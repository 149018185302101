import { styled } from "styled-components";
import bg from '../../assets/img/adv_01.png'

export const WrapBackground = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    position: relative;
    z-index: 21;
    &::before {
        content: "";

        background-image: url(${bg});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        animation: opacity 0.8s infinite;
        animation-timing-function: ease-in-out;
    }
    @keyframes opacity {
        from {opacity: 1;}
        to {opacity: 0;}
    }

`