import React from 'react'

export default function IcRate5(props) {
  return (
    <svg {...props} width={96} height={97} viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M47.9992 96.0043C74.5084 96.0043 95.9984 74.5167 95.9984 48.0106C95.9984 21.5044 74.5084 0.0168457 47.9992 0.0168457C21.49 0.0168457 0 21.5044 0 48.0106C0 74.5167 21.49 96.0043 47.9992 96.0043Z" fill="#FFB32B" />
      <path d="M56.3477 58.4417C59.8054 58.4417 62.6085 54.7047 62.6085 50.0949C62.6085 45.4852 59.8054 41.7482 56.3477 41.7482C52.89 41.7482 50.0869 45.4852 50.0869 50.0949C50.0869 54.7047 52.89 58.4417 56.3477 58.4417Z" fill="#273941" />
      <path d="M18.7813 58.4417C22.239 58.4417 25.042 54.7047 25.042 50.0949C25.042 45.4852 22.239 41.7482 18.7813 41.7482C15.3235 41.7482 12.5205 45.4852 12.5205 50.0949C12.5205 54.7047 15.3235 58.4417 18.7813 58.4417Z" fill="#273941" />
      <path d="M56.3418 56.3538C58.647 56.3538 60.5157 53.551 60.5157 50.0937C60.5157 46.6364 58.647 43.8337 56.3418 43.8337C54.0367 43.8337 52.168 46.6364 52.168 50.0937C52.168 53.551 54.0367 56.3538 56.3418 56.3538Z" fill="#141E21" />
      <path d="M18.7754 56.3538C21.0806 56.3538 22.9492 53.551 22.9492 50.0937C22.9492 46.6364 21.0806 43.8337 18.7754 43.8337C16.4703 43.8337 14.6016 46.6364 14.6016 50.0937C14.6016 53.551 16.4703 56.3538 18.7754 56.3538Z" fill="#141E21" />
      <path d="M58.4334 50.0967C59.5858 50.0967 60.52 49.1625 60.52 48.01C60.52 46.8576 59.5858 45.9233 58.4334 45.9233C57.2809 45.9233 56.3467 46.8576 56.3467 48.01C56.3467 49.1625 57.2809 50.0967 58.4334 50.0967Z" fill="#F6FAFD" />
      <path d="M20.865 50.0967C22.0175 50.0967 22.9517 49.1625 22.9517 48.01C22.9517 46.8576 22.0175 45.9233 20.865 45.9233C19.7126 45.9233 18.7783 46.8576 18.7783 48.01C18.7783 49.1625 19.7126 50.0967 20.865 50.0967Z" fill="#F6FAFD" />
      <path d="M45.918 77.2247H41.7442C41.7442 76.3733 40.1185 75.138 37.5703 75.138C35.0222 75.138 33.3965 76.3733 33.3965 77.2247H29.2227C29.2227 73.7128 32.8894 70.9647 37.5703 70.9647C42.2513 70.9647 45.918 73.7128 45.918 77.2247Z" fill="#273941" />
      <path d="M47.9992 0.0168457C38.5059 0.0168457 29.2257 2.83163 21.3323 8.10524C13.4389 13.3789 7.28669 20.8744 3.65374 29.6442C0.0207975 38.4139 -0.929747 48.0638 0.922313 57.3736C2.77437 66.6835 7.34585 75.2352 14.0587 81.9472C20.7715 88.6592 29.3241 93.2302 38.635 95.0821C47.946 96.9339 57.597 95.9835 66.3677 92.3509C75.1384 88.7184 82.6349 82.5669 87.9091 74.6744C93.1833 66.7819 95.9984 57.5028 95.9984 48.0105C95.9984 35.2818 90.9414 23.0744 81.9398 14.0739C72.9382 5.07331 60.7294 0.0168457 47.9992 0.0168457ZM43.8254 93.9176C35.983 93.9176 28.3168 91.5923 21.7962 87.2358C15.2755 82.8794 10.1933 76.6874 7.19216 69.4428C4.19103 62.1983 3.40579 54.2266 4.93576 46.5359C6.46572 38.8451 10.2422 31.7807 15.7875 26.236C21.3329 20.6912 28.3981 16.9152 36.0897 15.3854C43.7814 13.8556 51.754 14.6408 58.9993 17.6416C66.2447 20.6424 72.4374 25.724 76.7944 32.2439C81.1514 38.7638 83.4769 46.4292 83.4769 54.2706C83.4769 64.7856 79.2993 74.87 71.8632 82.3052C64.4271 89.7405 54.3416 93.9176 43.8254 93.9176Z" fill="#FFCE52" />
      <path d="M96.0003 48.0105C95.9992 38.1412 92.9552 28.5121 87.2829 20.4352C81.6106 12.3583 73.5859 6.22623 64.3021 2.87451C55.0182 -0.477222 44.9267 -0.885658 35.4023 1.70484C25.8779 4.29534 17.3837 9.75881 11.0771 17.3509C20.282 9.71935 32.0042 5.79791 43.9488 6.35436C55.8934 6.91082 67.2001 11.9051 75.6554 20.3594C84.1106 28.8137 89.1055 40.1191 89.662 52.0624C90.2185 64.0056 86.2966 75.7264 78.6642 84.9302C84.0937 80.4317 88.4628 74.79 91.4596 68.408C94.4564 62.0259 96.0068 55.061 96.0003 48.0105Z" fill="#FFE369" />
      <path d="M89.339 53.9175C90.0112 53.2453 89.7979 51.9422 88.8624 51.0069C87.927 50.0716 86.6238 49.8582 85.9516 50.5304C85.2793 51.2025 85.4927 52.5057 86.4281 53.441C87.3635 54.3763 88.6668 54.5896 89.339 53.9175Z" fill="#F6FAFD" />
      <path d="M90.0482 39.5084C90.3847 39.172 90.2783 38.5201 89.8106 38.0524C89.3429 37.5848 88.691 37.4784 88.3544 37.8149C88.0179 38.1514 88.1243 38.8033 88.592 39.2709C89.0597 39.7386 89.7116 39.8449 90.0482 39.5084Z" fill="#F6FAFD" />
      <path d="M91.0355 47.2652C92.3799 45.9209 91.9525 43.314 90.0809 41.4426C88.2092 39.5711 85.6021 39.1438 84.2576 40.4881C82.9131 41.8324 83.3405 44.4393 85.2122 46.3107C87.0838 48.1822 89.691 48.6095 91.0355 47.2652Z" fill="#F6FAFD" />
      <path d="M95.9963 14.6236C95.9963 6.55651 87.5881 0.0168457 77.214 0.0168457C66.8399 0.0168457 58.4316 6.55651 58.4316 14.6236C58.4316 22.2087 65.6148 28.5126 75.127 29.2304V37.5771L88.5313 26.261C90.6885 25.1481 92.5144 23.4865 93.8249 21.4435C95.1354 19.4004 95.8843 17.0482 95.9963 14.6236Z" fill="#5987DD" />
      <path d="M95.9967 14.623C95.9967 6.55589 87.5885 0.0162214 77.2144 0.0162214C74.0045 -0.10838 70.8121 0.544749 67.9091 1.91996C65.0061 3.29517 62.4786 5.3517 60.542 7.91432C64.2348 5.43589 68.5933 4.13702 73.0406 4.18959C83.4147 4.18959 91.8229 10.7293 91.8229 18.7964C91.8045 21.1759 91.078 23.4961 89.736 25.4612C91.5834 24.3011 93.1194 22.7073 94.2106 20.8184C95.3017 18.9295 95.9148 16.8028 95.9967 14.623Z" fill="#95B4FF" />
      <path d="M75.1319 37.5741V29.2273C65.6197 28.5095 58.4365 22.2056 58.4365 14.6206C58.4559 12.2241 59.1906 9.88809 60.5464 7.91187C58.6906 9.0755 57.1478 10.6755 56.0525 12.5723C54.9572 14.4691 54.3427 16.6051 54.2627 18.7939C54.2627 26.379 61.4459 32.6829 70.9581 33.4007V41.7474L84.3624 30.4313C86.507 29.2003 88.3454 27.5004 89.7404 25.4588C89.346 25.7342 88.9515 26.0097 88.53 26.258L75.1319 37.5741Z" fill="#F29410" />
      <path d="M66.7891 12.5339H70.9624V16.7072H66.7891V12.5339Z" fill="#EDEDED" />
      <path d="M75.127 12.5339H79.3003V16.7072H75.127V12.5339Z" fill="#EDEDED" />
    </svg>
  )
}
