import { styled } from "styled-components";
import PageBg from "../../components/PageBg/index.styled";

const Wrap = styled(PageBg)`
`

const WrapHome = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    .content{
        width: 100%;
        flex-grow: 1;
    }
`

export {
    WrapHome,
    Wrap
}