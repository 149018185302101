import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ConstData, ConstKey } from "../../assets/constants/constants";
import commonSlice from "../common/common.slice";
import { TimeUtils } from "../../utils/time";

// const apiFindUserWithFaceAndFinger = `${ConstData.host}/kiosk/findUserWithFaceAndFinger`;
const apiFindUserWithFaceAndFinger = `${ConstData.hostLocal}/kiosk/findUserWithFaceAndFinger`;

const findUserWithFaceAndFinger = createAsyncThunk(
  "detectFace/apiFindUserWithFaceAndFinger",
  async ({ fingerData, faceData, deviceInfo, callBack }, { getState, rejectWithValue, dispatch }) => {

    const currentTime = TimeUtils.getCurrentTime()
    //---
    const dataRequest = JSON.stringify({
      Finger: fingerData.base64,
      TemplateFingerData: fingerData.template,
      Face: faceData.base64,
      TimeStamp: currentTime,
      SessionId: deviceInfo.deviceId + currentTime
    });
    //---
    dispatch(commonSlice.actions.setIsLoading(true));
    try {
      const res = await axios.post(apiFindUserWithFaceAndFinger, dataRequest);
      const { data } = res;

      if (data.Code !== 0) {
        callBack(ConstData.flowCode.retry)
      } else {
        if (data.Cifs === undefined || data.Cif === null || data.Cif.CustomerNumber === "") {
          console.log("customer not exist: retry")
          callBack(ConstData.flowCode.retry)
        } else {
          //save data info
          var customerInfo = {
            type: data.Cif.PriorityCustomer === "1"
              ? ConstData.customerType.VIP
              : ConstData.customerType.NORMAL,
            customerNumber: data.Cif.CustomerNumber,
            name: data.Cif.Name,
            segment: data.Cif.Segment,
            segmentDesc: data.Cif.SegmentDesc,
            priorityCustomer: data.Cif.PriorityCustomer
          }
          console.log("save customer info: ", customerInfo)
          localStorage.setItem(ConstKey.CUSTOMER_INFO, JSON.stringify(customerInfo));
          //notify
          callBack(ConstData.flowCode.registered, data.Cifs.PriorityCustomer === "1" ? true : false)
        }
      }
    } catch (err) {
      dispatch(commonSlice.actions.setIs403Error(true))
      rejectWithValue(err);
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }
);

const mockFindUserWithFaceAndFinger = createAsyncThunk(
  "detectFace/apiMockFindUserByFace",
  async ({ mockType, fingerData, deviceInfo, faceData, callBack, callBackError }, { getState, rejectWithValue, dispatch }) => {
    const currentTime = TimeUtils.getCurrentTime()
    console.log("currentTime: ", currentTime)
    // const deviceInfo = useSelector(commonSelector.deviceInfo)
    console.log("deviceInfo: ", deviceInfo)
    // const faceData =  useSelector(detectFaceSelector.faceData)
    console.log("faceData: ", faceData)
    //---
    const dataRequest = JSON.stringify({
      Finger: fingerData.base64,
      TemplateFingerData: fingerData.template,
      Face: faceData.base64,
      TimeStamp: currentTime,
      SessionId: deviceInfo.id + currentTime
    });
    //---
    console.log("dataReq: ", dataRequest)
    //---
    dispatch(commonSlice.actions.setIsLoading(true));
    try {
      // const res = await axios.post(apiFindUserByFace, dataRequest);
      await TimeUtils.sleep(2000)
      //----
      const data = {
        Code: mockType,
        Message: mockType === 1 ? "system err" : "",
        Cif: mockType === 1
          ? null
          : mockType === 2
            ? { CustomerNumber: "" }
            : mockType === 3
              ? {
                CustomerNumber: "0123456789",
                Name: "Nguyễn Văn A",
                PriorityCustomer: "1",
                Segment: "Segment",
                SegmentDesc: "SegmentDesc",

              }
              : {
                CustomerNumber: "0123456789",
                Name: "Nguyễn Văn B",
                PriorityCustomer: "0",
                Segment: "Segment",
                SegmentDesc: "SegmentDesc",
              }
      };
      console.log("data info: ", data)
      if (data.Code !== 0) {
        callBack(ConstData.flowCode.retry)
      } else {
        if (data.Cif === null || data.Cif.CustomerNumber === "") {
          console.log("customer not exist: retry")
          callBack(ConstData.flowCode.retry)
        } else {
          //save data info
          var customerInfo = {
            type: data.Cif.PriorityCustomer === "1"
              ? ConstData.customerType.VIP
              : ConstData.customerType.NORMAL,
            customerNumber: data.Cif.CustomerNumber,
            name: data.Cif.Name,
            segment: data.Cif.Segment,
            segmentDesc: data.Cif.SegmentDesc,
            priorityCustomer: data.Cif.PriorityCustomer
          }
          console.log("save customer info: ", customerInfo)
          localStorage.setItem(ConstKey.CUSTOMER_INFO, JSON.stringify(customerInfo));
          //notify
          callBack(ConstData.flowCode.registered)
        }
      }
    } catch (error) {
      dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
      rejectWithValue(error);
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }

  }
);

export const ScanFingerAction = {
  findUserWithFaceAndFinger: findUserWithFaceAndFinger,
  mockFindUserWithFaceAndFinger: mockFindUserWithFaceAndFinger
}
