import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
//css
import { Wrap, WrapAdvertisingPage } from './index.styles'
//component
import AdvertisingSlider from '../../components/AdvertisingSlider/advertisingSlider'
import ButtonControl from '../../controls/ButtonControl'
//img
import IcDetectWhite from '../../assets/icon/IcDetectWhite'
import IcScanWhite from '../../assets/icon/IcScanWhite'
//redux
import { detectFaceSelector } from '../../redux/detectFace/detectFace.selectors'
import { ConstData } from '../../assets/constants/constants'


const AdvertisingPage = () => {
    const navigate = useNavigate()
    // const dispatch = useDispatch()

    const advertisingInfo = useSelector(detectFaceSelector.advertisingInfo);   

    const handleLatestVideo = () => {
        console.log("call api here");
    };

    const onShowScanCCCDPage = () => {
        navigate(ConstData.routePath.ScanCCCD)
    }

    const onShowDetectFacePage = () => {
        navigate(ConstData.routePath.DetectFace)
    }

    return (
        <Wrap>
            <WrapAdvertisingPage>
                <AdvertisingSlider
                    advertisingInfo={advertisingInfo}
                    handleLatestVideo={handleLatestVideo}
                />
                <div className="wrap-btn">
                    <ButtonControl
                        text={"Quét CCCD gắn chip"}
                        className="btn-detect"
                        onClick={onShowScanCCCDPage}
                        iconBtn={<IcScanWhite />}
                    />
                    <ButtonControl
                        text={"Quét khuôn mặt"}
                        className="btn-detect"
                        onClick={onShowDetectFacePage}
                        iconBtn={<IcDetectWhite />}
                    />
                </div>                
            </WrapAdvertisingPage>
        </Wrap>
    )
}

export default AdvertisingPage