/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { WrapVirtualKeyboard } from './index.styles'

// data
import { keyboardArray, keyboardSymbolArray } from './keyboardArray'
import { telexSpecialKey } from './telexSpecialKey';
import { vniSpecialKey } from './vniSpecialKey';


export default function VirtualKeyboard({ inputRef, text, setText, onEnter }) {
    const keyboard = useRef(null)
    const selectionRef = useRef({
        start: 0,
        end: 0
    })
    const [isUpperCase, setIsUpperCase] = useState(false)
    const [keyboardType, setKeyboardType] = useState("")
    const [isShowInputStyles, setIsShowInputStyles] = useState(false)

    const [styleInput, setStyleInput] = useState('keyTelex')

    useEffect(() => {
        const input = inputRef.current
        // get range of selection
        selectionRef.current = {
            start: input.selectionStart,
            end: input.selectionEnd
        }
    }, []);

    useEffect(() => {
        const { start, end } = selectionRef.current
        inputRef.current.setSelectionRange(start, end)
        inputRef.current.focus()
    }, [text]);

    const _handleClickKeyBoard = (keyboard, value) => {

        switch (keyboard.code) {
            case 'keyEnter': {
                // setText("")
                onEnter()
                break;
            }
            case 'keyBackspace': {
                const { start, end } = selectionRef.current
                if(start === end) {
                    setText(pre => pre.substring(0, start - 1) + pre.substring(start))
                    const newPos = start - 1 < 0 ? 0 : start - 1
                    selectionRef.current = {
                        start: newPos,
                        end: newPos
                    }
                    console.log("new caret position", selectionRef.current)
                    break;
                }
                setText(pre => pre.substring(0, start) + pre.substring(end))
                selectionRef.current = {
                    start: start,
                    end: start
                }
                console.log("new caret position", selectionRef.current)
                break;
            }
            case 'keyVNI':
            case 'keyTelex': {
                setStyleInput(keyboard.code);
                break;
            }
            default: {
                switch (styleInput) {
                    case 'keyTelex': {
                        _handleVietnameseStyleKeyboard(value, telexSpecialKey[keyboard.key]);
                        break;
                    }
                        
                    case 'keyVNI': {
                        _handleVietnameseStyleKeyboard(value, vniSpecialKey[keyboard.key]);
                        break;
                    }

                    default:
                        break;
                }
            }
        }
    }

    const _handleVietnameseStyleKeyboard = (character, regex) => {
        // text: current text
        // character: character is typed
        // regex: array of object mapping by character

        // console.log(text, character)

        const { start, end } = selectionRef.current
        // console.log(start, end)

        if (text.length === 0) {
            setText(character)
            selectionRef.current = {
                start: start + 1,
                end: end + 1
            }
            return;
        }

        if(!regex) {
            const endOfSelectStr = start
            const newText = text.substring(0, endOfSelectStr) + character + text.substring(endOfSelectStr)
            selectionRef.current = {
                start: start + 1,
                end: end + 1
            }
            setText(newText)
            return;
        }
        
        const isSelectRangeText = start !== end
        if(!isSelectRangeText) {
            const endOfSelectStr = start
            let startStr = text.substring(0, endOfSelectStr).lastIndexOf(" ")
            startStr = startStr === -1 ? 0 : startStr + 1
            const newStr = text.substring(startStr, endOfSelectStr)
            const objMapMatched = regex.find(({ value }) => newStr.includes(value))
            if(!objMapMatched) {
                const newText = text.substring(0, endOfSelectStr) + character + text.substring(endOfSelectStr)
                selectionRef.current = {
                    start: start + 1,
                    end: start + 1
                }
                setText(newText)
                return;
            }
            const { value, valueReplace } = objMapMatched

            const newText = text.substring(0, startStr) + newStr.replace(value, valueReplace) + text.substring(endOfSelectStr)
            selectionRef.current = {
                start: start,
                end: start
            }
            setText(newText)
            return
        }
        
        const newText = text.substring(0, start) + character + text.substring(end)
        selectionRef.current = {
            start: start + 1,
            end: start + 1
        }
        setText(newText)
    }

    const _handleClick = (keyboardItem) => {
        const input = inputRef.current
        input.focus()

        // get range of selection
        selectionRef.current = {
            start: input.selectionStart,
            end: input.selectionEnd
        }

        // console.log("current caret position", selectionRef.current)

        switch (keyboardItem.code) {
            case 'keyShift': {
                setIsUpperCase(pre => !pre);
                break;
            }
               
            case 'keySymbols': {
                if (keyboardType === 'symbols') {
                    setKeyboardType('normal')
                } else {
                    setKeyboardType('symbols')
                }
                break;
            }
            default: {
                _handleClickKeyBoard(keyboardItem, isUpperCase ? keyboardItem.key.toUpperCase() : keyboardItem.key)
                break;
            }
        }
    }

    const _renderKeyboard = () => {
        let keyboardArr = []
        switch (keyboardType) {
            case 'symbols':
                keyboardArr = keyboardSymbolArray
                break;
            default:
                keyboardArr = keyboardArray
                break;
        }
        return (
            <div className='bound'>
                {
                    keyboardArr.map((item, i) => {
                        return (
                            <div className='line' key={i}>
                                {
                                    item.map((keyItem, j) => {
                                        if (keyItem.styleInput) {
                                            return (
                                                <div className='input-style-bound' key={j}>
                                                    {
                                                        keyItem.styleInput.filter(ele => ele.code === styleInput)?.map((input, k) =>
                                                            <button
                                                                className="input-type-encode"
                                                                id={input.code}
                                                                onClick={() => setIsShowInputStyles(pre => !pre)} key={j}>
                                                                {input.title}
                                                            </button>
                                                        )
                                                    }
                                                    {
                                                        isShowInputStyles &&
                                                        <div className='input-style-dropdown'>
                                                            {
                                                                keyItem.styleInput.map((input, k) => {
                                                                    return (
                                                                        <div key={k} className={input.code === styleInput ? 'active' : undefined}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                setIsShowInputStyles(false)
                                                                                _handleClick(input)
                                                                            }}>
                                                                            {input.title}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                        return (
                                            <button id={keyItem.code} onClick={(e) => {e.stopPropagation();_handleClick(keyItem)}} key={j}>{isUpperCase ? keyItem.titleUpper : keyItem.title}</button>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    useLayoutEffect(() => {
        const position = keyboard.current.getBoundingClientRect()
        // console.log(position)
        const { height, top, left, width } = position
        if(top + height > document.body.offsetHeight) {
            keyboard.current.style.bottom = "calc(100% + 7px)"
            keyboard.current.style.top = "initial"
        }
        if(left + width > document.body.offsetWidth) {
            keyboard.current.style.left = "initial"
            keyboard.current.style.right = "0"
        }
    }, []);

    return (
        <WrapVirtualKeyboard ref={keyboard}>
            {_renderKeyboard()}
        </WrapVirtualKeyboard>
    )
}
