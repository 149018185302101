import dayjs from 'dayjs'

const getCurrentTime = () => {
    return Math.round((new Date()).getTime() / 1000)
}

const convertDateTime = (time, format) => {
    return dayjs(time).format(format);
};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const TimeUtils = {
    getCurrentTime: getCurrentTime,
    convertDateTime: convertDateTime,
    sleep: sleep
}