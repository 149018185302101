import React from 'react'

export default function IcStartActive(props) {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.998 2.00391L15.088 8.26391L21.998 9.27391L16.998 14.1439L18.178 21.0239L11.998 17.7739L5.81805 21.0239L6.99805 14.1439L1.99805 9.27391L8.90805 8.26391L11.998 2.00391Z" stroke="#007822" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}
