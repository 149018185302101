import React from 'react'
import { WrapHeader } from './index.styled'
import logoBank from '../../assets/img/logo.png'
import UseRenderTime from '../../controls/UseRenderTime'

const Header = ({ nameOffice }) => {
  var nameOfficeValue = nameOffice ?? "PGD Thành Công"
  if (nameOffice === "") {
    nameOfficeValue = "PGD Thành Công"
  }
  return (
    <WrapHeader>
      <div className='header-left'>
        <span className='name-office'>{nameOfficeValue}</span>
        {/* <span className='time'>{timeTransaction}</span> */}
        <UseRenderTime color="#FFFFFF" />
      </div>
      <div className='header-right'>
        <img src={logoBank} alt="logo" />
        <span className='name-bank'>AGRIBANK</span>
      </div>
    </WrapHeader>
  )
}

export default Header