/* eslint-disable react-hooks/exhaustive-deps */
import Slider from "react-slick";
import { ConstData } from "../../assets/constants/constants";
import React, { useRef, useEffect, useCallback } from "react";
import "./advertisingSlider.styles.css";
import { motion } from "framer-motion";
import { damping, variants } from "../../utils/transition_page";

const AdvertisingSlider = ({ advertisingInfo, handleLatestVideo }) => {
  let delayFunc = null;
  const VIDEO = ["VIDEO"];

  const playerRef = useRef(null);
  var playVideoPromise = null;

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getSliderWrapper = () => {
    if (playerRef.current === null || playerRef.current.innerSlider === null) {
      return null;
    }
    return playerRef.current.innerSlider.list;
  };

  const getCurrentVideo = () => {
    const sliderWrapper = getSliderWrapper();
    if (sliderWrapper === null) {
      return null;
    }
    //---
    const dataInfo =
      sliderWrapper.querySelector(".slick-active video") ||
      sliderWrapper.querySelector(".slick-active img");

    return dataInfo;
  };

  const isVideo = (currentVideo) => {
    return VIDEO.includes(
      currentVideo ? currentVideo?.tagName : getCurrentVideo()?.tagName
    );
  };

  const isHasData = () =>
    Boolean(advertisingInfo != null && advertisingInfo.dataList.length > 0);
  const isVideoLatest = () => getCurrentVideo().dataset.latest === "true";

  const clearCurrent = () => {
    try {
      const currentVideo = getCurrentVideo();
      if (currentVideo !== null && isVideo(currentVideo)) {
        if (playVideoPromise !== null && playVideoPromise !== undefined) {
          playVideoPromise
            .then((_) => {
              currentVideo.pause();
              currentVideo.removeEventListener("ended", handleEndVideo);
              currentVideo.currentTime = 0;
              //---
              playVideoPromise = null;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
    //---
    clearTimeout(delayFunc);
  };

  const handleEndVideo = useCallback(() => {
    handleNextByTime();
  }, []);

  const handleNextByTime = (time = 0) => {
    delayFunc = setTimeout(() => {
      if (playerRef.current !== null) {
        playerRef.current.slickNext();
      }
    }, time);
  };

  const handleAfterChange = (e) => {
    playVideoCurrent();
    if (isVideoLatest()) {
      handleLatestVideo();
    }
  };

  const playVideoCurrent = () => {
    const currentVideo = getCurrentVideo();
    if (currentVideo === null) {
      return;
    }
    try {
      if (isVideo(currentVideo)) {
        if (currentVideo.paused) {
          console.log("playVideoCurrent - currentVideo: ", currentVideo);
          playVideoPromise = currentVideo.play();
          currentVideo.addEventListener("ended", handleEndVideo);
        } else {
          handleNextByTime();
        }
      } else {
        handleNextByTime(advertisingInfo.slideTime);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("isHasData: ", isHasData());
    if (isHasData()) {
      playVideoCurrent();
    }
    return () => {
      clearCurrent();
    };
  }, [playerRef, advertisingInfo.dataList]);

  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: damping },
        opacity: { duration: 0.2 },
      }}
    >
      <Slider
        ref={playerRef}
        {...settings}
        beforeChange={(e) => {
          clearCurrent();
        }}
        afterChange={handleAfterChange}
      >
        {advertisingInfo &&
          advertisingInfo.dataList.map((data, index) => (
            <div key={index}>
              {data.type === ConstData.advertisingType.Image && (
                <div>
                  <img
                    src={data.src}
                    alt={`${index}`}
                    style={{
                      height: ConstData.screen.Height,
                      width: ConstData.screen.Width,
                    }}
                  />
                </div>
              )}
              {data.type === ConstData.advertisingType.Video && (
                <div>
                  <video
                    playsInline
                    style={{
                      height: ConstData.screen.Height,
                      width: ConstData.screen.Width,
                    }}
                    muted
                    controls={false}
                    alt="All the devices"
                    src={data.src}
                  />
                </div>
              )}
            </div>
          ))}
      </Slider>
    </motion.div>
  );
};

export default AdvertisingSlider;
