import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Lottie from "lottie-react";
//css
import { Wrap, WrapScanCCCD } from "./index.styled";
//img
import img_scan_cccd from "../../assets/img/img_scan_cccd.png";
import BannerBottomFinger from "../../assets/img/banner_bottom_finger.png";
import IconArrow from "../../assets/icon/arrow";
import scan_lottie from "../../assets/lottie/scan-lottie.json";
import IcScanFinger from "../../assets/icon/IcScanFinger";
import IcDetectWhite from "../../assets/icon/IcDetectWhite";
//component
import Header from "../../components/Header";
import ButtonControl from "../ButtonControl";
//hook
import { commonSelector } from "../../redux/common/common.selectors";
import { ConstData } from "../../assets/constants/constants";
import commonSlice from "../../redux/common/common.slice";
import useConnectScannerMK from "../../hooks/useConnectScannerMK";

const SELECT_VERIFY_FINGER = 'SELECT_VERIFY_FINGER'
const SELECT_VERIFY_FACE = 'SELECT_VERIFY_FACE'
const START_FINGER = 'START_FINGER'
const START_FACE = 'START_FACE'

const ScanCCCDControl = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  //---
  const {
    wsRef,
    connectWs,
    wsConnected,
    message,
    setMessage,
    isProcessing,
    setIsProcessing,
    isVerifyFailed,
    setIsVerifyFailed,
  } = useConnectScannerMK();
  //---
  const deviceInfo = useSelector(commonSelector.deviceInfo)
  const stepScanChip = useSelector(state => state.common.stepScanChip)
  //---
  const handleSelectVerify = (type) => {
    if (type === SELECT_VERIFY_FACE) {
      dispatch(commonSlice.actions.setTypeConnectMK(START_FACE))
      navigate(ConstData.routePath.DetectFace);
    } else if (type === SELECT_VERIFY_FINGER) {
      dispatch(commonSlice.actions.setTypeConnectMK(START_FINGER))
      navigate(ConstData.routePath.ScanFingerPage);
    }
  }

  return (
    <Wrap>
      <Header nameOffice={deviceInfo?.officeName} />
      <WrapScanCCCD>
        {stepScanChip === 1 && (
          <div className="content">
            <div className="content-top">
              <img src={img_scan_cccd} alt="VerifieldFinger" />
            </div>
            <div className="content-bottom">
              Vui lòng đưa CCCD vào thiết bị đọc chip đang sáng đèn{" "}
              <IconArrow className='icon-arrow' />
            </div>
          </div>
        )}
        {stepScanChip === 2 && (
          <div className='content'>
            <div className='content-top'>
              <Lottie
                animationData={scan_lottie}
                loop={true}
                className="animation"
              />
            </div>
            <div className='content-bottom'>Đang xử lý...</div>
          </div>
        )}
        {stepScanChip === 3 && (
          <div className='content'>
            <div className='content-top'>
              <div className="wrap-btn">
                <ButtonControl
                  iconBtn={<IcDetectWhite />}
                  text="Xác thực khuôn mặt"
                  className="btn-button"
                  onClick={() => handleSelectVerify(SELECT_VERIFY_FACE)}
                />
                <ButtonControl
                  iconBtn={<IcScanFinger />}
                  text="Xác thực vân tay"
                  className="btn-button"
                  onClick={() => handleSelectVerify(SELECT_VERIFY_FINGER)}
                />
              </div>
            </div>
            <div className='content-bottom'>Vui lòng chọn phương thức xác thực</div>
          </div>
        )}
        <div className="footer">
          <img src={BannerBottomFinger} alt="BannerBottomFinger" />
        </div>
      </WrapScanCCCD>
    </Wrap>
  );
};

export default ScanCCCDControl;
