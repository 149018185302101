import React from 'react'

export default function IcRate4(props) {
  return (
    <svg {...props} width={96} height={97} viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48 96.0013C74.5097 96.0013 96 74.5109 96 48.0012C96 21.4916 74.5097 0.0012207 48 0.0012207C21.4903 0.0012207 0 21.4916 0 48.0012C0 74.5109 21.4903 96.0013 48 96.0013Z" fill="#FFCE52" />
      <path d="M66.7853 43.8295C70.2431 43.8295 73.0461 40.092 73.0461 35.4817C73.0461 30.8713 70.2431 27.1339 66.7853 27.1339C63.3275 27.1339 60.5244 30.8713 60.5244 35.4817C60.5244 40.092 63.3275 43.8295 66.7853 43.8295Z" fill="#273941" />
      <path d="M29.2208 43.8295C32.6786 43.8295 35.4817 40.092 35.4817 35.4817C35.4817 30.8713 32.6786 27.1339 29.2208 27.1339C25.763 27.1339 22.96 30.8713 22.96 35.4817C22.96 40.092 25.763 43.8295 29.2208 43.8295Z" fill="#273941" />
      <path d="M66.7843 41.741C69.0895 41.741 70.9582 38.938 70.9582 35.4802C70.9582 32.0224 69.0895 29.2193 66.7843 29.2193C64.4791 29.2193 62.6104 32.0224 62.6104 35.4802C62.6104 38.938 64.4791 41.741 66.7843 41.741Z" fill="#141E21" />
      <path d="M29.2198 41.741C31.525 41.741 33.3937 38.938 33.3937 35.4802C33.3937 32.0224 31.525 29.2193 29.2198 29.2193C26.9146 29.2193 25.0459 32.0224 25.0459 35.4802C25.0459 38.938 26.9146 41.741 29.2198 41.741Z" fill="#141E21" />
      <path d="M68.876 35.4766C70.0286 35.4766 70.963 34.5422 70.963 33.3896C70.963 32.237 70.0286 31.3027 68.876 31.3027C67.7234 31.3027 66.7891 32.237 66.7891 33.3896C66.7891 34.5422 67.7234 35.4766 68.876 35.4766Z" fill="#F6FAFD" />
      <path d="M31.3086 35.4766C32.4612 35.4766 33.3956 34.5422 33.3956 33.3896C33.3956 32.237 32.4612 31.3027 31.3086 31.3027C30.156 31.3027 29.2217 32.237 29.2217 33.3896C29.2217 34.5422 30.156 35.4766 31.3086 35.4766Z" fill="#F6FAFD" />
      <path d="M47.9947 6.26373C73.3512 6.26373 93.9078 24.0175 93.9078 45.9159H95.9425C95.3672 33.5863 90.0643 21.9522 81.1353 13.4301C72.2064 4.90814 60.3377 0.153305 47.9947 0.153305C35.6517 0.153305 23.783 4.90814 14.8541 13.4301C5.92511 21.9522 0.622216 33.5863 0.046875 45.9159H2.08166C2.08166 24.0175 22.6382 6.26373 47.9947 6.26373Z" fill="#FFE369" />
      <path d="M93.913 45.9153C93.913 67.8138 73.3565 85.5676 48 85.5676C22.6435 85.5676 2.08696 67.8138 2.08696 45.9153H0.0521739C0.0229565 46.6082 0 47.3032 0 48.0023C1.89698e-07 60.7327 5.05713 72.9417 14.0589 81.9435C23.0606 90.9452 35.2696 96.0024 48 96.0024C60.7304 96.0024 72.9394 90.9452 81.9411 81.9435C90.9429 72.9417 96 60.7327 96 48.0023C96 47.3032 95.977 46.6082 95.9478 45.9153H93.913Z" fill="#FFB32B" />
      <path d="M31.3047 64.6968H64.696V68.8708H31.3047V64.6968Z" fill="#273941" />
      <path d="M76.8228 18.4241C77.495 17.7518 77.2817 16.4485 76.3462 15.513C75.4107 14.5775 74.1073 14.3641 73.435 15.0364C72.7628 15.7087 72.9761 17.012 73.9116 17.9475C74.8471 18.883 76.1505 19.0964 76.8228 18.4241Z" fill="#F6FAFD" />
      <path d="M62.9243 8.17761C63.2608 7.84107 63.1545 7.18906 62.6868 6.72132C62.219 6.25358 61.567 6.14722 61.2305 6.48377C60.8939 6.82031 61.0003 7.47232 61.468 7.94006C61.9358 8.40781 62.5878 8.51416 62.9243 8.17761Z" fill="#F6FAFD" />
      <path d="M72.2558 15.9401C73.6004 14.5956 73.173 11.9882 71.3012 10.1164C69.4294 8.24462 66.8221 7.81721 65.4775 9.16177C64.1329 10.5063 64.5604 13.1137 66.4321 14.9855C68.3039 16.8573 70.9113 17.2847 72.2558 15.9401Z" fill="#F6FAFD" />
    </svg>
  )
}
