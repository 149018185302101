import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { commonSelector } from './redux/common/common.selectors';
import LoadingControl from './controls/LoadingControl';
import PopupErr from './controls/PopupErr';
import PopupEndTransaction from './controls/PopupEndTransaction';
import Portal from './controls/Portal';
import commonSlice from './redux/common/common.slice';
import { Outlet, useNavigate } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import IcCheck from './assets/icon/IcCheck';
import IcRetry from './assets/icon/IcRetryWhite';
import { ConstData, ConstKey } from './assets/constants/constants';

const App = () => {
  //===
  const dispatch = useDispatch()
  const navigate = useNavigate()
  //---
  const isLoading = useSelector(commonSelector.isLoading)
  const isDisconnectNetwork = useSelector(commonSelector.isNetWorkErr)
  const isSystemErr = useSelector(commonSelector.isSystemError)
  const isWarningEndTrans = useSelector(commonSelector.isWarningEndTrans)
  const issueTicketResult = useSelector(commonSelector.issueTicketResult)
  const isEndOfSession = useSelector(commonSelector.isEndOfSession)
  const is403Error = useSelector(commonSelector.is403Error)
  //---
  const onDisconnectNetworkSubmit = () => {
    dispatch(commonSlice.actions.setIsDisconnectNetworkError(false))
    navigate(ConstData.routePath.MainPage);
  }
  const on403ErrSubmit = () => {
    localStorage.removeItem(ConstKey.DEVICE_INFO)
    //---
    dispatch(commonSlice.actions.setIs403Error(false))
    dispatch(commonSlice.actions.connectDeviceCompleted(null))
    //---
    navigate(ConstData.routePath.MainPage);
  }

  const onSystemErrSubmit = () => {
    dispatch(commonSlice.actions.setIsSystemError(false))
    //---
    navigate(ConstData.routePath.MainPage);
  }

  const onIssueTicketErrSubmit = () => {
    dispatch(commonSlice.actions.setIssueTicketResult({
      isErr: false,
      messageErr: ""
    }))
  }

  const onWarningEndTransSubmit = () => {
    dispatch(commonSlice.actions.setIsWarningEndTrans(false))
  }

  const goToMainPage = () => {
    dispatch(commonSlice.actions.setIsWarningEndTrans(false))
    dispatch(commonSlice.actions.setIsEndOfSession(false))
    //----
    localStorage.removeItem(ConstKey.TICKET_INFO);
    localStorage.removeItem(ConstKey.CUSTOMER_INFO);
    //----
    navigate(ConstData.routePath.MainPage)
  }

  const onShowEndOfSessionPopup = () => {
    dispatch(commonSlice.actions.setIsEndOfSession(true))
  }

  const onHideEndOfSessionPopup = () => {
    dispatch(commonSlice.actions.setIsEndOfSession(false))
  }
  //---
  return (
    <>
      <AnimatePresence>
        <Outlet />
      </AnimatePresence>
      {
        isLoading &&
        <Portal>
          <LoadingControl />
        </Portal>
      }
      {
        is403Error && (
          <Portal>
            <PopupErr
              title={"Đã có lỗi xảy ra"}
              desc={"Mất kết nối với Kiosk"}
              iconBtn={<IcRetry />}
              textBtn={"Kết thúc"}
              onSubmit={on403ErrSubmit}
            />
          </Portal>
        )}
      {isDisconnectNetwork && (
        <Portal>
          <PopupErr
            title={"Không kết nối được với các thiết bị ngoại vi"}
            desc={"Vui lòng kiểm tra lại các thiết bị ghi hình, thiết bị ghi nhận vân tay"}
            iconBtn={<IcCheck />}
            textBtn={"ĐÃ HIỂU"}
            onSubmit={onDisconnectNetworkSubmit}
          />
        </Portal>
      )}
      {isSystemErr && (
        <Portal>
          <PopupErr
            title={"Đã có lỗi xảy ra"}
            desc={"Mất kết nối với hệ thống"}
            iconBtn={<IcRetry />}
            textBtn={"THỬ LẠI"}
            onSubmit={onSystemErrSubmit}
          />
        </Portal>
      )}
      {issueTicketResult.isErr && (
        <Portal>
          <PopupErr
            title={"Đã có lỗi xảy ra"}
            desc={issueTicketResult.messageErr}
            iconBtn={<IcCheck />}
            textBtn={"ĐÃ HIỂU"}
            onSubmit={onIssueTicketErrSubmit}
          />
        </Portal>
      )}
      {isWarningEndTrans && (
        <Portal>
          <PopupEndTransaction
            title={"Tiếp tục giao dịch"}
            desc={"Bạn vui lòng bấm nút “Tiếp tục” để tiếp tục giao dịch"}
            onEnd={onShowEndOfSessionPopup}
            onSubmit={onWarningEndTransSubmit}
            isShowTimeWarning={true}
            timeWaringDefault={10}
          />
        </Portal>
      )}
      {isEndOfSession && (
        <Portal>
          <PopupEndTransaction
            title={"Kết thúc giao dịch"}
            desc={"Bạn có chắc chắn muốn kết thúc phiên giao dịch này?"}
            onEnd={goToMainPage}
            onSubmit={onHideEndOfSessionPopup}
            isShowTimeWarning={false}
          />
        </Portal>
      )}
    </>
  );
}

export default App;