/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ConstData } from "../assets/constants/constants";
import detectFaceSlice from "../redux/detectFace/detectFace.slice";
// import { wsCamera } from "./dataMock/camera";

const STREAM = "STREAM";
const COUNT = "COUNT";
const MESSAGE = "MESSAGE";
const DATA = "DATA";
const ERROR = "ERROR";
const CAMERA_START = "START_CAMERA"; //start camera
const CAMERA_STOP = "STOP_CAMERA"; //stop camera
const STATUS = "STATUS";

const useConnectWsCamera = () => {
  var showAdvertisingDelayFunc = null;

  const dispatch = useDispatch();
  const wsRef = useRef();
  const timerReconnect = useRef(0);
  const isUnmounted = useRef(false);
  const [wsConnected, setWsConnected] = useState(false);
  const [cameraStreamUrl, setCameraStreamUrl] = useState("");
  const [count, setCount] = useState(null);
  const [message, setMessage] = useState("");
  const [errDevice, setErrDevice] = useState("");
  const [hasFace, setHasFace] = useState(false);
  const [isGetFaceSuccess, setIsGetFaceSuccess] = useState(false);
  //---
  // const isRunTest = useRef(false)
  // if(!isRunTest.current){
  //   setTimeout(() => {
  //     setHasFace(true)
  //   }, 5000)
  
  //   setTimeout(async () => {
  //     setMessage("Lấy khuôn mặt thành công.");
  //     setIsGetFaceSuccess(true)
  //     const face = await wsCamera.getFaceBase64();
  //     getFaceSucceed(face)
  //   }, 10000)  
  //   isRunTest.current = true
  // }
  
  const startCamera = () => {
    wsRef.current.send(CAMERA_START);
  };

  const stopCamera = () => {
    wsRef.current.send(CAMERA_STOP);
  };

  const updateFaceStatus = (faceStatus) => {
    if(faceStatus){
      if(showAdvertisingDelayFunc !== null){
        // console.log("clearTimeout => showAdvertisingDelayFunc")
        clearTimeout(showAdvertisingDelayFunc)
        showAdvertisingDelayFunc = null
      }
    } else {
      if(showAdvertisingDelayFunc === null){
        // console.log("setTimeout => showAdvertisingDelayFunc")
        showAdvertisingDelayFunc = setTimeout(() => {
          setHasFace(false)
          dispatch(detectFaceSlice.actions.setFace(null))
          showAdvertisingDelayFunc = null;
        }, ConstData.delayTimeWhenNoFace);
      }
    }
  }

  const getFaceSucceed = (faceBase64) => {
    dispatch(detectFaceSlice.actions.setFace({
      base64: faceBase64,
      url: `data:image/png;base64,${faceBase64}`
    }))
  }

  const connectWs = useCallback(() => {
    setWsConnected(false);
    wsRef.current = new WebSocket(`ws://${ConstData.ipAddressOfDevice}:6868`);
    wsRef.current.onopen = () => {
      console.log("connect");
      setWsConnected(true);
      clearTimeout(timerReconnect.current);
      startCamera();
    };

    wsRef.current.onmessage = (e) => {
      try {
        // console.log("JSON.parse start")
        const evtData = JSON.parse(e.data);
        // console.log(evtData);
        // console.log("JSON.parse end")
        const { type } = evtData;
        // console.log("current.onmessage => type = ", type)
        if(type !== STREAM && isGetFaceSuccess){
          return;
        }
        switch (type) {
          case STREAM: {
            setCameraStreamUrl(`data:image/png;base64,${evtData?.frame}`);
            break;
          }
          case COUNT: {
            setCount(evtData?.count);
            break;
          }
          case STATUS: {
            const { code } = evtData;
            // console.log("current.onmessage => status type => code: ", code)
            if (code === 27) {
              console.log("current.onmessage => no face")
              updateFaceStatus(false)
            } else if (code === 26) {
              console.log("current.onmessage => has face")
              updateFaceStatus(true)
              setHasFace(true);
            }
            break;
          }
          case MESSAGE: {
            const { code } = evtData;
            let message = "";
            if (code === 20) {
              message = "Đang xử lý, vui lòng giữ yên khuôn mặt.";
            } else if (code === 21) {
              message = "Góc mặt không hợp lệ, vui lòng nhìn thẳng vào camera.";
            } else if (code === 22) {
              message = "Vui lòng di chuyển khuôn mặt vào vùng nhận diện.";
            } else if (code === 23) {
              message = "Khuôn mặt không hợp lệ.";
            } else if (code === 24) {
              message = "Khuôn mặt quá nhỏ, vui lòng đưa khuôn mặt gần hơn.";
            } else if (code === 25) {
              message = "Vui lòng giữ yên khuôn mặt trong vùng nhận diện.";
            } else {
              message = "Lấy khuôn mặt thành công.";
            }
            setMessage(message);
            break;
          }
          case ERROR: {
            const { code } = evtData;
            if (code === 11) {
              setErrDevice("Không thấy camera kết nối");
            } else if (code === 12) {
              setErrDevice("kết nối camera thất bại");
            }
            break;
          }
          case DATA: {
            stopCamera();
            setMessage("Lấy khuôn mặt thành công.");
            setIsGetFaceSuccess(true)
            //---
            const { face } = evtData.data;
            getFaceSucceed(face)
            //---
            break;
          }
          default: {}
        }
      } catch (err) {
        console.log("cannot parse data", e.data, err);
      }
    };
    wsRef.current.onclose = () => {
      setWsConnected(false);
      if (isUnmounted.current) return;
      timerReconnect.current = setTimeout(connectWs, 100000);
    };
    wsRef.current.onerror = (e) => {
      console.log(e);
    };
  }, []);

  useEffect(() => {
    // console.log("useConnectWsCamera => useEffect")
    setIsGetFaceSuccess(false)
    dispatch(detectFaceSlice.actions.setFace(null))
    // console.log("reset data")
    // setHasFace(false)
    //---
    connectWs();
    //---
    return () => {
      isUnmounted.current = true;
      clearTimeout(timerReconnect.current);
      wsRef.current && wsConnected && stopCamera();
      wsRef.current && wsRef.current.close();
      setWsConnected(false);
      //---
      console.log("setWsConnected: false")
    };
  }, []);

  const resetGetFace = () => {
    console.log("reset data")
    setIsGetFaceSuccess(false)
    dispatch(detectFaceSlice.actions.setFace(null))
  }

  return {
    cameraStreamUrl,
    hasFace,
    count,
    errDevice,
    message,
    isGetFaceSuccess,
    setIsGetFaceSuccess,
    resetGetFace,
    wsRef,
    wsConnected,
  };
};

export default useConnectWsCamera;
