import axios from "axios";

export const getBlobImg = (url) => new Promise(async (resolve) => {
    try {
        const res = await axios.get(url, {
            responseType: 'blob'
        });
        const { data } = res
        resolve(data)
        return 
    }
    catch(err) {
        resolve(null)
        return 
    }
})


export const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}


export const blobToFile = (blob, fileName) => {       
    return new File([blob], fileName, { lastModified: new Date().getTime(), type: blob.type })
}


export const removePrefix = (base64) => base64.split(",")[1]
