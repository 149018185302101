import React from 'react'
import Lottie from 'lottie-react'

// import animation
import loadingAnimation from '../../assets/json/loadingAnimation.json'
import { WrapLoading } from './index.styles'

/**
 *
 * @param {string="250px"} size size of icon loading(width === height === size)
 * @param {boolean=true} loadingPage true if loading fullscreen, else loading element
 */
export default function LoadingControl({ size = '160px', loadingPage = true }) {

    return loadingPage ? (
        <WrapLoading>
            <div className="loading">
                <Lottie
                    loop={true}
                    autoPlay={true}
                    animationData={loadingAnimation}
                    style={{
                        marginLeft: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: size,
                        height: size
                    }}
                />
                <div className='text'>Kiểm tra thông tin...</div>
            </div>
           
        </WrapLoading>
    ) : (
        <Lottie
            loop={true}
            autoPlay={true}
            animationData={loadingAnimation}
            style={{
                display: 'inline-block',
                margin: 0,
                width: size,
                height: size
            }}
        />
    )
}
