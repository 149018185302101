import styled from "styled-components";

const ButtonWrapper = styled.button`
    pointer-events: ${props => props.isDisable ? "none" : "auto"};
    width: 300px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    padding: 33px 36px;
    box-sizing: border-box;
    background: var(--gradient-primary, linear-gradient(180deg, #AB1F24 0%, #900F14 100%));;
    border-radius: 24px;
    cursor: pointer;
    border: none;
    outline: none;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    color: #FFFFFF;
    gap: 18px;
    &.disable{
        /* opacity: 0.5; */
        background: #E9E9E9!important;
        color: #7A7A7A!important;
        pointer-events: none!important;
    }
    /* svg{
        margin-right: 18px;
    } */
`

export {ButtonWrapper};