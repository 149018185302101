import { ConstKey } from "../../assets/constants/constants";

const advertisingInfoSelector = (state) => state.detectFace.advertisingInfo;
const faceDataSelector = (state) => {
    var faceData = state.detectFace.faceData;
    if (!faceData){
        faceData = JSON.stringify(localStorage.getItem(ConstKey.FACE_INFO)) || null
        if (faceData === "null"){
            faceData = null
        }
    }
    return faceData
}

export const detectFaceSelector = {
    advertisingInfo: advertisingInfoSelector,
    faceData: faceDataSelector
}