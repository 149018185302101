import { styled } from 'styled-components'
import PageBg from '../../components/PageBg/index.styled'
import { ConstData } from '../../assets/constants/constants'

const Wrap = styled(PageBg)``

const WrapAdvertisingPage = styled.div`
    position: relative;
    width: ${ConstData.screen.Width};
    height: ${ConstData.screen.Height};
    font-family: 'SVN-Gilroy';
    font-style: normal;
    z-index: 20;
    display: flex;
    flex-direction: column;       
    .wrap-btn{
        width: 1080px;
        height: 300px;
        background: var(--gradient-primary, linear-gradient(180deg, #AB1F24 0%, #900F14 100%));
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        z-index: 21;
        .btn-detect{
            width: 485px;
            height: 108px;
            border-radius: 24px;
            background: #007822
        }
    }
`
export { Wrap, WrapAdvertisingPage }