import React from 'react'

const IcScanWhite = (props) => {
    return (
        <svg {...props} width={43} height={42} viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.8181 8.75006H7.1817C5.42443 8.75006 3.99988 10.1212 3.99988 11.8126V30.1876C3.99988 31.8789 5.42443 33.2501 7.1817 33.2501H35.8181C37.5753 33.2501 38.9999 31.8789 38.9999 30.1876V11.8126C38.9999 10.1212 37.5753 8.75006 35.8181 8.75006Z" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.5 18.278H32" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.5 23.8585H32" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcScanWhite