import React from 'react'

const IcTRANSEXTCARD = (props) => {
    return (
        <svg {...props} width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.08228 12.7194C9.50561 10.9147 10.9147 9.5056 12.7194 9.08228L14.9555 8.55776C16.5195 8.1909 18.1472 8.1909 19.7112 8.55776L21.9473 9.08228C23.752 9.5056 25.1611 10.9147 25.5844 12.7194L26.1089 14.9555C26.4758 16.5195 26.4758 18.1472 26.1089 19.7111L25.5844 21.9473C25.1611 23.752 23.752 25.1611 21.9473 25.5844L19.7111 26.1089C18.1472 26.4758 16.5195 26.4758 14.9555 26.1089L12.7194 25.5844C10.9147 25.1611 9.50561 23.752 9.08228 21.9473L8.55776 19.7112C8.1909 18.1472 8.1909 16.5195 8.55776 14.9555L9.08228 12.7194Z" stroke="#00783A" strokeWidth="4.5" />
            <path d="M9.08228 42.0527C9.50561 40.248 10.9147 38.8389 12.7194 38.4155L14.9555 37.891C16.5195 37.5242 18.1472 37.5242 19.7112 37.891L21.9473 38.4155C23.752 38.8389 25.1611 40.248 25.5844 42.0527L26.1089 44.2888C26.4758 45.8528 26.4758 47.4804 26.1089 49.0444L25.5844 51.2805C25.1611 53.0852 23.752 54.4943 21.9473 54.9176L19.7111 55.4422C18.1472 55.809 16.5195 55.809 14.9555 55.4422L12.7194 54.9176C10.9147 54.4943 9.50561 53.0852 9.08228 51.2805L8.55776 49.0444C8.1909 47.4804 8.1909 45.8528 8.55776 44.2888L9.08228 42.0527Z" stroke="#AB1F24" strokeWidth="4.5" />
            <path d="M38.4158 12.7194C38.8391 10.9147 40.2482 9.5056 42.0529 9.08228L44.289 8.55776C45.853 8.1909 47.4807 8.1909 49.0446 8.55776L51.2808 9.08228C53.0854 9.5056 54.4946 10.9147 54.9179 12.7194L55.4424 14.9555C55.8093 16.5195 55.8093 18.1472 55.4424 19.7111L54.9179 21.9473C54.4946 23.752 53.0854 25.1611 51.2808 25.5844L49.0446 26.1089C47.4807 26.4758 45.853 26.4758 44.289 26.1089L42.0529 25.5844C40.2482 25.1611 38.8391 23.752 38.4158 21.9473L37.8913 19.7112C37.5244 18.1472 37.5244 16.5195 37.8913 14.9555L38.4158 12.7194Z" stroke="#00783A" strokeWidth="4.5" />
            <path d="M38.4158 42.0527C38.8391 40.248 40.2482 38.8389 42.0529 38.4155L44.289 37.891C45.853 37.5242 47.4807 37.5242 49.0446 37.891L51.2808 38.4155C53.0854 38.8389 54.4946 40.248 54.9179 42.0527L55.4424 44.2888C55.8093 45.8528 55.8093 47.4804 55.4424 49.0444L54.9179 51.2805C54.4946 53.0852 53.0854 54.4943 51.2808 54.9176L49.0446 55.4422C47.4807 55.809 45.853 55.809 44.289 55.4422L42.0529 54.9176C40.2482 54.4943 38.8391 53.0852 38.4158 51.2805L37.8913 49.0444C37.5244 47.4804 37.5244 45.8528 37.8913 44.2888L38.4158 42.0527Z" stroke="#00783A" strokeWidth="4.5" />
        </svg>
    )
}

export default IcTRANSEXTCARD