import { createAsyncThunk } from "@reduxjs/toolkit";
import homeSlice from "./home.slice";
import { TimeUtils } from "../../utils/time";
import commonSlice from "../common/common.slice";
import { ConstKey } from "../../assets/constants/constants";

const onCheckTicketError = (customerInfo, dataErr, dispatch) => {
  if(dataErr.Code === 31){
    const issueTicketResult = {
      isErr: true,
      messageErr: "Giao dịch bạn chọn không thể lấy vé vào quầy vào lúc này. Vui lòng chọn giao dịch khác"
    }
    if( customerInfo.BiometricFinger === "1" &&
        customerInfo.BiometricActive  === "1" && 
        customerInfo.BiometricFace  === "1"){
      issueTicketResult.messageErr = "Lấy vé thất bại, vui lòng thử lại"
    }
    dispatch(commonSlice.actions.setIssueTicketResult(issueTicketResult))
  } else {
    dispatch(commonSlice.actions.setIsSystemError(true))
  }
}

const onCheckTicketSuccess = (
    {getTicketMockType, deviceInfo, customerInfo, serviceList},
    {getState, dispatch, rejectWithValue}
  ) => {
    getTicketAction({
      mockType: getTicketMockType,
      deviceInfo: deviceInfo,
      serviceList: serviceList, 
      customerInfo: customerInfo,
      onSuccess: ()=>{
        dispatch(homeSlice.actions.setIsGetTicketSuccess(true))
      }, 
      onError: (dataErr) => {
        const issueTicketResult = {
          isErr: true,
          messageErr: "Giao dịch bạn chọn không thể lấy vé vào quầy vào lúc này. Vui lòng chọn giao dịch khác"
        }
        if(dataErr.Code !== 31){
            issueTicketResult.messageErr = "Lấy vé thất bại, vui lòng thử lại"
            
        }
        dispatch(commonSlice.actions.setIssueTicketResult(issueTicketResult))
      }
    },{
      getState: getState, 
      dispatch: dispatch, 
      rejectWithValue: rejectWithValue
    });
}

const checkTicketAction = async (
  {mockType, getTicketMockType, customerInfo, deviceInfo, serviceList, onSuccess, onError},
  { getState, dispatch, rejectWithValue }
) => {
  const currentTime = TimeUtils.getCurrentTime()

  const dataRequest = JSON.stringify({
    KioskID: deviceInfo.ID,
    ListService: serviceList,
    TimeStamp: currentTime,
    SessionId: deviceInfo.ID + currentTime
  });

  console.log("checkTicketAction => dataReq: ", dataRequest)

  dispatch(commonSlice.actions.setIsLoading(true));
  try {
    // const res = await axios.post(apiCheckTicket, dataRequest);
    await TimeUtils.sleep(1000)
    const {data} = {data: {
        Code: mockType===1?30:mockType===2?31:0,
        Message: mockType===1
                  ? "Internal Server Error"
                  : mockType===2
                    ? "don't have any counter to process this ticket's services"
                    : "",
      }};
    console.log("checkTicketAction => dataResp: ", data)
  
    if (data.Code === 0) {
      onSuccess({
        getTicketMockType: getTicketMockType,
        deviceInfo: deviceInfo,
        customerInfo: customerInfo,
        serviceList: serviceList
      },
      {
        getState: getState, 
        dispatch: dispatch, 
        rejectWithValue: rejectWithValue
      })
      return Promise.resolve(data);
    } else {
      var dataErr = {
        code: data.Code,
        message: data.Message
      }
      if(data.Code === 2){
        dataErr.message = "Kiosk does not exist"
      }
      //---
      onError(customerInfo, dataErr, dispatch)
      return Promise.reject();
    }
  } catch (error) {
    dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
    // console.log("error");
    return Promise.reject();
  } finally {
    console.log("finally");
    dispatch(commonSlice.actions.setIsLoading(false));
  }
};

const getTicketAction = async (
  { mockType, deviceInfo, customerInfo, serviceList, onSuccess, onError},
  { getState, dispatch, rejectWithValue }
) => {
  const currentTime = TimeUtils.getCurrentTime()

  const serviceDetails = []
    serviceList.map((item) => {
      serviceDetails.push({
        ServiceType : item.type, 
        Edited : false,
        serializedName : null 
      }) 
      return item
    })

    const dataRequest = JSON.stringify({
      KioskID: deviceInfo.ID,
      OfficeID: deviceInfo.OfficeID,
      CustomerInfos: {
        Name : customerInfo.name, 
        CustomerNumber : customerInfo.customerNumber, 
        Segment : customerInfo.Segment, 
        SegmentDesc : customerInfo.SegmentDesc, 
        PriorityCustomer : customerInfo.PriorityCustomer 
      },
      ListService: serviceList,
      ServiceDetails: serviceDetails,
      TimeStamp: currentTime,
      SessionId: deviceInfo.ID + currentTime
    });

  console.log("mockGetTicketAction => dataReq: ", dataRequest)
  dispatch(commonSlice.actions.setIsLoading(true));
  try {
    // const res = await axios.post(apiGetTicket, dataRequest);
    // const { data } = res;

    await TimeUtils.sleep(1000)
    const { data } = {
      data: {
        Code: mockType===1?30:mockType===2?31:0,
        Message: mockType===1
                  ? "Internal Server Error"
                  : mockType===2
                    ? "don't have any counter to process this ticket's services"
                    : "",
        TicketNumber: mockType===0?"2001":""
      }
    };

    console.log("mockGetTicketAction => dataResp: ", data)
  
    if (data.Code === 0) {
      const ticketInfo = {
        ticketNumber: data.TicketNumber,
        listService: serviceList
      }
      localStorage.setItem(ConstKey.TICKET_INFO, JSON.stringify(ticketInfo));
      dispatch(homeSlice.actions.setTicketInfo(ticketInfo))
      onSuccess()
      return Promise.resolve(ticketInfo);
    } else {
      var dataResult = {
        code: data.Code,
        message: data.Message
      }
      console.log("getTicketAction => error: ", data)
      //---
      onError(dataResult)
      return Promise.reject();
    }
  } catch (error) {
    dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
    // console.log("error");
    return Promise.reject();
  } finally {
    console.log("finally");
    dispatch(commonSlice.actions.setIsLoading(false));
  }
};

const onGetTicket = createAsyncThunk(
  "home/getTicket",
  async (
    { checkTickMockType, getTicketMockType, deviceInfo, customerInfo, serviceList},
    { getState, dispatch, rejectWithValue }
  ) => {
    checkTicketAction(
      {
        mockType: checkTickMockType,
        getTicketMockType: getTicketMockType,
        deviceInfo: deviceInfo,
        customerInfo: customerInfo,
        serviceList: serviceList,
        onSuccess: onCheckTicketSuccess,
        onError: onCheckTicketError
      },
      {
        getState: getState, 
        dispatch: dispatch, 
        rejectWithValue: rejectWithValue
      }
    )
  }
);

export const HomeActionMock = {
  onGetTicket: onGetTicket
}