import React from 'react'

export default function IcCloseGray(props) {
    return (
        <svg {...props} width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.8125 8.8125L21.1869 21.1869" stroke="#676767" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.8125 21.1875L21.1869 8.81313" stroke="#676767" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}
