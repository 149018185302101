
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
//img
import { ConstData } from "../assets/constants/constants";
//hook
import commonSlice from "../redux/common/common.slice";
import detectFaceSlice from "../redux/detectFace/detectFace.slice";

const MESSAGE = "MESSAGE";
const DATA = "DATA";
const STATUS = "STATUS";
let isFirst = false


const useConnectScannerMK = () => {
    const dispatch = useDispatch();
    const wsRef = useRef(null);
    const timerReconnect = useRef(0);
    const timeDelay = useRef(0);
    const isUnmounted = useRef(false);
    const [wsConnected, setWsConnected] = useState(false);
    const [message, setMessage] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [isVerifyFailed, setIsVerifyFailed] = useState(false);

    const connectWs = useCallback(() => {
        setWsConnected(false);
        wsRef.current = new WebSocket(`ws://${ConstData.ipAddressOfDevice}:8886`);
        wsRef.current.onopen = () => {
            console.log("connected");
            setWsConnected(true);
            clearTimeout(timerReconnect.current);
            clearTimeout(timeDelay.current)
        };
        wsRef.current.onmessage = async (e) => {
            // console.log("message", e)
            try {
                const evtData = JSON.parse(e.data);
                const { type } = evtData;
                switch (type) {
                    case MESSAGE: {
                        const { code } = evtData;
                        let message = "";
                        //todo: code 208 => ko có thiết bị => scan wee
                        if (code === 2) {
                            message = "Không tìm thấy thiết bị";
                        } else if (code === 3) {
                            message = "Không tìm thấy dịch vụ MK";
                        } else if (code === 10) {
                            setIsProcessing(true);
                        } else if (code === 210) {
                            setIsVerifyFailed(true);
                        } else if (code === 211) {
                            setIsVerifyFailed(true);
                        } else if (code === 51 || code === 1000 || code === 999) {
                            setIsVerifyFailed(true);
                        } else {
                            message = "";
                        }
                        setMessage(message);
                        break;
                    }
                    case STATUS: {
                        const { code } = evtData;
                        if (code === 1) {
                            if (isFirst) return
                            isFirst = true
                            dispatch(commonSlice.actions.setStepScanChip(2))
                            timeDelay.current = setTimeout(() => {
                                dispatch(commonSlice.actions.setStepScanChip(3))
                            }, 500)
                        } else if (code === 2) {
                            isFirst = false
                            dispatch(commonSlice.actions.setStepScanChip(1))
                        }
                        break;
                    }
                    case DATA: {
                        const { idcardData } = evtData;
                        const { faceImage, dg13 } = idcardData;
                        if (faceImage) {
                            dispatch(detectFaceSlice.actions.setFace({
                                base64: faceImage,
                                url: `data:image/png;base64,${faceImage}`
                            }))
                        }
                        if (dg13) {
                            const {
                                idCardNo,
                                name,
                                dateOfBirth,
                                gender,
                                nationality,
                                ethnic,
                                religion,
                                placeOfOrigin,
                                residenceAddress,
                                dateOfIssuance,
                                dateOfExpiry,
                            } = dg13;
                            console.log("idCardNo", idCardNo);
                            dispatch(commonSlice.actions.setIdCardNo(idCardNo))
                            dispatch(commonSlice.actions.setIsOcrSucceeded(true));
                        }
                        break
                    }
                    default: {

                    }
                }
            } catch (err) {
                console.log("cannot parse data", e.data, err);
            }
        };
        wsRef.current.onclose = (e) => {
            // console.log("close", e)
            setWsConnected(false);
            if (isUnmounted.current) return;
            timerReconnect.current = setTimeout(connectWs, 1000);
        };
        wsRef.current.onerror = (e) => {
            console.log("error", e);
        };
    }, []);

    useEffect(() => {
        connectWs();
    }, []);

    useEffect(() => {
        return () => {
            isUnmounted.current = true;
            clearTimeout(timerReconnect.current);
            clearTimeout(timeDelay.current)
            wsRef.current && wsRef.current.close();
            setWsConnected(false);
            setIsVerifyFailed(false);
            setIsProcessing(false);
        };
    }, []);

    return {
        wsRef,
        connectWs,
        wsConnected,
        message,
        setMessage,
        isProcessing,
        setIsProcessing,
        isVerifyFailed,
        setIsVerifyFailed,
    };
};
export default useConnectScannerMK;
