import styled, { css } from "styled-components";

const WrapUseRenderTime = styled.div`
    display: flex;
    flex-direction: column;
    letter-spacing: 0.1em;
    ${props => props.color ? css`color: ${props.color};` : null}
    .time{
        font-weight: 500;
        font-size: 24px;
    }

`

export {
    WrapUseRenderTime
}