/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
//css
import { Wrap } from "./index.styled";
//img
import { ConstData, ConstKey } from "../../assets/constants/constants";
//component
import SelectTransaction from "../../components/SelectTransaction";
//hook
import { commonSelector } from "../../redux/common/common.selectors";
import { homeSelector } from "../../redux/home/home.selectors";
import commonSlice from "../../redux/common/common.slice";
import { damping, variants } from "../../utils/transition_page";
import { motion } from "framer-motion";
import { HomeAction } from "../../redux/home/home.action";

const HomePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //---
    const customerInfo = useSelector(homeSelector.customerInfo)
    const deviceInfo = useSelector(commonSelector.deviceInfo)
    //---
    const isGetTicketSuccess = useSelector(homeSelector.isGetTicketSuccess)
    //---
    const onTransItemSelected = (transItem) => {
        //---
        dispatch(HomeAction.onGetTicket({
            deviceInfo: deviceInfo,
            customerInfo: customerInfo,
            serviceList: transItem
        }))
    }

    const onExit = () => {
        dispatch(commonSlice.actions.setIsEndOfSession(true))
    }

    useEffect(() => {
        dispatch(commonSlice.actions.setIsLoading(false));
        if (isGetTicketSuccess) {
            navigate('/issue-ticket')
        }
    }, [isGetTicketSuccess])
    //---
    return (
        <Wrap>
            <motion.div
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                    x: { type: "spring", stiffness: 300, damping: damping },
                    opacity: { duration: 0.2 },
                }}
            >
                <SelectTransaction
                    nameOffice={deviceInfo.officeName}
                    listItem={ConstData.transTypeList}
                    onItemSelected={onTransItemSelected}
                    onExit={onExit}
                />
            </motion.div>
        </Wrap>
    );
}

export default HomePage;