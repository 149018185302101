import { ConstKey } from "../../assets/constants/constants";

const isNetWorkErrSelector = (state) => state.common.isNetworkError;
const isSytemErrSelector = (state) => state.common.isSytemError;
const issueTicketResultSelector = (state) => state.common.issueTicketResult;
const isLoadingSelector = (state) => state.common.isLoading;
const isWarningEndTransSelector = (state) => state.common.isWarningEndTrans;
const isEndOfSessionSelector = (state) => state.common.isEndOfSession;
const is403ErrorSelector = (state) => state.common.is403Error;

const deviceInfoSelector = (state) => {
    var deviceInfo = state.common.deviceInfo;
    if (!deviceInfo){
        deviceInfo = JSON.parse(localStorage.getItem(ConstKey.DEVICE_INFO)) || null
        if (deviceInfo === "null"){
            deviceInfo = null
        }
    }
    return deviceInfo
}

export const commonSelector = {
    isNetWorkErr: isNetWorkErrSelector,
    deviceInfo: deviceInfoSelector,
    isLoading: isLoadingSelector,
    isSystemError: isSytemErrSelector,
    isWarningEndTrans: isWarningEndTransSelector,
    issueTicketResult: issueTicketResultSelector,
    isEndOfSession: isEndOfSessionSelector,
    is403Error: is403ErrorSelector,
}