import React from 'react'

export default function IcRate1(props) {
    return (
        <svg {...props} width={96} height={96} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.9999 95.9998C74.5095 95.9998 95.9998 74.5095 95.9998 47.9999C95.9998 21.4903 74.5095 0 47.9999 0C21.4903 0 0 21.4903 0 47.9999C0 74.5095 21.4903 95.9998 47.9999 95.9998Z" fill="#FFCE52" />
            <path d="M47.9946 6.26275C73.351 6.26275 93.9075 24.0164 93.9075 45.9148H95.9423C95.3669 33.5852 90.064 21.9511 81.1351 13.4292C72.2062 4.90717 60.3376 0.152344 47.9946 0.152344C35.6516 0.152344 23.7829 4.90717 14.854 13.4292C5.9251 21.9511 0.622214 33.5852 0.046875 45.9148H2.08165C2.08165 24.0164 22.6381 6.26275 47.9946 6.26275Z" fill="#FFE369" />
            <path d="M93.9128 45.914C93.9128 67.8124 73.3563 85.5661 47.9999 85.5661C22.6434 85.5661 2.08695 67.8124 2.08695 45.914H0.0521737C0.0229564 46.6068 0 47.3018 0 48.0009C1.89697e-07 60.7313 5.05712 72.9403 14.0588 81.942C23.0606 90.9437 35.2695 96.0008 47.9999 96.0008C60.7302 96.0008 72.9392 90.9437 81.9409 81.942C90.9426 72.9403 95.9998 60.7313 95.9998 48.0009C95.9998 47.3018 95.9768 46.6068 95.9476 45.914H93.9128Z" fill="#FFB32B" />
            <path d="M76.8247 18.4184C77.497 17.7461 77.2836 16.4428 76.3481 15.5073C75.4126 14.5718 74.1093 14.3585 73.437 15.0307C72.7647 15.703 72.9781 17.0064 73.9136 17.9419C74.8491 18.8773 76.1524 19.0907 76.8247 18.4184Z" fill="#F6FAFD" />
            <path d="M62.9272 8.1743C63.2638 7.83775 63.1574 7.18575 62.6897 6.71801C62.222 6.25027 61.5699 6.14391 61.2334 6.48046C60.8968 6.817 61.0032 7.46901 61.4709 7.93675C61.9387 8.40449 62.5907 8.51084 62.9272 8.1743Z" fill="#F6FAFD" />
            <path d="M72.2597 15.9395C73.6043 14.5949 73.1769 11.9876 71.3051 10.1158C69.4333 8.24399 66.826 7.81659 65.4814 9.16114C64.1369 10.5057 64.5643 13.1131 66.436 14.9848C68.3078 16.8566 70.9152 17.284 72.2597 15.9395Z" fill="#F6FAFD" />
            <path d="M68.8652 79.303C68.8652 73.543 59.5365 68.8683 47.9957 68.8683C36.4548 68.8683 27.1262 73.543 27.1262 79.303L26.9175 79.5743C23.7872 77.5491 21.2123 74.7742 19.4265 71.5014C17.6408 68.2287 16.7006 64.5618 16.6914 60.8335C16.6196 59.7223 16.9892 58.6277 17.72 57.7875C18.4508 56.9473 19.4836 56.4295 20.594 56.3465C24.5175 58.6005 33.9296 58.4335 47.9957 58.4335C62.0617 58.4335 71.4739 58.6005 75.3765 56.3465C76.4904 56.4248 77.5282 56.9405 78.2633 57.7811C78.9983 58.6218 79.371 59.7191 79.2999 60.8335C79.2908 64.5618 78.3506 68.2287 76.5648 71.5014C74.779 74.7742 72.2041 77.5491 69.0739 79.5743L68.8652 79.303Z" fill="#273941" />
            <path d="M68.8686 79.3057L69.0773 79.577C62.8089 83.6378 55.4662 85.7243 47.9991 85.5666C40.532 85.7243 33.1893 83.6378 26.9209 79.577L27.1296 79.3057C27.1296 73.5457 36.4583 68.8709 47.9991 68.8709C59.5399 68.8709 68.8686 73.5457 68.8686 79.3057Z" fill="#AE2D4C" />
            <path d="M32.3482 16.6968C33.6392 16.683 34.9189 16.9377 36.1062 17.4445C37.2936 17.9514 38.3626 18.6995 39.2456 19.6414C40.1286 20.5832 40.8062 21.6982 41.2355 22.9158C41.6648 24.1333 41.8364 25.4268 41.7395 26.7141C41.7395 41.7402 26.0874 50.088 22.957 50.088C21.4544 50.088 5.46833 42.3663 4.23703 28.4046C4.19529 27.8411 4.17442 27.2776 4.17442 26.7141C4.00464 24.4484 4.67119 22.1989 6.04797 20.3914C7.42474 18.5839 9.41625 17.3437 11.6457 16.9055C12.2756 16.7628 12.9198 16.6928 13.5657 16.6968C18.2613 16.6968 21.3918 20.0359 22.957 25.0446C24.5222 20.0359 27.6526 16.6968 32.3482 16.6968Z" fill="#CF4054" />
            <path d="M91.8258 26.7141C91.8258 27.2707 91.8049 27.8341 91.7632 28.4046C90.6571 42.3663 76.0484 50.088 73.0432 50.088C71.478 50.088 54.2607 41.7402 54.2607 26.7141C54.1637 25.4268 54.3354 24.1333 54.7647 22.9158C55.194 21.6982 55.8716 20.5832 56.7546 19.6414C57.6376 18.6995 58.7066 17.9514 59.894 17.4445C61.0813 16.9377 62.361 16.683 63.652 16.6968C68.3476 16.6968 71.478 20.0359 73.0432 25.0446C74.6084 20.0359 77.7389 16.6968 82.4345 16.6968C83.0804 16.6928 83.7246 16.7628 84.3545 16.9055C86.5839 17.3437 88.5755 18.5839 89.9522 20.3914C91.329 22.1989 91.9956 24.4484 91.8258 26.7141Z" fill="#CF4054" />
            <path d="M20.594 60.5204C24.5175 62.7744 33.9296 62.6074 47.9957 62.6074C62.0617 62.6074 71.4739 62.7744 75.3765 60.5204C76.2675 60.5638 77.1187 60.9023 77.7962 61.4826C78.4736 62.063 78.9388 62.8522 79.1184 63.726C79.2395 62.7666 79.3002 61.8005 79.2999 60.8335C79.371 59.7191 78.9983 58.6218 78.2633 57.7811C77.5282 56.9405 76.4904 56.4248 75.3765 56.3465C71.4739 58.6005 62.0617 58.4335 47.9957 58.4335C33.9296 58.4335 24.5175 58.6005 20.594 56.3465C19.4836 56.4295 18.4508 56.9473 17.72 57.7875C16.9892 58.6276 16.6196 59.7223 16.6914 60.8335C16.6911 61.7998 16.7518 62.7652 16.873 63.7239C17.0501 62.8529 17.5117 62.0654 18.1854 61.4855C18.859 60.9055 19.7063 60.5661 20.594 60.5204Z" fill="#141E21" />
            <path d="M67.8898 76.1453C61.8696 79.6935 54.9861 81.5091 47.9991 81.3919C41.0121 81.5091 34.1286 79.6935 28.1084 76.1453C27.4856 77.0828 27.1458 78.1796 27.1296 79.305L26.9209 79.5763C33.1893 83.6371 40.532 85.7236 47.9991 85.5658C55.4662 85.7236 62.8089 83.6371 69.0773 79.5763L68.8686 79.305C68.8524 78.1796 68.5126 77.0828 67.8898 76.1453Z" fill="#8A293D" />
            <path d="M73.0443 50.0879C71.5625 50.0879 56.0753 42.5957 54.4266 29.0493C54.3265 29.6573 54.2714 30.2718 54.2617 30.8879C54.2617 45.914 71.4791 54.2618 73.0443 54.2618C76.0495 54.2618 90.6581 46.5401 91.7642 32.5783C91.806 32.0079 91.8268 31.4444 91.8268 30.8879C91.8255 30.2542 91.7767 29.6215 91.6808 28.995C90.2095 42.5748 76.0057 50.0879 73.0443 50.0879Z" fill="#FBB40A" />
            <path d="M22.9593 50.0879C21.4776 50.0879 5.99032 42.5957 4.34163 29.0493C4.24151 29.6573 4.1864 30.2718 4.17676 30.8879C4.17676 45.914 21.3941 54.2618 22.9593 54.2618C25.9645 54.2618 40.5732 46.5401 41.6793 32.5783C41.721 32.0079 41.7419 31.4444 41.7419 30.8879C41.7405 30.2542 41.6917 29.6215 41.5958 28.995C40.1245 42.5748 25.9207 50.0879 22.9593 50.0879Z" fill="#FBB40A" />
            <path d="M82.4342 16.6968C77.7386 16.6968 74.6081 20.0359 73.0429 25.0446C74.2596 21.2881 76.6951 18.7837 80.3473 18.7837C81.332 18.7555 82.3118 18.9322 83.2247 19.3026C84.1376 19.673 84.9635 20.229 85.6502 20.9353C86.3369 21.6416 86.8694 22.4829 87.2139 23.4058C87.5585 24.3288 87.7075 25.3132 87.6516 26.2968C87.6516 37.5663 75.4784 43.8271 73.0429 43.8271C71.8742 43.8271 59.4402 38.0358 58.4823 27.5635C58.451 27.1461 58.4343 26.7183 58.4343 26.2968C58.3271 24.5703 58.8606 22.8651 59.9327 21.5076C61.0048 20.1502 62.5401 19.236 64.2443 18.9402C64.7351 18.8328 65.2362 18.7803 65.7386 18.7837C69.3908 18.7837 71.8262 21.2881 73.0429 25.0446C71.4777 20.0359 68.3473 16.6968 63.6516 16.6968C63.0058 16.6928 62.3616 16.7628 61.7316 16.9055C59.5022 17.3437 57.5107 18.5839 56.1339 20.3914C54.7571 22.1989 54.0906 24.4484 54.2604 26.7141C54.2604 27.2707 54.2812 27.8341 54.323 28.4046C55.5543 42.3663 71.5403 50.088 73.0429 50.088C76.1734 50.088 91.8255 41.7402 91.8255 26.7141C91.9224 25.4268 91.7508 24.1333 91.3215 22.9158C90.8922 21.6982 90.2145 20.5832 89.3316 19.6414C88.4486 18.6995 87.3795 17.9514 86.1922 17.4445C85.0049 16.9377 83.7251 16.683 82.4342 16.6968Z" fill="#AE2D4C" />
            <path d="M32.3482 16.6968C27.6526 16.6968 24.5222 20.0359 22.957 25.0446C24.1737 21.2881 26.6091 18.7837 30.2613 18.7837C31.246 18.7555 32.2259 18.9322 33.1387 19.3026C34.0516 19.673 34.8775 20.229 35.5643 20.9353C36.251 21.6416 36.7835 22.4829 37.128 23.4058C37.4725 24.3288 37.6215 25.3132 37.5656 26.2968C37.5656 37.5663 25.3924 43.8271 22.957 43.8271C21.7883 43.8271 9.35423 38.0358 8.39632 27.5635C8.36501 27.142 8.34832 26.7141 8.34832 26.2968C8.24114 24.5703 8.7747 22.8651 9.84679 21.5076C10.9189 20.1502 12.4541 19.236 14.1584 18.9402C14.6491 18.8328 15.1503 18.7803 15.6526 18.7837C19.3048 18.7837 21.7403 21.2881 22.957 25.0446C21.3918 20.0359 18.2613 16.6968 13.5657 16.6968C12.9198 16.6928 12.2756 16.7628 11.6457 16.9055C9.41625 17.3437 7.42474 18.5839 6.04797 20.3914C4.67119 22.1989 4.00464 24.4484 4.17442 26.7141C4.17442 27.2707 4.19529 27.8341 4.23703 28.4046C5.46833 42.3663 21.4544 50.088 22.957 50.088C26.0874 50.088 41.7395 41.7402 41.7395 26.7141C41.8364 25.4268 41.6648 24.1333 41.2355 22.9158C40.8062 21.6982 40.1286 20.5832 39.2456 19.6414C38.3626 18.6995 37.2936 17.9514 36.1062 17.4445C34.9189 16.9377 33.6392 16.683 32.3482 16.6968Z" fill="#AE2D4C" />
            <path d="M87.2661 26.7685C87.9383 26.0963 87.725 24.7929 86.7895 23.8574C85.854 22.9219 84.5507 22.7086 83.8784 23.3809C83.2061 24.0531 83.4195 25.3565 84.355 26.292C85.2904 27.2275 86.5938 27.4408 87.2661 26.7685Z" fill="#F6FAFD" />
            <path d="M83.7963 22.7939C84.1329 22.4574 84.0265 21.8054 83.5588 21.3376C83.0911 20.8699 82.4391 20.7635 82.1025 21.1001C81.766 21.4366 81.8723 22.0886 82.3401 22.5564C82.8078 23.0241 83.4598 23.1305 83.7963 22.7939Z" fill="#F6FAFD" />
            <path d="M37.1714 26.7539C37.8436 26.0816 37.6303 24.7783 36.6948 23.8428C35.7593 22.9073 34.456 22.6939 33.7837 23.3662C33.1114 24.0385 33.3248 25.3418 34.2603 26.2773C35.1957 27.2128 36.4991 27.4262 37.1714 26.7539Z" fill="#F6FAFD" />
            <path d="M33.7065 22.7734C34.0431 22.4369 33.9367 21.7849 33.469 21.3171C33.0012 20.8494 32.3492 20.743 32.0127 21.0796C31.6761 21.4161 31.7825 22.0681 32.2502 22.5359C32.718 23.0036 33.37 23.11 33.7065 22.7734Z" fill="#F6FAFD" />
        </svg>
    )
}
