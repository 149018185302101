import { Player } from "@lottiefiles/react-lottie-player";
import Lottie from "lottie-react";
import React from "react";

const LottieAnimation = ({ data, handleComplete,isLoop = false, ...props }) => {
    return (
        <Lottie
            animationData={data}
            onComplete={handleComplete}
            loop={isLoop}
            {...props}
        />
        
    )
}

export default LottieAnimation