import { styled } from "styled-components";
import PageBg from "../../components/PageBg/index.styled";
import { ConstData } from "../../assets/constants/constants";

const Wrap = styled(PageBg)``

const WrapDetectFace = styled.div`
    position: absolute;
    width: ${ConstData.screen.Width};
    height: ${ConstData.screen.Height};
    font-family: 'SVN-Gilroy';
    font-style: normal;
    .face-content{
        position: absolute;
        width: ${ConstData.screen.Width};
        height: ${ConstData.screen.Height};
        z-index: 21;
    }
    .header-content {
        width: 100%;
        top: 0px;
        position: absolute;
        z-index: 31;
    }
    .bottom-content{
        position: absolute;
        width: 100%;
        bottom: 0px;
        z-index: 31;
    }
    .block-face {
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: #ccc;
    }
    .lottie {
        position: absolute;
        top: 160px;
        width: 100%;
        height: 160px;
        z-index: 31;
        rotate: 180deg;
    }    
    .count{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 22;
        font-size: 100px;
        color: #ffffff;
        font-weight: 700;
    }
    .block-text{
        position: absolute;
        top: 400px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 31;
        border-radius: 8px;
        background: #201B19;
        width: fit-content;
        padding: 20px 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;        
        .icon{
            flex-shrink: 0;
        }
        .text{
            font-family: SVN-Gilroy;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            color: #FFFFFF;
            white-space: nowrap;
        }
    }
    .block-avatar {
        width: ${ConstData.screen.CameraWidth};
        height: ${ConstData.screen.CameraHeght};
        top: 0px;
        position: absolute;
    }
`

export {
    WrapDetectFace,
    Wrap
}