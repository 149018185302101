import React from 'react'

const IcTRANSINT = (props) => {
    return (
        <svg {...props} width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.54004 23.2007H55.4596" stroke="#00783A" strokeWidth="4.5" />
            <path d="M7.69422 23.5967C9.48797 16.3729 15.2159 10.7876 22.4813 9.17765L23.6916 8.90946C29.1641 7.69685 34.8356 7.69685 40.308 8.90946L41.5183 9.17765C48.7838 10.7876 54.5117 16.3729 56.3055 23.5967C57.6757 29.1151 57.6757 34.8849 56.3055 40.4033C54.5117 47.6271 48.7838 53.2124 41.5183 54.8224L40.308 55.0905C34.8356 56.3032 29.1641 56.3032 23.6916 55.0905L22.4813 54.8223C15.2159 53.2124 9.48797 47.6271 7.69422 40.4033C6.32393 34.8849 6.32393 29.1151 7.69422 23.5967Z" stroke="#AB1F24" strokeWidth="4.5" />
            <path d="M56.3055 40.4033C57.6757 34.8849 57.6757 29.1151 56.3055 23.5967C54.5117 16.3729 48.7838 10.7876 41.5183 9.17765L40.308 8.90946C34.8356 7.69685 29.1641 7.69685 23.6916 8.90946L22.4813 9.17765C15.2159 10.7876 9.48797 16.3729 7.69422 23.5967C6.32393 29.1151 6.32393 34.8849 7.69422 40.4033C9.48797 47.6271 15.2159 53.2124 22.4813 54.8223L23.6916 55.0905" stroke="#00783A" strokeWidth="4.5" strokeLinecap="round" />
            <path d="M18.6665 32L29.3332 32" stroke="#00783A" strokeWidth="4.5" strokeLinecap="round" />
        </svg>
    )
}

export default IcTRANSINT