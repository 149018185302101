import React from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'

//css
import './index.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { init } from '@sentry/browser';
//img
import { ConstData, ConstKey } from './assets/constants/constants';

//component
import App from './App'
import HomePage from './page/HomePage'
import MainPage from './page/MainPage';
import PrivateRoute from './controls/PrivateRoute';
import ScanFingerPage from './page/ScanFingerPage';
import IssueTicketPage from './page/IssueTicketPage';
import DetectFacePage from './page/DetectFacePage';
import ScanCCCDPage from './page/ScanCCCDPage';
import RatingThanksPage from './page/RateThanksPage';
import RatingPage from './page/RatingPage';
//hook
import store from './redux/store'

// localStorage.removeItem(ConstKey.DEVICE_INFO)
//   localStorage.removeItem(ConstKey.CUSTOMER_INFO)
//   localStorage.removeItem(ConstKey.TICKET_INFO)

const router = createBrowserRouter([
  {
    path: ConstData.routePath.MainPage,
    element: <App />,
    children: [
      {
        index: true,
        element: <MainPage />,
      },
      {
        path: ConstData.routePath.ScanFingerPage,
        element: <ScanFingerPage />,
      },
      {
        path: ConstData.routePath.HomePage,
        element: <PrivateRoute><HomePage /></PrivateRoute>,
      },
      {
        path: ConstData.routePath.IssueTicketPage,
        element: <IssueTicketPage />,
      },
      {
        path: ConstData.routePath.DetectFace,
        element: <DetectFacePage />,
      },
      {
        path: ConstData.routePath.ScanCCCD,
        element: <ScanCCCDPage />,
      },     
      {
        path: ConstData.routePath.RatingPage,
        element: <RatingPage />,
      },
      {
        path: ConstData.routePath.ThanksPage,
        element: <RatingThanksPage />,
      },
    ]
  },
]);

init({
  ignoreErrors: [
    'AbortError: The play() request was interrupted',
  ],
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
)
