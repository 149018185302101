import React from 'react'

const IcDetectWhite = (props) => {
    return (
        <svg {...props} width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-outside-1_9947_20083" maskUnits="userSpaceOnUse" x="13.5708" y="26.7676" width={15} height={6} fill="black">
                <rect fill="white" x="13.5708" y="26.7676" width={15} height={6} />
                <path fillRule="evenodd" clipRule="evenodd" d="M27.1331 29.3683C27.5075 29.0225 27.5307 28.4387 27.1849 28.0643C26.839 27.6899 26.2552 27.6667 25.8808 28.0125C24.5485 29.2431 22.8153 29.9208 21.0003 29.9208C19.1853 29.9208 17.4521 29.2431 16.1199 28.0125C15.7454 27.6667 15.1616 27.6899 14.8158 28.0643C14.4699 28.4387 14.4931 29.0225 14.8675 29.3683C16.5418 30.9148 18.7197 31.7665 21.0003 31.7665C23.2809 31.7665 25.4588 30.9148 27.1331 29.3683Z" />
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.1331 29.3683C27.5075 29.0225 27.5307 28.4387 27.1849 28.0643C26.839 27.6899 26.2552 27.6667 25.8808 28.0125C24.5485 29.2431 22.8153 29.9208 21.0003 29.9208C19.1853 29.9208 17.4521 29.2431 16.1199 28.0125C15.7454 27.6667 15.1616 27.6899 14.8158 28.0643C14.4699 28.4387 14.4931 29.0225 14.8675 29.3683C16.5418 30.9148 18.7197 31.7665 21.0003 31.7665C23.2809 31.7665 25.4588 30.9148 27.1331 29.3683Z" fill="white" />
            <path d="M27.1849 28.0643L27.5521 27.725L27.5521 27.725L27.1849 28.0643ZM25.8808 28.0125L25.5415 27.6452L25.5415 27.6452L25.8808 28.0125ZM16.1199 28.0125L16.4591 27.6452L16.4591 27.6452L16.1199 28.0125ZM14.8158 28.0643L14.4485 27.725L14.4485 27.725L14.8158 28.0643ZM14.8675 29.3683L14.5282 29.7356L14.5282 29.7356L14.8675 29.3683ZM27.4724 29.7356C28.0497 29.2024 28.0853 28.3022 27.5521 27.725L26.8176 28.4035C26.976 28.5751 26.9654 28.8426 26.7939 29.0011L27.4724 29.7356ZM27.5521 27.725C27.0189 27.1477 26.1188 27.1121 25.5415 27.6452L26.22 28.3798C26.3916 28.2214 26.6591 28.232 26.8176 28.4035L27.5521 27.725ZM25.5415 27.6452C24.3017 28.7904 22.6896 29.4208 21.0003 29.4208V30.4208C22.9409 30.4208 24.7953 29.6958 26.22 28.3798L25.5415 27.6452ZM21.0003 29.4208C19.311 29.4208 17.6989 28.7904 16.4591 27.6452L15.7806 28.3798C17.2053 29.6958 19.0597 30.4208 21.0003 30.4208V29.4208ZM16.4591 27.6452C15.8818 27.1121 14.9817 27.1478 14.4485 27.725L15.183 28.4036C15.3415 28.232 15.609 28.2214 15.7806 28.3798L16.4591 27.6452ZM14.4485 27.725C13.9153 28.3022 13.9509 29.2024 14.5282 29.7356L15.2067 29.0011C15.0352 28.8426 15.0246 28.5751 15.1831 28.4035L14.4485 27.725ZM14.5282 29.7356C16.2949 31.3675 18.5941 32.2665 21.0003 32.2665V31.2665C18.8454 31.2665 16.7886 30.4622 15.2067 29.0011L14.5282 29.7356ZM21.0003 32.2665C23.4066 32.2665 25.7057 31.3675 27.4724 29.7356L26.7939 29.0011C25.212 30.4622 23.1552 31.2665 21.0003 31.2665V32.2665Z" fill="white" mask="url(#path-1-outside-1_9947_20083)" />
            <mask id="path-3-outside-2_9947_20083" maskUnits="userSpaceOnUse" x="17.8134" y="14.7705" width={6} height={12} fill="black">
                <rect fill="white" x="17.8134" y="14.7705" width={6} height={12} />
                <path fillRule="evenodd" clipRule="evenodd" d="M22.8466 23.2559V16.6934C22.8466 16.1837 22.4334 15.7705 21.9237 15.7705C21.414 15.7705 21.0009 16.1837 21.0009 16.6934V23.2559C21.0009 23.5386 20.7709 23.7686 20.4882 23.7686H19.7362C19.2265 23.7686 18.8134 24.1817 18.8134 24.6914C18.8134 25.2011 19.2265 25.6143 19.7362 25.6143H20.4882C21.7886 25.6143 22.8466 24.5563 22.8466 23.2559Z" />
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.8466 23.2559V16.6934C22.8466 16.1837 22.4334 15.7705 21.9237 15.7705C21.414 15.7705 21.0009 16.1837 21.0009 16.6934V23.2559C21.0009 23.5386 20.7709 23.7686 20.4882 23.7686H19.7362C19.2265 23.7686 18.8134 24.1817 18.8134 24.6914C18.8134 25.2011 19.2265 25.6143 19.7362 25.6143H20.4882C21.7886 25.6143 22.8466 24.5563 22.8466 23.2559Z" fill="white" />
            <path d="M23.3466 23.2559V16.6934H22.3466V23.2559H23.3466ZM23.3466 16.6934C23.3466 15.9075 22.7095 15.2705 21.9237 15.2705V16.2705C22.1572 16.2705 22.3466 16.4598 22.3466 16.6934H23.3466ZM21.9237 15.2705C21.1379 15.2705 20.5009 15.9075 20.5009 16.6934H21.5009C21.5009 16.4598 21.6902 16.2705 21.9237 16.2705V15.2705ZM20.5009 16.6934V23.2559H21.5009V16.6934H20.5009ZM20.5009 23.2559C20.5009 23.2582 20.5005 23.2593 20.5001 23.2602C20.4996 23.2614 20.4986 23.263 20.497 23.2647C20.4953 23.2663 20.4937 23.2673 20.4925 23.2678C20.4916 23.2682 20.4905 23.2686 20.4882 23.2686V24.2686C21.047 24.2686 21.5009 23.8147 21.5009 23.2559H20.5009ZM20.4882 23.2686H19.7362V24.2686H20.4882V23.2686ZM19.7362 23.2686C18.9504 23.2686 18.3134 23.9056 18.3134 24.6914H19.3134C19.3134 24.4579 19.5027 24.2686 19.7362 24.2686V23.2686ZM18.3134 24.6914C18.3134 25.4772 18.9504 26.1143 19.7362 26.1143V25.1143C19.5027 25.1143 19.3134 24.925 19.3134 24.6914H18.3134ZM19.7362 26.1143H20.4882V25.1143H19.7362V26.1143ZM20.4882 26.1143C22.0647 26.1143 23.3466 24.8324 23.3466 23.2559H22.3466C22.3466 24.2802 21.5124 25.1143 20.4882 25.1143V26.1143Z" fill="white" mask="url(#path-3-outside-2_9947_20083)" />
            <mask id="path-5-outside-3_9947_20083" maskUnits="userSpaceOnUse" x="26.46" y="14.7705" width={4} height={6} fill="black">
                <rect fill="white" x="26.46" y="14.7705" width={4} height={6} />
                <path fillRule="evenodd" clipRule="evenodd" d="M29.2031 19.2739V16.6421C29.2031 16.1607 28.8129 15.7705 28.3315 15.7705C27.8502 15.7705 27.46 16.1607 27.46 16.6421V19.2739C27.46 19.7553 27.8502 20.1455 28.3315 20.1455C28.8129 20.1455 29.2031 19.7553 29.2031 19.2739Z" />
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M29.2031 19.2739V16.6421C29.2031 16.1607 28.8129 15.7705 28.3315 15.7705C27.8502 15.7705 27.46 16.1607 27.46 16.6421V19.2739C27.46 19.7553 27.8502 20.1455 28.3315 20.1455C28.8129 20.1455 29.2031 19.7553 29.2031 19.2739Z" fill="white" />
            <path d="M29.7031 19.2739V16.6421H28.7031V19.2739H29.7031ZM29.7031 16.6421C29.7031 15.8846 29.089 15.2705 28.3315 15.2705V16.2705C28.5368 16.2705 28.7031 16.4369 28.7031 16.6421H29.7031ZM28.3315 15.2705C27.574 15.2705 26.96 15.8846 26.96 16.6421H27.96C27.96 16.4369 28.1263 16.2705 28.3315 16.2705V15.2705ZM26.96 16.6421V19.2739H27.96V16.6421H26.96ZM26.96 19.2739C26.96 20.0314 27.574 20.6455 28.3315 20.6455V19.6455C28.1263 19.6455 27.96 19.4791 27.96 19.2739H26.96ZM28.3315 20.6455C29.089 20.6455 29.7031 20.0314 29.7031 19.2739H28.7031C28.7031 19.4791 28.5368 19.6455 28.3315 19.6455V20.6455Z" fill="white" mask="url(#path-5-outside-3_9947_20083)" />
            <mask id="path-7-outside-4_9947_20083" maskUnits="userSpaceOnUse" x="12.0011" y="14.7705" width={4} height={6} fill="black">
                <rect fill="white" x="12.0011" y="14.7705" width={4} height={6} />
                <path fillRule="evenodd" clipRule="evenodd" d="M13.8727 20.1455C13.3913 20.1455 13.0011 19.7553 13.0011 19.2739V16.6421C13.0011 16.1607 13.3913 15.7705 13.8727 15.7705C14.354 15.7705 14.7443 16.1607 14.7443 16.6421V19.2739C14.7443 19.7553 14.354 20.1455 13.8727 20.1455Z" />
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.8727 20.1455C13.3913 20.1455 13.0011 19.7553 13.0011 19.2739V16.6421C13.0011 16.1607 13.3913 15.7705 13.8727 15.7705C14.354 15.7705 14.7443 16.1607 14.7443 16.6421V19.2739C14.7443 19.7553 14.354 20.1455 13.8727 20.1455Z" fill="white" />
            <path d="M13.8727 19.6455C13.6675 19.6455 13.5011 19.4791 13.5011 19.2739H12.5011C12.5011 20.0314 13.1152 20.6455 13.8727 20.6455V19.6455ZM13.5011 19.2739V16.6421H12.5011V19.2739H13.5011ZM13.5011 16.6421C13.5011 16.4369 13.6675 16.2705 13.8727 16.2705V15.2705C13.1152 15.2705 12.5011 15.8846 12.5011 16.6421H13.5011ZM13.8727 16.2705C14.0779 16.2705 14.2443 16.4369 14.2443 16.6421H15.2443C15.2443 15.8846 14.6302 15.2705 13.8727 15.2705V16.2705ZM14.2443 16.6421V19.2739H15.2443V16.6421H14.2443ZM14.2443 19.2739C14.2443 19.4791 14.0779 19.6455 13.8727 19.6455V20.6455C14.6302 20.6455 15.2443 20.0314 15.2443 19.2739H14.2443Z" fill="white" mask="url(#path-7-outside-4_9947_20083)" />
            <path d="M14 5.25H8.75C7.82174 5.25 6.9315 5.61875 6.27513 6.27513C5.61875 6.9315 5.25 7.82174 5.25 8.75V14M36.75 14V8.75C36.75 7.82174 36.3813 6.9315 35.7249 6.27513C35.0685 5.61875 34.1783 5.25 33.25 5.25H28M28 36.75H33.25C34.1783 36.75 35.0685 36.3813 35.7249 35.7249C36.3813 35.0685 36.75 34.1783 36.75 33.25V28M5.25 28V33.25C5.25 34.1783 5.61875 35.0685 6.27513 35.7249C6.9315 36.3813 7.82174 36.75 8.75 36.75H14" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcDetectWhite