import { createSlice } from "@reduxjs/toolkit";
import { ConstData } from "../../assets/constants/constants";
import { DetectFaceAction } from "./detectFace.action";

const initialState = {
    advertisingInfo: ConstData.advertisingInfo,
    faceData: null
}

const findUserByFace = (state, action) => {
    // return initialState
    console.log("findUserByFace action")
}

const detectFaceSlice = createSlice({
    name: 'detectFace',
    initialState,
    reducers: {
        getAdvertisingList: (state, action) => {
            state.advertisingInfo = action.payload
        },
        setFace: (state, action) => {
            state.faceData = action.payload
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(DetectFaceAction.mockFindUserByFace.fulfilled, findUserByFace)
            // .addCase(DetectFaceAction.findUserByFace.fulfilled, (state, action) => { })
    },
});

export default detectFaceSlice