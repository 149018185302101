import React from 'react'
import { WrapFormTransactionReceipt } from './index.styled'
import LogoName from '../../assets/img/logo_name.png'
import { TimeUtils } from '../../utils/time'
import { useSelector } from 'react-redux'
import { homeSelector } from '../../redux/home/home.selectors'
import { commonSelector } from '../../redux/common/common.selectors'
import { ConstData } from '../../assets/constants/constants'

const FormTransactionReceipt = ({ refForm }) => {
    const ticketInfo = useSelector(homeSelector.ticketInfo)
    const customerInfo = useSelector(homeSelector.customerInfo)
    const deviceInfo = useSelector(commonSelector.deviceInfo)
    const timeTransaction = TimeUtils.convertDateTime(new Date(), "DD/MM/YYYY HH:mm")

    console.log("FormTransactionReceipt", ticketInfo);
    return (
        <WrapFormTransactionReceipt ref={refForm}>
            <div className='content'>
                <div className='content-header'>
                    <div className='content-header-left'>
                        <img src={LogoName} alt="LogoName" />
                    </div>
                    <div className='content-header-right'>
                        <span className='name-office'>{deviceInfo.officeName}</span>
                        <span className='time'>{timeTransaction}</span>
                    </div>
                </div>
                <div className='content-number'>
                    <div className="content-number-transaction">số vé giao dịch</div>
                    <div className="content-number-bill">{ticketInfo.ticketNumber}</div>
                </div>
                <div className='content-desp'>
                    <span className="content-desp-value">DỊCH VỤ YÊU CẦU</span>
                    {
                        customerInfo && customerInfo.type === ConstData.customerType.VIP
                            ?
                            <span className="content-desp-service">Tất cả dịch vụ</span>
                            :
                            // ticketInfo && ticketInfo.listService?.map((item, index) => {
                            //     return <span key={index} className="content-desp-service">{item || "-"}</span>
                            // })
                            ticketInfo && ticketInfo.listService &&
                            <span className="content-desp-service">{ticketInfo.listService.value || "-"}</span>
                    }
                </div>
                <div className='content-bottom'>
                    Xin quý khách vui lòng chờ trong ít phút.
                </div>
            </div>
        </WrapFormTransactionReceipt>
    )
}

export default FormTransactionReceipt