import React from 'react'

export default function IcRate2(props) {
  return (
    <svg {...props} width={96} height={97} viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z" fill="#FFCE52" />
      <path d="M47.9947 6.26251C73.3512 6.26251 93.9077 24.0162 93.9077 45.9147H95.9425C95.3672 33.5851 90.0643 21.9509 81.1353 13.4289C72.2064 4.90692 60.3377 0.152085 47.9947 0.152085C35.6517 0.152085 23.783 4.90692 14.8541 13.4289C5.92511 21.9509 0.622216 33.5851 0.046875 45.9147H2.08166C2.08166 24.0162 22.6382 6.26251 47.9947 6.26251Z" fill="#FFE369" />
      <path d="M93.913 45.9141C93.913 67.8126 73.3565 85.5664 48 85.5664C22.6435 85.5664 2.08696 67.8126 2.08696 45.9141H0.0521739C0.0229565 46.607 0 47.302 0 48.0011C1.89698e-07 60.7315 5.05713 72.9405 14.0589 81.9423C23.0606 90.944 35.2696 96.0011 48 96.0011C60.7304 96.0011 72.9394 90.944 81.9411 81.9423C90.9429 72.9405 96 60.7315 96 48.0011C96 47.302 95.977 46.607 95.9478 45.9141H93.913Z" fill="#FFB32B" />
      <path d="M75.7827 17.3738C76.455 16.7015 76.2416 15.3982 75.3061 14.4627C74.3706 13.5272 73.0673 13.3138 72.395 13.9861C71.7227 14.6584 71.9361 15.9618 72.8716 16.8972C73.8071 17.8327 75.1104 18.0461 75.7827 17.3738Z" fill="#F6FAFD" />
      <path d="M61.8882 7.13046C62.2247 6.79391 62.1184 6.14191 61.6506 5.67416C61.1829 5.20642 60.5309 5.10006 60.1943 5.43661C59.8578 5.77316 59.9641 6.42517 60.4319 6.89291C60.8996 7.36065 61.5516 7.46701 61.8882 7.13046Z" fill="#F6FAFD" />
      <path d="M71.2207 14.8919C72.5652 13.5474 72.1378 10.94 70.2661 9.06822C68.3943 7.19643 65.7869 6.76903 64.4423 8.11359C63.0978 9.45814 63.5252 12.0655 65.397 13.9373C67.2688 15.8091 69.8761 16.2365 71.2207 14.8919Z" fill="#F6FAFD" />
      <path d="M70.9554 70.9554C79.0235 70.9554 85.5641 64.4149 85.5641 56.3467C85.5641 48.2786 79.0235 41.738 70.9554 41.738C62.8872 41.738 56.3467 48.2786 56.3467 56.3467C56.3467 64.4149 62.8872 70.9554 70.9554 70.9554Z" fill="url(#paint0_radial_10167_21085)" />
      <path d="M25.0462 70.9554C33.1144 70.9554 39.6549 64.4149 39.6549 56.3467C39.6549 48.2786 33.1144 41.738 25.0462 41.738C16.978 41.738 10.4375 48.2786 10.4375 56.3467C10.4375 64.4149 16.978 70.9554 25.0462 70.9554Z" fill="url(#paint1_radial_10167_21085)" />
      <path d="M48.004 79.3043C46.3435 79.3043 44.7511 78.6447 43.5769 77.4705C42.4028 76.2964 41.7432 74.7039 41.7432 73.0434H45.9171C45.9171 73.4562 46.0395 73.8597 46.2688 74.2029C46.4981 74.5461 46.8241 74.8135 47.2054 74.9715C47.5867 75.1295 48.0064 75.1708 48.4112 75.0903C48.816 75.0097 49.1879 74.811 49.4797 74.5191C49.7716 74.2273 49.9704 73.8554 50.0509 73.4506C50.1314 73.0457 50.0901 72.6261 49.9321 72.2448C49.7742 71.8634 49.5067 71.5375 49.1635 71.3082C48.8203 71.0789 48.4168 70.9565 48.004 70.9565C47.4505 70.9565 46.9197 70.7366 46.5283 70.3452C46.137 69.9538 45.9171 69.423 45.9171 68.8695C45.9171 68.316 46.137 67.7852 46.5283 67.3938C46.9197 67.0024 47.4505 66.7825 48.004 66.7825C48.4168 66.7825 48.8203 66.6602 49.1635 66.4308C49.5067 66.2015 49.7742 65.8756 49.9321 65.4942C50.0901 65.1129 50.1314 64.6933 50.0509 64.2884C49.9704 63.8836 49.7716 63.5118 49.4797 63.2199C49.1879 62.928 48.816 62.7293 48.4112 62.6487C48.0064 62.5682 47.5867 62.6095 47.2054 62.7675C46.8241 62.9255 46.4981 63.1929 46.2688 63.5361C46.0395 63.8793 45.9171 64.2828 45.9171 64.6956H41.7432C41.7424 63.6803 41.9886 62.68 42.4604 61.7809C42.9323 60.8819 43.6158 60.1112 44.4519 59.5351C45.288 58.959 46.2517 58.595 47.2598 58.4743C48.268 58.3536 49.2904 58.4799 50.2388 58.8423C51.1873 59.2048 52.0334 59.7924 52.7041 60.5546C53.3749 61.3169 53.8502 62.2308 54.0891 63.2176C54.328 64.2045 54.3233 65.2346 54.0755 66.2192C53.8276 67.2039 53.344 68.1134 52.6663 68.8695C53.4718 69.7701 53.9995 70.8845 54.1858 72.0783C54.372 73.2721 54.2088 74.4943 53.716 75.5974C53.2231 76.7006 52.4215 77.6375 51.408 78.2953C50.3945 78.953 49.2123 79.3034 48.004 79.3043Z" fill="#273941" />
      <path d="M77.2163 41.7362H73.0423C73.0423 40.0757 72.3827 38.4833 71.2086 37.3091C70.0344 36.135 68.442 35.4753 66.7815 35.4753C65.121 35.4753 63.5285 36.135 62.3544 37.3091C61.1802 38.4833 60.5206 40.0757 60.5206 41.7362H56.3467C56.3467 38.9687 57.4461 36.3146 59.403 34.3577C61.3599 32.4008 64.014 31.3014 66.7815 31.3014C69.549 31.3014 72.2031 32.4008 74.16 34.3577C76.1169 36.3146 77.2163 38.9687 77.2163 41.7362Z" fill="#273941" />
      <path d="M39.6508 41.7362H35.4769C35.4769 40.0757 34.8173 38.4833 33.6431 37.3091C32.469 36.135 30.8765 35.4753 29.216 35.4753C27.5556 35.4753 25.9631 36.135 24.7889 37.3091C23.6148 38.4833 22.9552 40.0757 22.9552 41.7362H18.7812C18.7813 38.9687 19.8806 36.3146 21.8375 34.3577C23.7944 32.4008 26.4486 31.3014 29.216 31.3014C31.9835 31.3014 34.6376 32.4008 36.5945 34.3577C38.5514 36.3146 39.6508 38.9687 39.6508 41.7362Z" fill="#273941" />
      <defs>
        <radialGradient id="paint0_radial_10167_21085" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(70.9804 56.3674) scale(14.6129 14.6129)">
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset={1} stopColor="#FFD364" />
        </radialGradient>
        <radialGradient id="paint1_radial_10167_21085" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(451.263 998.555) scale(17.0483 17.0484)">
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset={1} stopColor="#FFD364" />
        </radialGradient>
      </defs>
    </svg>
  )
}
