import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ConstData, ConstKey } from "../../assets/constants/constants";
import commonSlice from "../common/common.slice";
import { TimeUtils } from "../../utils/time";
import homeSlice from "../home/home.slice";

// const apiFindUserByFace = `${ConstData.host}/kiosk/find-user-by-face`;
const apiFindUserByFace = `${ConstData.hostLocal}/kiosk/find-user-by-face`;
const apiFindUserByCifs = `${ConstData.hostLocal}/kiosk/get-cifs`;

const findUserByCifs = createAsyncThunk(
  "detectFace/apiFindUserByCifs",
  async ({ faceData, deviceInfo, idCardNo, callBack, callBackError }, { getState, rejectWithValue, dispatch }) => {
    const currentTime = TimeUtils.getCurrentTime()
    //---
    const dataRequest = JSON.stringify({
      KioskID: deviceInfo.deviceId,
      CustomerID: idCardNo,
      TimeStamp: currentTime,
      SessionId: deviceInfo.deviceId + currentTime
    });
    //---
    dispatch(commonSlice.actions.setIsLoading(true));
    try {
      const res = await axios.post(apiFindUserByCifs, dataRequest);
      const { data } = res;
      if (data.Code !== 0) {
        if ((data.Code === 1010) || (data.Code === 6666)) {
          callBack(ConstData.flowCode.unRegistered)
        } else {
          callBack(ConstData.flowCode.retry)
        }
      } else {
        if (data.Cifs === undefined || data.Cifs === null || data.Cifs.length === 0) {
          callBack(ConstData.flowCode.unRegistered)
        } else {
          if (data.Cifs.length > 1) {
            localStorage.setItem(ConstKey.FACE_INFO, JSON.stringify(faceData));
            callBack(ConstData.flowCode.scanFinger)
          } else {
            //save data info
            var customerInfo = {
              type: data.Cifs[0].PriorityCustomer === "1"
                ? ConstData.customerType.VIP
                : ConstData.customerType.NORMAL,
              customerNumber: data.Cifs[0].CustomerNumber,
              name: data.Cifs[0].Name,
              segment: data.Cifs[0].Segment,
              segmentDesc: data.Cifs[0].SegmentDesc,
              priorityCustomer: data.Cifs[0].PriorityCustomer
            }
            localStorage.setItem(ConstKey.CUSTOMER_INFO, JSON.stringify(customerInfo));
            dispatch(homeSlice.actions.setCustomerInfo(customerInfo))
            //notify
            callBack(ConstData.flowCode.registered, data.Cifs[0].PriorityCustomer === "1" ? true : false)
          }
        }
      }
    } catch (err) {
      dispatch(commonSlice.actions.setIs403Error(true))
      rejectWithValue(err);
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }
);

const findUserByFace = createAsyncThunk(
  "detectFace/apiFindUserByFace",
  async ({ faceData, deviceInfo, callBack, callBackError }, { getState, rejectWithValue, dispatch }) => {
    const currentTime = TimeUtils.getCurrentTime()
    //---
    const dataRequest = JSON.stringify({
      KioskID: deviceInfo.deviceId,
      Face: faceData.base64,
      TimeStamp: currentTime,
      SessionId: deviceInfo.deviceId + currentTime
    });
    //---
    dispatch(commonSlice.actions.setIsLoading(true));
    try {
      const res = await axios.post(apiFindUserByFace, dataRequest);
      const { data } = res;

      if (data.Code !== 0) {
        if ((data.Code === 1010) || (data.Code === 6666)) {
          callBack(ConstData.flowCode.unRegistered)
        } else {
          callBack(ConstData.flowCode.retry)
        }
      } else {
        if (data.Cifs === undefined || data.Cifs === null || data.Cifs.length === 0) {
          callBack(ConstData.flowCode.unRegistered)
        } else {
          if (data.Cifs.length > 1) {
            localStorage.setItem(ConstKey.FACE_INFO, JSON.stringify(faceData));
            callBack(ConstData.flowCode.scanFinger)
          } else {
            //save data info
            var customerInfo = {
              type: data.Cifs[0].PriorityCustomer === "1"
                ? ConstData.customerType.VIP
                : ConstData.customerType.NORMAL,
              customerNumber: data.Cifs[0].CustomerNumber,
              name: data.Cifs[0].Name,
              segment: data.Cifs[0].Segment,
              segmentDesc: data.Cifs[0].SegmentDesc,
              priorityCustomer: data.Cifs[0].PriorityCustomer
            }
            localStorage.setItem(ConstKey.CUSTOMER_INFO, JSON.stringify(customerInfo));
            dispatch(homeSlice.actions.setCustomerInfo(customerInfo))
            //notify
            callBack(ConstData.flowCode.registered, data.Cifs[0].PriorityCustomer === "1" ? true : false)
          }
        }
      }
    } catch (err) {
      dispatch(commonSlice.actions.setIs403Error(true))
      rejectWithValue(err);
    } finally {
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }
);

const mockFindUserByFace = createAsyncThunk(
  "detectFace/apiFindUserByFace",
  async ({ mockType, faceData, deviceID, callBack, callBackError }, { dispatch }) => {
    console.log("mockFindUserByFace: start")
    const currentTime = TimeUtils.getCurrentTime()
    console.log("mockFindUserByFace: currentTime - ", currentTime)
    //---
    const dataRequest = JSON.stringify({
      KioskID: deviceID,
      Face: faceData,
      TimeStamp: currentTime,
      SessionId: deviceID + currentTime
    });
    //---
    console.log("dataReq: ", dataRequest)
    console.log(`mockType: ${mockType}`)
    //---
    dispatch(commonSlice.actions.setIsLoading(true));
    //---
    // const res = await axios.post(apiFindUserByFace, dataRequest);
    await TimeUtils.sleep(2000)
    console.log("sleep: end")
    //---
    const data = {
      Code: mockType < 0 ? 1 : mockType === 0 ? 6666 : 0,
      Message: mockType === 0 ? "system err" : "",
      Cifs: mockType === 1 ? null : mockType === 2 ? [{
        CustomerNumber: "0123456789",
        Name: "Nguyễn Văn A",
        PriorityCustomer: "2",
        Segment: "Segment",
        SegmentDesc: "SegmentDesc",
      }] : [{}, {}]
    };
    console.log("dataResp: ", data)

    if (data.Code !== 0) {
      if (data.Code === 6666) {
        callBack(ConstData.flowCode.unRegistered)
      } else {
        callBack(ConstData.flowCode.retry)
      }
    } else {
      if (data.Cifs === null || data.Cifs.length === 0) {
        callBack(ConstData.flowCode.retry)
      } else {
        if (data.Cifs.length > 1) {
          localStorage.setItem(ConstKey.FACE_INFO, JSON.stringify(faceData));
          callBack(ConstData.flowCode.scanFinger)
        } else {
          //save data info
          var customerInfo = {
            type: data.Cifs[0].PriorityCustomer === "1"
              ? ConstData.customerType.VIP
              : ConstData.customerType.NORMAL,
            customerNumber: data.Cifs[0].CustomerNumber,
            name: data.Cifs[0].Name,
            segment: data.Cifs[0].Segment,
            segmentDesc: data.Cifs[0].SegmentDesc,
            priorityCustomer: data.Cifs[0].PriorityCustomer
          }
          localStorage.setItem(ConstKey.CUSTOMER_INFO, JSON.stringify(customerInfo));
          dispatch(homeSlice.actions.setCustomerInfo(customerInfo))
          //notify
          callBack(ConstData.flowCode.registered, data.Cifs[0].PriorityCustomer === "1" ? true : false)
        }
      }
    }

  }
);

export const DetectFaceAction = {
  findUserByFace: findUserByFace,
  mockFindUserByFace: mockFindUserByFace,
  findUserByCifs: findUserByCifs,
}
