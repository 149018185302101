import styled from "styled-components";

const WrapPrivateRoute = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    min-width: 100vw;
    width: 100%;
`
export {
    WrapPrivateRoute
}