import React from "react";

const IcEnd = (props) => {
  return (
    <svg
      {...props}
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 15.7502L21.5 3.50024L37.25 15.7502V35.0002C37.25 35.9285 36.8813 36.8187 36.2249 37.4751C35.5685 38.1315 34.6783 38.5002 33.75 38.5002H9.25C8.32174 38.5002 7.4315 38.1315 6.77513 37.4751C6.11875 36.8187 5.75 35.9285 5.75 35.0002V15.7502Z"
        stroke="white"
        strokeWidth="4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 38.5V21H26.75V38.5"
        stroke="white"
        strokeWidth="4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcEnd;
