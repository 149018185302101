import IcBackSpace from "../../assets/icon/IcBackSpace"

const keyboardNumberArrayLine1 = [
    { title: "1", titleUpper: "1", key: "1",  code: "key1", keyCode: 1 },
    { title: "2", titleUpper: "2", key: "2",  code: "key2", keyCode: 2 },
    { title: "3", titleUpper: "3", key: "3",  code: "key3", keyCode: 3 },
]

const keyboardNumberArrayLine2 = [
    { title: "4", titleUpper: "4", key: "4",  code: "key4", keyCode: 4 },
    { title: "5", titleUpper: "5", key: "5",  code: "key5", keyCode: 5 },
    { title: "6", titleUpper: "6", key: "6",  code: "key6", keyCode: 6 },
]

const keyboardNumberArrayLine3 = [
    { title: "7", titleUpper: "7", key: "7",  code: "key7", keyCode: 7 },
    { title: "8", titleUpper: "8", key: "8",  code: "key8", keyCode: 8 },
    { title: "9", titleUpper: "9", key: "9",  code: "key9", keyCode: 9 },
]

const keyboardNumberArrayLine4 = [
    { title: "0", titleUpper: "0", key: "0",  code: "key0", keyCode: 0 },
    { title: <IcBackSpace />, titleUpper: "Backspace", key: "backspace",  code: "keyBackspace", keyCode: 0 }
]

export const keyboardNumberArray = [
    keyboardNumberArrayLine1,
    keyboardNumberArrayLine2,
    keyboardNumberArrayLine3,
    keyboardNumberArrayLine4
]