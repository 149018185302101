import React from 'react'

const IcDEPINT = (props) => {
    return (
        <svg {...props} width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.94101 23.8678C10.6783 16.4614 16.4614 10.6783 23.8678 8.941C29.2167 7.68633 34.7833 7.68633 40.1322 8.941C47.5387 10.6783 53.3217 16.4614 55.059 23.8678C56.3137 29.2167 56.3137 34.7833 55.059 40.1322C53.3217 47.5386 47.5386 53.3217 40.1322 55.059C34.7833 56.3137 29.2167 56.3137 23.8678 55.059C16.4614 53.3217 10.6783 47.5387 8.94101 40.1322C7.68633 34.7833 7.68633 29.2167 8.94101 23.8678Z" stroke="#00783A" strokeWidth="4.5" strokeLinejoin="round" />
            <path d="M55.059 40.1322C56.3137 34.7833 56.3137 29.2167 55.059 23.8678C53.3217 16.4614 47.5387 10.6783 40.1322 8.941C34.7833 7.68633 29.2167 7.68633 23.8678 8.941C16.4614 10.6783 10.6783 16.4614 8.94101 23.8678C7.68633 29.2167 7.68633 34.7833 8.94101 40.1322C10.6783 47.5387 16.4614 53.3217 23.8678 55.059" stroke="#AB1F24" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.0001 20.9036C28.1699 20.9036 25.0649 24.0085 25.0649 27.8387C25.0649 30.244 26.2895 32.3634 28.1491 33.6073L26.2453 39.4645C25.6627 41.2569 26.9988 43.096 28.8835 43.096H35.1167C37.0014 43.096 38.3375 41.2569 37.7549 39.4645L35.8511 33.6073C37.7107 32.3634 38.9352 30.244 38.9352 27.8387C38.9352 24.0085 35.8303 20.9036 32.0001 20.9036Z" stroke="#00783A" strokeWidth="4.5" strokeLinejoin="round" />
        </svg>
    )
}

export default IcDEPINT