import React from 'react'

const IcCheck = (props) => {
  return (
    <svg {...props} width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
    <path id="Vector" d="M35.5001 11L16.2501 30.25L7.50012 21.5" stroke="white" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>

  )
}

export default IcCheck