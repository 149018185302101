import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ConstData, ConstKey } from "../../assets/constants/constants";
import homeSlice from "./home.slice";
import { TimeUtils } from "../../utils/time";
import commonSlice from "../common/common.slice";

const apiCheckTicket = `${ConstData.hostLocal}/kiosk/check-can-get-ticket`;
const apiGetTicket = `${ConstData.hostLocal}/kiosk/get-ticket`;

const onCheckTicketError = (customerInfo, dataErr, dispatch) => {
  if (dataErr.Code === 31) {
    const issueTicketResult = {
      isErr: true,
      messageErr: "Giao dịch bạn chọn không thể lấy vé vào quầy vào lúc này. Vui lòng chọn giao dịch khác"
    }
    if (customerInfo.BiometricFinger === "1" &&
      customerInfo.BiometricActive === "1" &&
      customerInfo.BiometricFace === "1") {
      issueTicketResult.messageErr = "Lấy vé thất bại, vui lòng thử lại"
    }
    dispatch(commonSlice.actions.setIssueTicketResult(issueTicketResult))
  } else {
    dispatch(commonSlice.actions.setIsSystemError(true))
  }
}

const onCheckTicketSuccess = (
  { deviceInfo, customerInfo, serviceList },
  { getState, dispatch, rejectWithValue }
) => {
  getTicketAction({
    deviceInfo: deviceInfo,
    serviceList: serviceList,
    customerInfo: customerInfo,
    onSuccess: (ticketInfo) => {
      dispatch(homeSlice.actions.setTicketInfo(ticketInfo))
      dispatch(homeSlice.actions.setIsGetTicketSuccess(true))
    },
    onError: (dataErr) => {
      const issueTicketResult = {
        isErr: true,
        messageErr: "Giao dịch bạn chọn không thể lấy vé vào quầy vào lúc này. Vui lòng chọn giao dịch khác"
      }
      if (dataErr.Code !== 31) {
        issueTicketResult.messageErr = "Lấy vé thất bại, vui lòng thử lại"

      }
      dispatch(commonSlice.actions.setIssueTicketResult(issueTicketResult))
    }
  }, {
    getState: getState,
    dispatch: dispatch,
    rejectWithValue: rejectWithValue
  });
}

const checkTicketAction = async (
  { customerInfo, deviceInfo, serviceList, onSuccess, onError },
  { getState, dispatch, rejectWithValue }
) => {
  const currentTime = TimeUtils.getCurrentTime()
  const dataRequest = JSON.stringify({
    KioskID: deviceInfo.deviceId,
    ListService: [serviceList.id],
    TimeStamp: currentTime,
    SessionId: deviceInfo.deviceId + currentTime
  });

  dispatch(commonSlice.actions.setIsLoading(true));
  try {
    const res = await axios.post(apiCheckTicket, dataRequest);
    const { data } = res;

    if (data.Code === 0) {
      onSuccess({
        deviceInfo: deviceInfo,
        customerInfo: customerInfo,
        serviceList: serviceList
      },
        {
          getState: getState,
          dispatch: dispatch,
          rejectWithValue: rejectWithValue
        })
      return Promise.resolve(data);
    } else {
      var dataErr = {
        code: data.Code,
        message: data.Message
      }
      if (data.Code === 2) {
        dataErr.message = "Kiosk does not exist"
      }
      //---
      dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
      onError(customerInfo, dataErr, dispatch)
      // return Promise.reject();
    }
  } catch (error) {
    dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
    rejectWithValue(error);
    // return Promise.reject();
  } finally {
    console.log("finally");
    dispatch(commonSlice.actions.setIsLoading(false));
  }
};

const getTicketAction = async (
  { deviceInfo, customerInfo, serviceList, onSuccess, onError },
  { getState, dispatch, rejectWithValue }
) => {
  const currentTime = TimeUtils.getCurrentTime()
  const serviceDetails = [{
    ServiceType: serviceList.type,
    Edited: false,
    serializedName: null
  }]
  // serviceList.map((item) => {
  //   serviceDetails.push({
  //     ServiceType: item.type,
  //     Edited: false,
  //     serializedName: null
  //   })
  //   return item
  // })
  const dataRequest = JSON.stringify({
    KioskID: deviceInfo.deviceId,
    OfficeID: deviceInfo.officeId,
    CustomerInfos: {
      Name: customerInfo.name,
      CustomerNumber: customerInfo.customerNumber,
      Segment: customerInfo.segment,
      SegmentDesc: customerInfo.segmentDesc,
      PriorityCustomer: customerInfo.priorityCustomer
    },
    ListService: [serviceList.id],
    ServiceDetails: serviceDetails,
    TimeStamp: currentTime,
    SessionId: deviceInfo.deviceId + currentTime
  });

  dispatch(commonSlice.actions.setIsLoading(true));
  try {
    const res = await axios.post(apiGetTicket, dataRequest);
    const { data } = res;

    if (data.Code === 0) {
      const ticketInfo = {
        ticketNumber: data.TicketNumber,
        listService: serviceList,
      }
      localStorage.setItem(ConstKey.TICKET_INFO, JSON.stringify(ticketInfo));
      dispatch(homeSlice.actions.setTicketInfo(ticketInfo))

      onSuccess(ticketInfo)
      return Promise.resolve(ticketInfo);
    } else {
      var dataResult = {
        code: data.Code,
        message: data.Message
      }
      console.log("getTicketAction => error: ", data)
      //---
      onError(dataResult)
      dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
      // return Promise.reject();
    }
  } catch (error) {
    dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
    rejectWithValue(error);
    // console.log("error");
    // return Promise.reject();
  } finally {
    console.log("finally");
    dispatch(commonSlice.actions.setIsLoading(false));
  }
};

const onGetTicket = createAsyncThunk(
  "home/getTicket",
  async (
    { deviceInfo, customerInfo, serviceList },
    { getState, dispatch, rejectWithValue }
  ) => {
    checkTicketAction(
      {
        deviceInfo: deviceInfo,
        customerInfo: customerInfo,
        serviceList: serviceList,
        onSuccess: onCheckTicketSuccess,
        onError: onCheckTicketError
      },
      {
        getState: getState,
        dispatch: dispatch,
        rejectWithValue: rejectWithValue
      }
    )
  }
);

export const HomeAction = {
  onGetTicket: onGetTicket
}