import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ConstData, ConstKey } from "../../assets/constants/constants";
import commonSlice from "./common.slice";
import { TimeUtils } from "../../utils/time";

// const apiConnectDevice = `${ConstData.host}/kiosk/connect`;
const apiConnectDevice = `${ConstData.hostLocal}/kiosk/connect`;
const apiPrintReceipt = `http://${ConstData.ipAddressOfDevice}:8884/print`

const getPrintReceiptAction = createAsyncThunk(
  "common/print",
  async (
    { file, onSuccess, onError },
    { rejectWithValue }
  ) => {
    // try {
    //   const res = await axios.post(apiPrintReceipt, file, {
    //     headers: { 
    //       'Access-Control-Allow-Origin' : '*',
    //     },
    //   });
    //   console.log("getPrintReceiptAction resp: ", res)
    //   const { data } = res;
    //   if (data.Code === 0) {
    //     onSuccess && onSuccess();
    //   } else {
    //     onError && onError();
    //   }
    // } catch (err) {
    //   // console.log("getPrintReceiptAction respErr.headers: ", err.headers)
    //   console.log(err.response?err.response.status:"");
    //   console.log("getPrintReceiptAction respErr.code: ", err.code)
    //   console.log("getPrintReceiptAction respErr.config: ", err.config.headers)
    //   console.log("getPrintReceiptAction respErr.message: ", err.message)
    //   rejectWithValue(err);
    // }

    const myRequest = new Request(apiPrintReceipt, {
      method: 'POST',
      mode: 'no-cors',
      body: file
    });
    fetch(myRequest).then(function (response) {
      return response;
    }).then(function (response) {

      console.log("response: ", response)
      onSuccess();
      // const { body } = response;
      // if (body===null || body.Code === 0) {
      //   onSuccess && onSuccess();
      // } else {
      //   onError && onError();
      // }
    }).catch(function (e) {
      console.log("error: ", e);
      // rejectWithValue(e);
      onError();
    });
  }
);
const connectDeviceAction = createAsyncThunk(
  "common/connectDevice",
  async (
    { id, pwd },
    { getState, dispatch, rejectWithValue }
  ) => {
    const dataRequest = JSON.stringify({
      ConnectID: id,
      Pwd: pwd,
      TimeStamp: Math.round((new Date()).getTime() / 1000)
    });
    dispatch(commonSlice.actions.setIsLoading(true));
    try {
      const res = await axios.post(apiConnectDevice, dataRequest);
      const { data } = res;
      if (data.Code === 0) {
        var deviceInfo = {
          deviceId: data.DeviceID,
          deviceName: data.DeviceName,
          officeId: data.OfficeID,
          officeName: data.OfficeName
        }
        localStorage.setItem(ConstKey.DEVICE_INFO, JSON.stringify(deviceInfo));
        dispatch(commonSlice.actions.connectDeviceCompleted(deviceInfo))
        //---
        const dataPayload = {
          deviceInfo: deviceInfo,
        };
        return Promise.resolve(dataPayload);
      } else {
        // console.log("error");
        dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
        // return Promise.reject();
      }
    } catch (error) {
      dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
      rejectWithValue(error);
    } finally {
      console.log("finally");
      dispatch(commonSlice.actions.setIsLoading(false));
    }
  }
);

const mockConnectDeviceAction = createAsyncThunk(
  "connectDevice",
  async (
    { isMockErr, connectDeviceCode, pinCode, onError },
    { getState, dispatch, rejectWithValue }
  ) => {
    const dataRequest = JSON.stringify({
      ConnectID: connectDeviceCode,
      Pwd: pinCode,
      TimeStamp: Math.round((new Date()).getTime() / 1000)
    });
    console.log("dataReq: ", dataRequest)
    dispatch(commonSlice.actions.setIsLoading(true));
    await TimeUtils.sleep(2000);
    var data = {
      Code: isMockErr ? 150 : 0,
      Message: isMockErr ? "kiosk not exist" : "",
      DeviceID: isMockErr ? "" : "KioskTest001",
      DeviceName: isMockErr ? "" : "Kiosk Test 001",
      OfficeID: isMockErr ? "" : "OfficeTest001",
      OfficeName: isMockErr ? "" : "PGD Thành Công"
    }
    //---
    if (data.Code === 0) {
      var deviceInfo = {
        deviceId: data.DeviceID,
        deviceName: data.DeviceName,
        officeId: data.OfficeID,
        officeName: data.OfficeName
      }
      localStorage.setItem(ConstKey.DEVICE_INFO, JSON.stringify(deviceInfo));
      dispatch(commonSlice.actions.connectDeviceCompleted(deviceInfo))
      //---
      const dataPayload = {
        deviceInfo: deviceInfo,
      };
      return Promise.resolve(dataPayload);
    } else {
      // console.log("error");
      dispatch(commonSlice.actions.setIsLoading(false))
      onError();
      // return Promise.reject();
    }
  }
);

export const CommonAction = {
  getPrintReceiptAction: getPrintReceiptAction,
  connectDeviceAction: connectDeviceAction,
  mockConnectDeviceAction: mockConnectDeviceAction
}