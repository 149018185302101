import { ConstKey } from "../../assets/constants/constants";

const ticketInfoSelector = (state) => {
    var ticketInfo = state.home.ticketInfo;
    if (!ticketInfo){
        ticketInfo = JSON.stringify(localStorage.getItem(ConstKey.TICKET_INFO)) || null
        if (ticketInfo === "null"){
            ticketInfo = null
        }
    }
    return ticketInfo
}

const customerInfoSelector = (state) => {
    var customerInfo = state.home.customerInfo;
    if (!customerInfo){
        customerInfo = JSON.stringify(localStorage.getItem(ConstKey.CUSTOMER_INFO)) || null
        if (customerInfo === "null"){
            customerInfo = null
        }
    }
    return customerInfo
}

const isGetTicketSuccessSelector = (state) => {
    return state.home.isGetTicketSuccess
}

export const homeSelector = {
    ticketInfo: ticketInfoSelector,
    customerInfo: customerInfoSelector,
    isGetTicketSuccess: isGetTicketSuccessSelector
}