import styled from "styled-components";
import PageBg from "../../components/PageBg/index.styled";

const Wrap = styled(PageBg)`
    overflow: hidden;
`

const WrapRatingThanksPage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .content{
        width: 960px;
        height: 1506px;
        margin: 121px 60px;
        background: #ffffff;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text-thanks{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            p{
                font-family: 'SVN-Gilroy';
                font-size: 45px;
                font-style: normal;
                font-weight: 600;
                line-height: 54px;
                color: #010101;
            }
        }
    }

`
export {
    Wrap, WrapRatingThanksPage
}