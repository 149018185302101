
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

//css
import { Wrap, WrapRatingPage } from "./index.styles"
//img
import IcRate1 from "../../assets/icon/IcRate1"
import IcRate2 from "../../assets/icon/IcRate2"
import IcRate3 from "../../assets/icon/IcRate3"
import IcRate4 from "../../assets/icon/IcRate4"
import IcRate5 from "../../assets/icon/IcRate5"
//component
import Header from '../../components/Header';
import { damping, variants } from "../../utils/transition_page";
import { motion } from "framer-motion";
import { commonSelector } from '../../redux/common/common.selectors';
import { ConstData } from "../../assets/constants/constants"

const dataIconRate = [
  {
    icon: <IcRate1 />,
    key: 5,
    text: "Tuyệt vời"
  },
  {
    icon: <IcRate2 />,
    key: 4,
    text: "Rất tốt"
  },
  {
    icon: <IcRate3 />,
    key: 3,
    text: "Dùng được"
  },
  {
    icon: <IcRate4 />,
    key: 2,
    text: "Khắc phục thêm"
  },
  {
    icon: <IcRate5 />,
    key: 1,
    text: "Khó dùng!"
  }
]

const RatingPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  //---
  const deviceInfo = useSelector(commonSelector.deviceInfo)

  const [isSelected, setIsSelected] = useState("")

  const handleRate = (key) => {
    setIsSelected(key)
    let timeout = setTimeout(() => {
      navigate(ConstData.routePath.ThanksPage);
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
    // const callBackSuccess = () => {
    //   navigate(ConstData.routePath.ThanksPage);
    // }
    // dispatch(getRatingAction({
    //   transactionID: transactionId,
    //   rating: key,
    //   callBackSuccess
    // }))
  }  

  return (
    <Wrap>
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: damping },
          opacity: { duration: 0.2 },
        }}
      >
        <WrapRatingPage>
          <Header nameOffice={deviceInfo.officeName} />
          <div className="container">
            <div className="rate-question">Sau khi sử dụng dịch vụ bạn đánh giá như thế nào?</div>
            <div className="rate-icon">
              {dataIconRate.map((item, index) => (
                <div className={`rate-icon-item ${item.key === isSelected ? "selected" : ""}`} key={index} onClick={() => handleRate(item.key)} >
                  <div className="icon">{item.icon}</div>
                  <p className="text">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </WrapRatingPage>
      </motion.div>
    </Wrap>
  )
}
export default RatingPage