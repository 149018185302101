/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//css
import { WrapMainBackground } from './index.styles';
//component
import AdvertisingPage from '../AdvertisingPage';
import ConnectDevice from '../../components/ConnectDevice';
//hook
import { commonSelector } from '../../redux/common/common.selectors';
import { ConstKey } from '../../assets/constants/constants';
import commonSlice from '../../redux/common/common.slice';
import homeSlice from '../../redux/home/home.slice';
import detectFaceSlice from '../../redux/detectFace/detectFace.slice';
import scanFingerSlice from '../../redux/scanFinger/scanFinger.slice';

const MainPage = () => {

  const dispatch = useDispatch()
  //---
  const deviceInfo = useSelector(state => state.common.deviceInfo)
  //---
  const [isConnectDevice, setIsConnectDevice] = useState(false)
  //---
  useEffect(() => {
    if (!deviceInfo) {
      setIsConnectDevice(true)
    } else {
      setIsConnectDevice(false)
    }
  }, [deviceInfo])
  
  useEffect(() => {
    window.onbeforeunload = function () {
      console.log("detectFace onbeforeunload")
      localStorage.removeItem(ConstKey.FACE_INFO)
      localStorage.removeItem(ConstKey.CUSTOMER_INFO)
      localStorage.removeItem(ConstKey.TICKET_INFO)
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    dispatch(commonSlice.actions.resetCommon())
    dispatch(homeSlice.actions.resetHome())
    dispatch(detectFaceSlice.actions.setFace(null))
    dispatch(scanFingerSlice.actions.resetScanFinger())
    localStorage.removeItem(ConstKey.FACE_INFO)
    localStorage.removeItem(ConstKey.CUSTOMER_INFO)
    localStorage.removeItem(ConstKey.TICKET_INFO)
  }, [])

  return (
    <WrapMainBackground>
      {
        isConnectDevice
          ? <ConnectDevice />
          : <AdvertisingPage />
      }
    </WrapMainBackground>
  );
}

export default MainPage;
