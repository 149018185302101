/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//css
import { Wrap, WrapDetectFace } from "./deteactFace.styled";
//img
import BannerBottomFinger from "../../assets/img/banner_bottom_finger.png";
import { ConstData, ConstKey } from "../../assets/constants/constants";
import LottieAnimation from "../LottieAnimation";
import lottieFaceAnimation from "../../assets/lottie/face_animation.json";
import IcRetryWhite from "../../assets/icon/IcRetryWhite";
import IcSmile from "../../assets/icon/IcSmile";
//component
import Header from "../../components/Header";
import Portal from "../Portal";
import PopupEror from "../PopupErr";
//hook
import useConnectWsCamera from "../../hooks/useConnectWsCamera";
import { detectFaceSelector } from "../../redux/detectFace/detectFace.selectors";
import { DetectFaceAction } from '../../redux/detectFace/detectFace.action'
import commonSlice from "../../redux/common/common.slice";
import { commonSelector } from "../../redux/common/common.selectors";
import detectFaceSlice from "../../redux/detectFace/detectFace.slice";
import useConnectScannerMK from "../../hooks/useConnectScannerMK";

const DetectFaceControl = ({ onShowHomePage, onShowIssueTicketPage, onShowScanFingerPage }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const numberOfRetry = useRef(0)
    //--
    const { wsRef, wsConnected, cameraStreamUrl, count, errDevice, message, isGetFaceSuccess, setIsGetFaceSuccess, resetGetFace } = useConnectWsCamera();
    //---
    const {
        wsRef: wsRefMK,
        connectWs: connectWsMK,
        wsConnected: wsConnectedMK,
        message: messageMK,
        setMessage: setMessageMK,
        isProcessing: isProcessingMK,
        setIsProcessing: setIsProcessingMK,
        isVerifyFailed: isVerifyFailedMK,
        setIsVerifyFailed: setIsVerifyFailedMK,
    } = useConnectScannerMK();
    //---
    const typeConnectMK = useSelector(state => state.common.typeConnectMK)
    const idCardNo = useSelector(state => state.common.idCardNo)
    const isOcrSucceeded = useSelector(state => state.common.isOcrSucceeded)
    //---
    const deviceInfo = useSelector(commonSelector.deviceInfo)
    //---
    const faceData = useSelector(detectFaceSelector.faceData);
    //---
    const [isShowPopupErrMK, setIsShowPopupErrMK] = useState(false)
    const [isShowErrMorethan3, setIsShowErrMorethan3] = useState(false)

    //---
    if (!isGetFaceSuccess && faceData !== null) {
        localStorage.removeItem(ConstKey.FACE_INFO)
        localStorage.removeItem(ConstKey.CUSTOMER_INFO)
        localStorage.removeItem(ConstKey.TICKET_INFO)
        //---
        detectFaceSlice.actions.setFace(null)
    } else {
        // console.log("clear new face data: ", faceData);
    }

    useEffect(() => {
        if (messageMK) {
            setIsShowPopupErrMK(true)
        }
    }, [messageMK])

    useEffect(() => {
        if (!isGetFaceSuccess) return
        let timer = setTimeout(() => {
            setIsShowPopupErrMK(false)
            dispatch(commonSlice.actions.setIsLoading(true));
        }, 300);
        return () => {
            clearTimeout(timer)
        }
    }, [isGetFaceSuccess])

    useEffect(() => {
        if (!isProcessingMK) return
        let timer = setTimeout(() => {
            setIsShowPopupErrMK(false)
            dispatch(commonSlice.actions.setIsLoading(true));
        }, 300);
        return () => {
            clearTimeout(timer)
        }
    }, [isProcessingMK])

    useEffect(() => {
        if (!isOcrSucceeded && !isVerifyFailedMK) return
        let timer = setTimeout(() => {
            dispatch(commonSlice.actions.setIsLoading(false));
        }, 300);
        return () => {
            clearTimeout(timer)
        }
    }, [isOcrSucceeded, isVerifyFailedMK])

    useEffect(() => {
        if (!typeConnectMK) return
        wsConnectedMK && wsRefMK.current.send(typeConnectMK)
    }, [typeConnectMK, wsConnectedMK, wsRefMK])

    useEffect(() => {
        if (!faceData) return
        wsConnectedMK && wsRefMK.current.send(faceData.base64)
    }, [faceData, wsConnectedMK, wsRefMK])

    useEffect(() => {
        if (!isGetFaceSuccess || !faceData) {
            return
        }
        const callBack = (flowCode, isVIPCustomer) => {
            dispatch(commonSlice.actions.setIsLoading(false))
            if (flowCode === ConstData.flowCode.registered) {
                var flow = "Khách hàng đã đăng ký STH"
                if (isVIPCustomer) {
                    flow = "Khách hàng VIP"
                    onShowIssueTicketPage()
                } else {
                    onShowHomePage()
                }
            }
            else if (flowCode === ConstData.flowCode.unRegistered) {
                const flow = "Khách hàng chưa đăng ký STH / Khách vãng lai"
                onShowHomePage()
            } else if (flowCode === ConstData.flowCode.scanFinger) {
                onShowScanFingerPage()
            } else if (flowCode === ConstData.flowCode.retry) {
                numberOfRetry.current = numberOfRetry.current + 1
                if (numberOfRetry.current < ConstData.numberOfRetry) {
                    // console.log(`get face: retry`)
                    resetGetFace();
                } else {
                    // console.log(`=> go to HomePage - Khách hàng chưa đăng ký STH / Khách vãng lai`)
                    onShowHomePage()
                }
            } else {
                onShowHomePage()
            }
        }
        const calBackErr = () => {
            dispatch(commonSlice.actions.setIsDisconnectNetworkError(true))
        }
        //---
        if (typeConnectMK) {
            // Call Api flow MK
            if (!idCardNo) return
            dispatch(DetectFaceAction.findUserByCifs({
                faceData: faceData,
                deviceInfo: deviceInfo,
                idCardNo: idCardNo,
                callBack: callBack,
                callBackError: calBackErr
            }))
        } else if (!typeConnectMK) {
            dispatch(DetectFaceAction.findUserByFace({
                faceData: faceData,
                deviceInfo: deviceInfo,
                callBack: callBack,
                callBackError: calBackErr
            }))
        }
    }, [faceData, isGetFaceSuccess, idCardNo])

    const handleRetry = () => {
        setIsGetFaceSuccess(false)
        setIsShowPopupErrMK(false)
        setIsVerifyFailedMK(false)
        setIsProcessingMK(false)
        dispatch(detectFaceSlice.actions.setFace(null))
        numberOfRetry.current = numberOfRetry.current + 1
        if (wsConnected && wsConnectedMK) {
            wsRef.current.send("START_CAMERA")
            wsRefMK.current.send(typeConnectMK)            
        }
    }

    const handleUnderStand = () => {
        setIsShowErrMorethan3(false)
        setIsGetFaceSuccess(false)
        setIsShowPopupErrMK(false)
        setIsVerifyFailedMK(false)
        setIsProcessingMK(false)
        dispatch(detectFaceSlice.actions.setFace(null))
        navigate(ConstData.routePath.MainPage);
    }

    useEffect(() => {
        if (numberOfRetry.current === 2 && isVerifyFailedMK) {
            setIsShowErrMorethan3(true)
        }
    }, [isVerifyFailedMK])

    return (
        <Wrap>
            <WrapDetectFace>
                <div className="face-content">
                    <div className="header-content">
                        <Header nameOffice={deviceInfo.officeName} />
                    </div>
                    <div className="block-face">
                        <div id="liveView" className="block-avatar">
                            {!isGetFaceSuccess && cameraStreamUrl && <img
                                src={cameraStreamUrl}
                                alt="cameraStream"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "relative",
                                    zIndex: "21",
                                }}
                            />}
                            {isGetFaceSuccess && faceData && <img
                                src={faceData.url}
                                alt="faceStream"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "relative",
                                    zIndex: "21",
                                }}
                            />}
                        </div>
                        <div className="block-animation">
                            <LottieAnimation
                                isLoop={true}
                                data={lottieFaceAnimation}
                                className="lottie"
                            />
                            {/* {count && <div className="count">{count}</div>} */}
                        </div>
                        <div className="block-text">
                            <IcSmile className='icon' />
                            <div className="text">
                                {message || "Vui lòng nhìn vào camera"}
                            </div>
                        </div>

                    </div>
                    <img className="bottom-content" src={BannerBottomFinger} alt="BannerBottomFinger" />
                </div>
                {isVerifyFailedMK && typeConnectMK && (numberOfRetry.current !== 2) && (
                    <Portal>
                        <PopupEror
                            title={"Xác thực thất bại."}
                            desc={"Quý khách vui lòng thử lại."}
                            iconBtn={<IcRetryWhite />}
                            textBtn={"Thử lại"}
                            onSubmit={handleRetry}
                        />
                    </Portal>
                )}
                {isShowPopupErrMK && typeConnectMK && (
                    <Portal>
                        <PopupEror
                            title={messageMK}
                            iconBtn={<IcRetryWhite />}
                            textBtn={"Thử lại"}
                            onSubmit={handleRetry}
                        />
                    </Portal>
                )}
                {isShowErrMorethan3 &&
                    <Portal>
                        <PopupEror
                            title={"Xác thực thất bại."}
                            desc={"Quý khách vui lòng đến chi nhánh gần nhất để được hỗ trợ."}
                            iconBtn={<IcRetryWhite />}
                            textBtn={"Đã hiểu"}
                            onSubmit={handleUnderStand}
                        />
                    </Portal>
                }
            </WrapDetectFace>
        </Wrap>
    );
}

export default DetectFaceControl;