/* eslint-disable react-hooks/exhaustive-deps */

import { useSelector } from "react-redux";
//css
import { Wrap } from "./index.styled";
//component
import IssueTicketInfo from "../../components/IssueTicketInfo";
//hook
import { homeSelector } from "../../redux/home/home.selectors";
import { commonSelector } from "../../redux/common/common.selectors";

const IssueTicketPage = () => {
    //---
    const customerInfo = useSelector(homeSelector.customerInfo)
    const ticketInfo = useSelector(homeSelector.ticketInfo)
    //---
    const deviceInfo = useSelector(commonSelector.deviceInfo)
    //----    
    return (
        <Wrap>
            <IssueTicketInfo
                customerInfo={customerInfo}
                ticketInfo={ticketInfo}
                deviceInfo={deviceInfo}
            />
        </Wrap>
    );
}

export default IssueTicketPage;