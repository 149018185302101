import { createSlice } from "@reduxjs/toolkit";
import { ScanFingerAction } from "./scanFinger.action";
import { ConstData } from "../../assets/constants/constants";

const initialState = {
    scanFpStatus: ConstData.scanFingerStatus.noFinger,
    fingerData: null
}

const findUserWithFaceAndFinger = (state, action) => {
    // return initialState
    console.log("findUserWithFaceAndFinger action")
}

const scanFingerSlice = createSlice({
    name: 'scanFinger',
    initialState,
    reducers: {
        resetScanFinger: () => initialState,
        setFingerData: (state, action) => {
            state.fingerData = action.payload
        },
        setScanFpStatus: (state, action) => {
            state.scanFpStatus = action.payload
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(ScanFingerAction.mockFindUserWithFaceAndFinger.fulfilled, findUserWithFaceAndFinger)
            // .addCase(ScanFingerAction.findUserWithFaceAndFinger.fulfilled,  (state, action) => { })
    },
});

export default scanFingerSlice