import React from 'react'

const IcThanks = (props) => {
    return (
        <svg {...props} width={160} height={160} viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10167_21295)">
                <circle cx={80} cy={80} r={80} fill="white" />
                <path d="M80 0C35.8892 0 0 35.8862 0 80C0 124.114 35.8892 160 80 160C124.111 160 160 124.114 160 80C160 35.8862 124.111 0 80 0ZM124.6 53.32L75.3692 108.705C74.1569 110.068 72.4677 110.769 70.7662 110.769C69.4154 110.769 68.0585 110.326 66.9231 109.422L36.1538 84.8062C33.5015 82.6862 33.0708 78.8123 35.1938 76.1569C37.3138 73.5015 41.1908 73.0708 43.8431 75.1938L70.0492 96.1569L115.4 45.1385C117.652 42.5969 121.548 42.3692 124.086 44.6277C126.628 46.8892 126.858 50.7785 124.6 53.32Z" fill="url(#paint0_linear_10167_21295)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_10167_21295" x1={80} y1={0} x2={80} y2={160} gradientUnits="userSpaceOnUse">
                    <stop stopColor="#41C728" />
                    <stop offset={1} stopColor="#1FC000" />
                </linearGradient>
                <clipPath id="clip0_10167_21295">
                    <rect width={160} height={160} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcThanks