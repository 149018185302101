import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
//css
import {WrapPopupLinkKiosk} from './index.styles'
import OverlayFullScreen from '../../controls/OverlayFullScreen'
//img
import logoLinkKiosk from '../../assets/img/logo_link_kiosk.png'
import IcCheck from '../../assets/icon/IcCheck'
import IcStart from '../../assets/icon/IcStart'
import IcStartActive from '../../assets/icon/IcStartActive'
import IcStartError from '../../assets/icon/IcStartError'
import IcCloseGray from "../../assets/icon/IcCloseGray"
//component
import InputText from '../../controls/InputText'
//hook
import { CommonAction } from '../../redux/common/common.action'

const iconInputStar = {
    icon: IcStart,
    iconActive: IcStartActive,
    iconError: IcStartError,
}

export default function ConnectDevice({hidePopup}) {
    const dispatch = useDispatch()
    const [linkDeviceCode, setLinkDeviceCode] = useState("")
    const [pinCode, setPinCode] = useState("")
    const [isCallApiErr, setIsCallApiErr] = useState(false)

    const handleGetLinkDeviceCode = (value) => {
        setLinkDeviceCode(value)
    }

    const handleGetPinCode = (value) => {
        setPinCode(value)
    }

    const handleSubmit = () => {
        setIsCallApiErr(false)
        dispatch(CommonAction.connectDeviceAction({
            id: linkDeviceCode,
            pwd: pinCode,
            onError: () => {
                setIsCallApiErr(true)
            }
        }))
    }
    // const handleSubmit = () => {
    //     setIsCallApiErr(false)
    //     dispatch(CommonAction.mockConnectDeviceAction({
    //         isMockErr: false,
    //         connectDeviceCode: linkDeviceCode,
    //         pinCode: pinCode,
    //         onError: () => {
    //             setIsCallApiErr(true)
    //         }
    //     }))
    // }

  return (
    <OverlayFullScreen>
            <div className='wrap-inner-screen'>
                <WrapPopupLinkKiosk>
                    <div className='icon-close' onClick={hidePopup}>
                        <IcCloseGray />
                    </div>
                    <div className='header'>
                        <img src={logoLinkKiosk} alt="logo" />
                        <div className="title">
                          Thiết bị chưa được kết nối vào hệ thống
                        </div>
                        <div className='desc'>Bạn vui lòng nhập mã ID và mã PIN của thiết bị để cấu hình vào hệ thống</div>
                    </div>
                    <InputText
                        title={"Nhập mã ID"}
                        isRequired={false}
                        iconInput={iconInputStar}
                        value={linkDeviceCode}
                        onChange={handleGetLinkDeviceCode}
                        className="input-link"
                    />
                    <InputText
                        title={"Mã PIN thiết bị"}
                        isRequired={false}
                        iconInput={iconInputStar}
                        value={pinCode}
                        onChange={handleGetPinCode}
                    />
                    {
                        isCallApiErr && 
                        <>
                            <div className='err err-top'>Vui lòng nhập đầy đủ thông tin</div>
                            <div className='err'> Mã không đúng hoặc không tồn tại</div>
                        </>
                        
                    }
                    <div className='btn-container' onClick={handleSubmit}>
                      <IcCheck />
                      Cấu hình
                    </div>
                </WrapPopupLinkKiosk>
            </div>
        </OverlayFullScreen>
  )
}
