import { useNavigate } from "react-router-dom";
//css
import { WrapBackground } from "./index.styles";
//component
import DetectFaceControl from "../../controls/DetectFaceControl";
//hook
import { ConstData } from "../../assets/constants/constants";
import { motion } from "framer-motion";
import { damping, variants } from "../../utils/transition_page";

const DetectFacePage = () => {
  const navigate = useNavigate();
  //===

  const onShowHomePage = () => {
    navigate(ConstData.routePath.HomePage);
  };

  const onShowScanFingerPage = () => {
    navigate(ConstData.routePath.ScanFingerPage);
  };

  const onShowIssueTicketPage = () => {
    navigate(ConstData.routePath.IssueTicketPage);
  };

  return (
    <WrapBackground>
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: damping },
          opacity: { duration: 0.2 },
        }}
      >
        <DetectFaceControl
          onShowHomePage={onShowHomePage}
          onShowIssueTicketPage={onShowIssueTicketPage}
          onShowScanFingerPage={onShowScanFingerPage}
        />
      </motion.div>
    </WrapBackground>
  );
};

export default DetectFacePage;
