import React from "react";
import { useSelector } from "react-redux";
import { homeSelector } from "../../redux/home/home.selectors";
//css
import { Wrap, WrapHome } from "./index.styled";
//img
import IconExit from "../../assets/icon/exit";
//component
import Header from "../Header";
//hook
import { ConstData, ConstKey } from "../../assets/constants/constants";

const SelectTransaction = ({
  listItem,
  nameOffice,
  onExit,
  onItemSelected,
}) => {
 
  const customerInfo = useSelector(homeSelector.customerInfo)
  const handleExit = () => {
    console.log('handleExit');
    if (onExit) {
      onExit();
    }
  }
  const handleClickItem = (item) => {
    console.log('handleClickItem');
    if (onItemSelected) {
      onItemSelected(item)
    }

  }
  return (
    <Wrap>
      <WrapHome>
        <Header nameOffice={nameOffice} />
        <div className="container">
          <div className="header">
            <div className="header-title">Xin chào quý khách</div>
            {
              customerInfo?.name && <span className="header-name">{customerInfo.name}</span>
            }
            <div className="header-desp">Mời quý khách lựa chọn giao dịch</div>
          </div>
          <div className="content">
            {listItem.map((item, index) => (
              <div className="content-item" key={index} onClick={(() => {
                handleClickItem(item)
              })}>
                {item.position === ConstData.position.Left && (
                  <div className="content-item-left general">
                    <div>{item.icon}</div>
                    <div>{item.value}</div>
                  </div>
                )}
                {item.position === ConstData.position.Right && (
                  <div className="content-item-right general">
                    <div>{item.value}</div>
                    <div>{item.icon}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="btn" onClick={handleExit}>
            <div><IconExit /></div>
            <span>Thoát</span>
          </div>
        </div>
      </WrapHome>
    </Wrap>
  );
};

export default SelectTransaction;
