import React from 'react'

const IcRetryWhite = (props) => {
    return (
        <svg {...props} width={37} height={36} viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6860_14703)">
                <path d="M2 30V21H11" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M35 6V15H26" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.765 13.4965C6.52575 11.3466 7.8187 9.42456 9.5232 7.90958C11.2277 6.3946 13.2882 5.3361 15.5124 4.83284C17.7367 4.32958 20.0521 4.39796 22.2428 5.03161C24.4334 5.66526 26.4279 6.84352 28.04 8.45645L35 14.9965M2 20.9965L8.96 27.5365C10.5721 29.1494 12.5666 30.3276 14.7572 30.9613C16.9479 31.5949 19.2633 31.6633 21.4876 31.1601C23.7118 30.6568 25.7723 29.5983 27.4768 28.0833C29.1813 26.5683 30.4742 24.6463 31.235 22.4965" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_6860_14703">
                    <rect width={36} height={36} fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcRetryWhite