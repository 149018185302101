import IcBackSpace from "../../assets/icon/IcBackSpace";
import IcEnter from "../../assets/icon/IcEnter";
import IcShift from "../../assets/icon/IcShift";
import ic_wee_space from '../../assets/icon/ic_wee_space.png'

const keyboardArrayLine1 = [
    { title: "1", titleUpper: "1", key: "1",  code: "key1", keyCode: 1 },
    { title: "2", titleUpper: "2", key: "2",  code: "key2", keyCode: 2 },
    { title: "3", titleUpper: "3", key: "3",  code: "key3", keyCode: 3 },
    { title: "4", titleUpper: "4", key: "4",  code: "key4", keyCode: 4 },
    { title: "5", titleUpper: "5", key: "5",  code: "key5", keyCode: 5 },
    { title: "6", titleUpper: "6", key: "6",  code: "key6", keyCode: 6 },
    { title: "7", titleUpper: "7", key: "7",  code: "key7", keyCode: 7 },
    { title: "8", titleUpper: "8", key: "8",  code: "key8", keyCode: 8 },
    { title: "9", titleUpper: "9", key: "9",  code: "key9", keyCode: 9 },
    { title: "0", titleUpper: "0", key: "0",  code: "key0", keyCode: 0 },
    { title: <IcBackSpace />, titleUpper: <IcBackSpace />, key: "backspace",  code: "keyBackspace", keyCode: 0 }
]

const keyboardArrayLine2 = [
    { title: "q", titleUpper: "Q", key: "q",  code: "keyQ", keyCode: 1 },
    { title: "w", titleUpper: "W", key: "w",  code: "keyW", keyCode: 2 },
    { title: "e", titleUpper: "E", key: "e",  code: "keyE", keyCode: 3 },
    { title: "r", titleUpper: "R", key: "r",  code: "keyR", keyCode: 4 },
    { title: "t", titleUpper: "T", key: "t",  code: "keyT", keyCode: 5 },
    { title: "y", titleUpper: "Y", key: "y",  code: "keyY", keyCode: 6 },
    { title: "u", titleUpper: "U", key: "u",  code: "keyU", keyCode: 7 },
    { title: "i", titleUpper: "I", key: "i",  code: "keyI", keyCode: 8 },
    { title: "o", titleUpper: "O", key: "o",  code: "keyO", keyCode: 0 },
    { title: "p", titleUpper: "P", key: "p",  code: "keyP", keyCode: 0 },
    { title: ":", titleUpper: ":", key: ":",  code: "key:", keyCode: 9 },
    { title: ";", titleUpper: ";", key: ";",  code: "key;", keyCode: 9 },
]

const keyboardArrayLine3 = [
    { title: "a", titleUpper: "A", key: "a",  code: "keyA", keyCode: 1 },
    { title: "s", titleUpper: "S", key: "s",  code: "keyS", keyCode: 2 },
    { title: "d", titleUpper: "D", key: "d",  code: "keyD", keyCode: 3 },
    { title: "f", titleUpper: "F", key: "f",  code: "keyF", keyCode: 4 },
    { title: "g", titleUpper: "G", key: "g",  code: "keyG", keyCode: 5 },
    { title: "h", titleUpper: "H", key: "h",  code: "keyH", keyCode: 6 },
    { title: "j", titleUpper: "J", key: "j",  code: "keyJ", keyCode: 7 },
    { title: "k", titleUpper: "K", key: "k",  code: "keyK", keyCode: 8 },
    { title: "l", titleUpper: "L", key: "l",  code: "keyL", keyCode: 0 },
    { title: <IcEnter />, titleUpper: <IcEnter />, key: "enter",  code: "keyEnter", keyCode: 0 },
]

const keyboardArrayLine4 = [
    { title: <IcShift />, titleUpper: <IcShift active />, key: "shift",  code: "keyShift", keyCode: 1 },
    { title: "z", titleUpper: "Z", key: "z",  code: "keyZ", keyCode: 2 },
    { title: "x", titleUpper: "X", key: "x",  code: "keyX", keyCode: 3 },
    { title: "c", titleUpper: "C", key: "c",  code: "keyC", keyCode: 4 },
    { title: "v", titleUpper: "V", key: "v",  code: "keyV", keyCode: 5 },
    { title: "b", titleUpper: "B", key: "b",  code: "keyB", keyCode: 6 },
    { title: "n", titleUpper: "N", key: "n",  code: "keyN", keyCode: 7 },
    { title: "m", titleUpper: "M", key: "m",  code: "keyM", keyCode: 8 },
    { title: "@", titleUpper: "@", key: "@",  code: "key@", keyCode: 0 },
    { title: "-", titleUpper: "-", key: "-",  code: "key-", keyCode: 0 },
    { title: ",", titleUpper: ",", key: ",",  code: "key,", keyCode: 0 },
    { title: ".", titleUpper: ".", key: ".",  code: "key.", keyCode: 0 },
]

const keyboardArrayLine5 = [
    {
        styleInput: [
            { title: "VNI", titleUpper: "VNI", key: "vni",  code: "keyVNI", keyCode: 1 },
            { title: "TELEX", titleUpper: "TELEX", key: "telex",  code: "keyTelex", keyCode: 1 }
        ]
    },
    { title: <img src={ic_wee_space} alt="space" />, titleUpper: <img src={ic_wee_space} alt="space" />, key: " ",  code: "keyWhiteSpace", keyCode: 2 },
    { title: "?#&", titleUpper: "?#&", key: "symbols",  code: "keySymbols", keyCode: 3 },
]

export const keyboardArray = [
    keyboardArrayLine1,
    keyboardArrayLine2,
    keyboardArrayLine3,
    keyboardArrayLine4,
    keyboardArrayLine5
]

const keyboardSymbolArrayLine2 = [
    { title: "!", titleUpper: "!", key: "!",  code: "keyQ", keyCode: 1 },
    { title: "@", titleUpper: "@", key: "@",  code: "keyW", keyCode: 2 },
    { title: "#", titleUpper: "#", key: "#",  code: "keyE", keyCode: 3 },
    { title: "$", titleUpper: "$", key: "$",  code: "key$", keyCode: 4 },
    { title: "%", titleUpper: "%", key: "%",  code: "key%", keyCode: 5 },
    { title: "^", titleUpper: "^", key: "^",  code: "key^", keyCode: 6 },
    { title: "&", titleUpper: "&", key: "&",  code: "key&", keyCode: 7 },
    { title: "*", titleUpper: "*", key: "*",  code: "key*", keyCode: 8 },
    { title: "(", titleUpper: "(", key: "(",  code: "key(", keyCode: 0 },
    { title: ")", titleUpper: ")", key: ")",  code: "key)", keyCode: 0 },
    { title: "-", titleUpper: "-", key: "-",  code: "key-", keyCode: 9 },
    { title: "+", titleUpper: "+", key: "+",  code: "key+", keyCode: 9 },
]

const keyboardSymbolArrayLine3 = [
    { title: "[", titleUpper: "[", key: "[",  code: "keyOpenBracket", keyCode: 1 },
    { title: "]", titleUpper: "]", key: "]",  code: "key]", keyCode: 2 },
    { title: "{", titleUpper: "{", key: "{",  code: "key{", keyCode: 3 },
    { title: "}", titleUpper: "}", key: "}",  code: "key}", keyCode: 4 },
    { title: ":", titleUpper: ":", key: ":",  code: "key:", keyCode: 5 },
    { title: ";", titleUpper: ";", key: ";",  code: "key;", keyCode: 6 },
    { title: "...", titleUpper: "...", key: "...",  code: "key...", keyCode: 7 },
    { title: "?", titleUpper: "?", key: "?",  code: "key?", keyCode: 8 },
    { title: "~", titleUpper: "~", key: "~",  code: "key~", keyCode: 0 },
    { title: <IcEnter />, titleUpper: <IcEnter />, key: "enter",  code: "keyEnter", keyCode: 0 },
]

const keyboardSymbolArrayLine4 = [
    { title: <IcShift />, titleUpper: <IcShift active />, key: "shift",  code: "keyShift", keyCode: 1 },
    { title: "/", titleUpper: "/", key: "/",  code: "key/", keyCode: 2 },
    { title: "\\", titleUpper: "\\", key: "\\",  code: "key\\", keyCode: 3 },
    { title: "<", titleUpper: "<", key: "<",  code: "key<", keyCode: 4 },
    { title: ">", titleUpper: ">", key: ">",  code: "key>", keyCode: 5 },
    { title: "'", titleUpper: "'", key: "'",  code: "key'", keyCode: 6 },
    { title: "\"", titleUpper: "\"", key: "\"",  code: "key\"", keyCode: 7 },
    { title: ".com", titleUpper: ".com", key: ".com",  code: "key.com", keyCode: 8 },
    { title: "@", titleUpper: "@", key: "@",  code: "key@", keyCode: 0 },
    { title: "-", titleUpper: "-", key: "-",  code: "key-", keyCode: 0 },
    { title: ",", titleUpper: ",", key: ",",  code: "key,", keyCode: 0 },
    { title: ".", titleUpper: ".", key: ".",  code: "key.", keyCode: 0 },
]

export const keyboardSymbolArray = [
    keyboardArrayLine1,
    keyboardSymbolArrayLine2,
    keyboardSymbolArrayLine3,
    keyboardSymbolArrayLine4,
    keyboardArrayLine5
]