import IcDEPEXT from "../icon/IcDEPEXT";
import IcDEPINT from "../icon/IcDEPINT";
import IcTRANSEXTACC from "../icon/IcTRANSEXTACC";
import IcTRANSEXTCARD from "../icon/IcTRANSEXTCARD";
import IcTRANSINT from "../icon/IcTRANSINT";
import IcWITHDRAW from "../icon/IcWITHDRAW";
import IconNewAccount from "../icon/newAccount";
import adv01 from "../img/adv_01.png";
import adv02 from "../img/adv_02.png";
import adv03 from "../img/adv_03.png";
// import adv04 from "../video/van_tay.mp4";

const host = "http://kioskwebagribank.wee.vn/api"
const hostLocal = "http://localhost:8080/net"
// const hostLocal = "http://kioskwebagribank.wee.vn/net"

const hostWsMK = "http://localhost"

const ipAddressOfDevice = "172.16.15.126"

const routePath = {
    MainPage: '/',
    HomePage: '/home',
    ScanFingerPage: "/scan-finger",
    IssueTicketPage: "/issue-ticket",
    DetectFace: "/detect-face",
    ScanCCCD: "/scan-cccd",
    RatingPage: "/rating-page",
    ThanksPage: "/thanks-page",
}

const screen = {
    Width: "1080px",
    Height: "1920px",
    CameraWidth: "1215px",
    CameraHeght: "1620px",
    WidthValue: 1080,
    HeightValue: 1920
}

const advertisingType = {
    Image: "Image",
    Video: "Video"
}

const position = {
    Left: "LEFT",
    Right: "RIGHT"
}

const transTypeList = [
    {
        id: "64113166a3cfde73b1f648d7",
        value: "Mở tài khoản",
        type: "TRANSEXT",
        icon: <IconNewAccount />,
        position: position.Left
    },
    {
        id: "64113272a3cfde73b1f648d8",
        value: "Sao kê - số phụ",
        type: "TRANSEXTACC",
        icon: <IcTRANSEXTACC />,
        position: position.Right
    },
    {
        id: "64113287a3cfde73b1f648d9",
        value: "Tư vấn dịch vụ",
        type: "TRANSEXTCARD",
        icon: <IcTRANSEXTCARD />,
        position: position.Left
    },
    {
        id: "64113293a3cfde73b1f648da",
        value: "Dịch vụ thẻ",
        type: "TRANSINT",
        icon: <IcTRANSINT />,
        position: position.Right
    },
    {
        id: "641132b2a3cfde73b1f648db",
        value: "Giao dịch hạn mức trên 300 triệu",
        type: "SAVINGDEP",
        icon: <IconNewAccount />,
        position: position.Left
    },
    {
        id: "641132bda3cfde73b1f648dc",
        value: "Tiền gửi",
        type: "DEPEXT",
        icon: <IcDEPEXT />,
        position: position.Right
    },
    {
        id: "641132c8a3cfde73b1f648dd",
        value: "Tín dụng",
        type: "DEPINT",
        icon: <IcDEPINT />,
        position: position.Left
    },
    {
        id: "641132dca3cfde73b1f648de",
        value: "Khách hàng ưu tiên",
        type: "WITHDRAW",
        icon: <IcWITHDRAW />,
        position: position.Right
    },
];

const advertisingInfo = {
    slideTime: 10000,
    dataList: [
        // { type: advertisingType.Video, src: adv04 },
        { type: advertisingType.Image, src: adv01 },
        { type: advertisingType.Image, src: adv02 },
        { type: advertisingType.Image, src: adv03 },
    ],
};

const flowCode = {
    unRegistered: 1,
    registered: 2,
    scanFinger: 3,
    retry: 4
}

const scanFingerStatus = {
    noFinger: 11,
    hasFinger: 10,
    processing: 15,
    done: 16,
}

const customerType = {
    VIP: "VIP",
    NORMAL: "NORMAL"
}

export const ConstKey = {
    DEVICE_INFO: "DEVICE_INFO",
    FACE_INFO: "FACE_INFO",
    CUSTOMER_INFO: "CUSTOMER_INFO",
    TICKET_INFO: "TICKET_INFO"
}

export const ConstData = {
    host: host,
    hostLocal: hostLocal,
    hostWsMK: hostWsMK,
    routePath: routePath,
    ipAddressOfDevice: ipAddressOfDevice,
    delayTimeWhenNoFace: 10000,
    delayTimeWhenNoAction: 30000,
    screen: screen,
    position: position,
    advertisingType: advertisingType,
    transTypeList: transTypeList,
    advertisingInfo: advertisingInfo,
    flowCode: flowCode,
    scanFingerStatus: scanFingerStatus,
    numberOfRetry: 1,
    customerType: customerType
};