import styled from "styled-components";
import PageBg from "../../components/PageBg/index.styled";

const Wrap = styled(PageBg)`
    overflow: hidden;
`

const WrapRatingPage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .container{
        width: 960px;
        height: 1506px;
        margin: 121px 60px;
        background: #ffffff;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 128px;
        .rate-question{
            font-family: 'SVN-Gilroy';
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: 54px;
            width: 580px;
            text-align: center;
        }
        .rate-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 800px;
            /* gap: 30px; */
            .rate-icon-item{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                /* gap: 30px; */
                width: 245px;
                .icon{
                    border: 6px solid #ffffff;
                    border-radius: 50%;
                    width: 96px;
                    height: 96px;
                }
                .text{
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%
                }
                &.selected{
                    .icon{
                        border: 6px solid #007822;
                        border-radius: 50%;
                        width: 96px;
                        height: 96px;
                    }
                    .text{
                        color: #007822;
                        font-weight: 700;
                    }
                }
            }
        }
    }

`

export {
    Wrap, WrapRatingPage
}