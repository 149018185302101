import React from 'react'

const IconExit = (props) => {
  return (
    <svg {...props} width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.4997 25.4402C23.8496 25.4402 23.2869 25.2032 22.8122 24.7278C22.3374 24.2531 22.0994 23.6902 22.0994 23.0404V11.0401C22.0994 10.3899 22.3371 9.82747 22.8122 9.35271C23.2868 8.87743 23.8496 8.63989 24.4997 8.63989C25.1499 8.63989 25.7123 8.8777 26.1873 9.35271C26.6624 9.82747 26.8999 10.3902 26.8999 11.0401V23.0405C26.8999 23.6903 26.6624 24.2531 26.1873 24.7279C25.7123 25.2032 25.1497 25.4402 24.4997 25.4402Z" fill="white"/>
<path d="M11.6086 19.0088C12.615 16.9965 14.0305 15.3089 15.8554 13.9465C16.3804 13.5465 16.9741 13.3902 17.6367 13.4776C18.2992 13.5652 18.8242 13.8777 19.2117 14.415C19.6117 14.9399 19.7645 15.5305 19.671 16.1868C19.5776 16.8434 19.2679 17.3713 18.7429 17.7714C17.5184 18.6963 16.5709 19.8277 15.9025 21.1652C15.2337 22.5026 14.8997 23.9278 14.8997 25.44C14.8997 26.7404 15.1528 27.9807 15.6589 29.1622C16.165 30.3435 16.8496 31.3651 17.7122 32.2278C18.5748 33.0901 19.5962 33.7744 20.7777 34.2808C21.959 34.7871 23.1996 35.0406 24.4996 35.0406C25.7994 35.0406 27.0401 34.7871 28.2214 34.2808C29.4028 33.7744 30.4245 33.0901 31.2872 32.2278C32.1494 31.3651 32.8338 30.3435 33.3402 29.1622C33.8464 27.9807 34.0996 26.7404 34.0996 25.44C34.0996 23.9278 33.7652 22.5028 33.0965 21.1652C32.4277 19.8279 31.4808 18.6965 30.2559 17.7714C29.7309 17.3711 29.4216 16.8432 29.3277 16.1868C29.234 15.5308 29.387 14.9399 29.7871 14.415C30.1747 13.8777 30.6963 13.5651 31.3527 13.4776C32.009 13.3902 32.6061 13.5465 33.1435 13.9465C34.9687 15.3089 36.3841 16.9965 37.3905 19.0088C38.3969 21.0215 38.8999 23.1652 38.8999 25.44C38.8999 27.3903 38.5186 29.2526 37.7562 31.0276C36.9935 32.8027 35.9687 34.3336 34.681 35.6214C33.3936 36.9088 31.8623 37.934 30.0872 38.6959C28.3122 39.4586 26.4497 39.8403 24.4997 39.8403C22.5496 39.8403 20.6872 39.4586 18.9121 38.6959C17.1369 37.9338 15.6056 36.9087 14.3181 35.6214C13.0308 34.334 12.0059 32.8027 11.2434 31.0276C10.4809 29.2526 10.0999 27.3899 10.0999 25.44C10.0998 23.1651 10.6022 21.0214 11.6086 19.0088Z" fill="white"/>
</svg>

  )
}

export default IconExit