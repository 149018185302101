import React from 'react'

const IcSmile = (props) => {
    return (
        <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.0001 29.3334C23.3638 29.3334 29.3334 23.3638 29.3334 16C29.3334 8.63622 23.3638 2.66669 16.0001 2.66669C8.63628 2.66669 2.66675 8.63622 2.66675 16C2.66675 23.3638 8.63628 29.3334 16.0001 29.3334ZM16.0001 26.6667C21.8911 26.6667 26.6667 21.8911 26.6667 16C26.6667 10.109 21.8911 5.33335 16.0001 5.33335C10.109 5.33335 5.33341 10.109 5.33341 16C5.33341 21.8911 10.109 26.6667 16.0001 26.6667ZM12.0001 14.6667C12.7365 14.6667 13.3334 14.0698 13.3334 13.3334C13.3334 12.597 12.7365 12 12.0001 12C11.2637 12 10.6667 12.597 10.6667 13.3334C10.6667 14.0698 11.2637 14.6667 12.0001 14.6667ZM18.6667 17.3334H21.3334C21.3334 20.2788 18.9455 22.6667 16.0001 22.6667C13.0546 22.6667 10.6667 20.2788 10.6667 17.3334H13.3334C13.3334 18.8062 14.5273 20 16.0001 20C17.4729 20 18.6667 18.8062 18.6667 17.3334ZM20.0001 14.6667C20.7365 14.6667 21.3334 14.0698 21.3334 13.3334C21.3334 12.597 20.7365 12 20.0001 12C19.2637 12 18.6667 12.597 18.6667 13.3334C18.6667 14.0698 19.2637 14.6667 20.0001 14.6667Z" fill="white" />
        </svg>
    )
}

export default IcSmile