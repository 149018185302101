export const vniSpecialKey = {
    "1": [
        { value: "a", valueReplace: "á" },
        { value: "A", valueReplace: "Á" },
        { value: "â", valueReplace: "ấ" },
        { value: "Â", valueReplace: "Ấ" },
        { value: "ă", valueReplace: "ắ" },
        { value: "Ă", valueReplace: "Ắ" },
        { value: "e", valueReplace: "é" },
        { value: "E", valueReplace: "É" },
        { value: "ê", valueReplace: "ế" },
        { value: "Ê", valueReplace: "Ế" },
        { value: "o", valueReplace: "ó" },
        { value: "O", valueReplace: "Ó" },
        { value: "ô", valueReplace: "ố" },
        { value: "Ô", valueReplace: "Ố" },
        { value: "ơ", valueReplace: "ớ" },
        { value: "Ơ", valueReplace: "Ớ" },
        { value: "u", valueReplace: "ú" },
        { value: "U", valueReplace: "Ú" },
        { value: "ư", valueReplace: "ứ" },
        { value: "Ư", valueReplace: "Ứ" },
        { value: "y", valueReplace: "ý" },
        { value: "Y", valueReplace: "Ý" },
        { value: "i", valueReplace: "í" },
        { value: "I", valueReplace: "Í" },
    ],
    "2": [
        { value: "a", valueReplace: "à" },
        { value: "A", valueReplace: "À" },
        { value: "â", valueReplace: "ầ" },
        { value: "Â", valueReplace: "Ầ" },
        { value: "ă", valueReplace: "ằ" },
        { value: "Ă", valueReplace: "Ằ" },
        { value: "e", valueReplace: "è" },
        { value: "E", valueReplace: "È" },
        { value: "ê", valueReplace: "ề" },
        { value: "Ê", valueReplace: "Ề" },
        { value: "o", valueReplace: "ò" },
        { value: "O", valueReplace: "Ò" },
        { value: "ô", valueReplace: "ồ" },
        { value: "Ô", valueReplace: "Ồ" },
        { value: "ơ", valueReplace: "ờ" },
        { value: "Ơ", valueReplace: "Ờ" },
        { value: "u", valueReplace: "ù" },
        { value: "U", valueReplace: "Ù" },
        { value: "ư", valueReplace: "ừ" },
        { value: "Ư", valueReplace: "Ừ" },
        { value: "y", valueReplace: "ỳ" },
        { value: "Y", valueReplace: "Ỳ" },
        { value: "i", valueReplace: "ì" },
        { value: "I", valueReplace: "Ì" },
    ],
    "3": [
        { value: "a", valueReplace: "ả" },
        { value: "A", valueReplace: "Ả" },
        { value: "â", valueReplace: "ẩ" },
        { value: "Â", valueReplace: "Ẩ" },
        { value: "ă", valueReplace: "ẳ" },
        { value: "Ă", valueReplace: "Ẳ" },
        { value: "e", valueReplace: "ẻ" },
        { value: "E", valueReplace: "Ẻ" },
        { value: "ê", valueReplace: "ể" },
        { value: "Ê", valueReplace: "Ể" },
        { value: "o", valueReplace: "ỏ" },
        { value: "O", valueReplace: "Ỏ" },
        { value: "ô", valueReplace: "ổ" },
        { value: "Ô", valueReplace: "Ổ" },
        { value: "ơ", valueReplace: "ở" },
        { value: "Ơ", valueReplace: "Ở" },
        { value: "u", valueReplace: "ủ" },
        { value: "U", valueReplace: "Ủ" },
        { value: "ư", valueReplace: "ử" },
        { value: "Ư", valueReplace: "Ử" },
        { value: "y", valueReplace: "ỷ" },
        { value: "Y", valueReplace: "Ỷ" },
        { value: "i", valueReplace: "ỉ" },
        { value: "I", valueReplace: "Ỉ" },
        
    ],
    "4": [
        { value: "a", valueReplace: "ã" },
        { value: "A", valueReplace: "Ã" },
        { value: "â", valueReplace: "ẫ" },
        { value: "Â", valueReplace: "Ẫ" },
        { value: "ă", valueReplace: "ẵ" },
        { value: "Ă", valueReplace: "Ẵ" },
        { value: "e", valueReplace: "ẽ" },
        { value: "E", valueReplace: "Ẽ" },
        { value: "ê", valueReplace: "ễ" },
        { value: "Ê", valueReplace: "Ễ" },
        { value: "o", valueReplace: "õ" },
        { value: "O", valueReplace: "Õ" },
        { value: "ô", valueReplace: "ỗ" },
        { value: "Ô", valueReplace: "Ỗ" },
        { value: "ơ", valueReplace: "ỡ" },
        { value: "Ơ", valueReplace: "Ỡ" },
        { value: "u", valueReplace: "ũ" },
        { value: "U", valueReplace: "Ũ" },
        { value: "ư", valueReplace: "ữ" },
        { value: "Ư", valueReplace: "Ữ" },
        { value: "y", valueReplace: "ỹ" },
        { value: "Y", valueReplace: "Ỹ" },
        { value: "i", valueReplace: "ĩ" },
        { value: "I", valueReplace: "Ĩ" },
    ],
    "5": [
        { value: "a", valueReplace: "ạ" },
        { value: "A", valueReplace: "Ạ" },
        { value: "â", valueReplace: "ậ" },
        { value: "Â", valueReplace: "Ậ" },
        { value: "ă", valueReplace: "ặ" },
        { value: "Ă", valueReplace: "Ặ" },
        { value: "e", valueReplace: "ẹ" },
        { value: "E", valueReplace: "Ẹ" },
        { value: "ê", valueReplace: "ệ" },
        { value: "Ê", valueReplace: "Ệ" },
        { value: "o", valueReplace: "ọ" },
        { value: "O", valueReplace: "Ọ" },
        { value: "ô", valueReplace: "ộ" },
        { value: "Ô", valueReplace: "Ộ" },
        { value: "ơ", valueReplace: "ợ" },
        { value: "Ơ", valueReplace: "Ợ" },
        { value: "u", valueReplace: "ụ" },
        { value: "Y", valueReplace: "Ụ" },
        { value: "ư", valueReplace: "ự" },
        { value: "Ư", valueReplace: "Ự" },
        { value: "y", valueReplace: "ỵ" },
        { value: "Y", valueReplace: "Ỵ" },
        { value: "i", valueReplace: "ị" },
        { value: "I", valueReplace: "Ị" },
    ],
    "6": [
        { value: "a", valueReplace: "â" },
        { value: "á", valueReplace: "ấ" },
        { value: "à", valueReplace: "ầ" },
        { value: "ả", valueReplace: "ẩ" },
        { value: "ã", valueReplace: "ẫ" },
        { value: "ạ", valueReplace: "ậ" },
        { value: "A", valueReplace: "Â" },
        { value: "Á", valueReplace: "Ấ" },
        { value: "À", valueReplace: "Ầ" },
        { value: "Ả", valueReplace: "Ẩ" },
        { value: "Ã", valueReplace: "Ẫ" },
        { value: "Ạ", valueReplace: "Ậ" },
        { value: "e", valueReplace: "ê" },
        { value: "é", valueReplace: "ế" },
        { value: "è", valueReplace: "ề" },
        { value: "ẻ", valueReplace: "ể" },
        { value: "ẽ", valueReplace: "ễ" },
        { value: "ẹ", valueReplace: "ệ" },
        { value: "E", valueReplace: "Ê" },
        { value: "É", valueReplace: "Ế" },
        { value: "È", valueReplace: "Ề" },
        { value: "Ẻ", valueReplace: "Ể" },
        { value: "Ẽ", valueReplace: "Ễ" },
        { value: "Ẹ", valueReplace: "Ệ" },
        { value: "o", valueReplace: "ô" },
        { value: "ó", valueReplace: "ố" },
        { value: "ò", valueReplace: "ồ" },
        { value: "ỏ", valueReplace: "ổ" },
        { value: "õ", valueReplace: "ỗ" },
        { value: "ọ", valueReplace: "ộ" },
        { value: "O", valueReplace: "Ô" },
        { value: "Ó", valueReplace: "Ố" },
        { value: "Ò", valueReplace: "Ồ" },
        { value: "Ỏ", valueReplace: "Ổ" },
        { value: "Õ", valueReplace: "Ỗ" },
        { value: "Ọ", valueReplace: "Ộ" },
    ],
    "7": [
        { value: "uo", valueReplace: "ươ" },
        { value: "uó", valueReplace: "ướ" },
        { value: "uò", valueReplace: "ườ" },
        { value: "uỏ", valueReplace: "ưở" },
        { value: "uõ", valueReplace: "ưỡ" },
        { value: "uọ", valueReplace: "ượ" },

        { value: "UO", valueReplace: "ƯƠ" },
        { value: "UÓ", valueReplace: "ƯỚ" },
        { value: "UÒ", valueReplace: "ƯỜ" },
        { value: "UỎ", valueReplace: "ƯỞ" },
        { value: "UÕ", valueReplace: "ƯỠ" },
        { value: "UỌ", valueReplace: "ƯỢ" },

        { value: "Uo", valueReplace: "Ươ" },
        { value: "Uó", valueReplace: "Ướ" },
        { value: "Uò", valueReplace: "Ườ" },
        { value: "Uỏ", valueReplace: "Ưở" },
        { value: "Uõ", valueReplace: "Ưỡ" },
        { value: "Uọ", valueReplace: "Ượ" },

        { value: "uO", valueReplace: "ưƠ" },
        { value: "uÓ", valueReplace: "ưỚ" },
        { value: "uÒ", valueReplace: "ưỜ" },
        { value: "uỎ", valueReplace: "ưỞ" },
        { value: "uÕ", valueReplace: "ưỠ" },
        { value: "uỌ", valueReplace: "ưỢ" },

        //
        { value: "ưo", valueReplace: "ươ" },
        { value: "ưó", valueReplace: "ướ" },
        { value: "ưò", valueReplace: "ườ" },
        { value: "ưỏ", valueReplace: "ưở" },
        { value: "ưõ", valueReplace: "ưỡ" },
        { value: "ưọ", valueReplace: "ượ" },

        { value: "ƯO", valueReplace: "ƯƠ" },
        { value: "ƯÓ", valueReplace: "ƯỚ" },
        { value: "ƯÒ", valueReplace: "ƯỜ" },
        { value: "ƯỎ", valueReplace: "ƯỞ" },
        { value: "ƯÕ", valueReplace: "ƯỠ" },
        { value: "ƯỌ", valueReplace: "ƯỢ" },

        { value: "Ưo", valueReplace: "Ươ" },
        { value: "Ưó", valueReplace: "Ướ" },
        { value: "Ưò", valueReplace: "Ườ" },
        { value: "Ưỏ", valueReplace: "Ưở" },
        { value: "Ưõ", valueReplace: "Ưỡ" },
        { value: "Ưọ", valueReplace: "Ượ" },

        { value: "ưO", valueReplace: "ưƠ" },
        { value: "ưÓ", valueReplace: "ưỚ" },
        { value: "ưÒ", valueReplace: "ưỜ" },
        { value: "ưỎ", valueReplace: "ưỞ" },
        { value: "ưÕ", valueReplace: "ưỠ" },
        { value: "ưỌ", valueReplace: "ưỢ" },

        //
        { value: "uơ", valueReplace: "ươ" },
        { value: "uớ", valueReplace: "ướ" },
        { value: "uờ", valueReplace: "ườ" },
        { value: "uở", valueReplace: "ưở" },
        { value: "uỡ", valueReplace: "ưỡ" },
        { value: "uợ", valueReplace: "ượ" },

        { value: "UƠ", valueReplace: "ƯƠ" },
        { value: "UỚ", valueReplace: "ƯỚ" },
        { value: "UỜ", valueReplace: "ƯỜ" },
        { value: "UỞ", valueReplace: "ƯỞ" },
        { value: "UỠ", valueReplace: "ƯỠ" },
        { value: "UỢ", valueReplace: "ƯỢ" },

        { value: "Uơ", valueReplace: "Ươ" },
        { value: "Uớ", valueReplace: "Ướ" },
        { value: "Uờ", valueReplace: "Ườ" },
        { value: "Uở", valueReplace: "Ưở" },
        { value: "Uỡ", valueReplace: "Ưỡ" },
        { value: "Uợ", valueReplace: "Ượ" },

        { value: "uƠ", valueReplace: "ưƠ" },
        { value: "uỚ", valueReplace: "ưỚ" },
        { value: "uỜ", valueReplace: "ưỜ" },
        { value: "uỞ", valueReplace: "ưỞ" },
        { value: "uỠ", valueReplace: "ưỠ" },
        { value: "uỢ", valueReplace: "ưỢ" },
        
        //
        { value: "o", valueReplace: "ơ" },
        { value: "ó", valueReplace: "ớ" },
        { value: "ò", valueReplace: "ờ" },
        { value: "ỏ", valueReplace: "ở" },
        { value: "õ", valueReplace: "ỡ" },
        { value: "ọ", valueReplace: "ợ" },
        { value: "O", valueReplace: "Ơ" },
        { value: "Ó", valueReplace: "Ớ" },
        { value: "Ò", valueReplace: "Ờ" },
        { value: "Ỏ", valueReplace: "Ở" },
        { value: "Õ", valueReplace: "Ỡ" },
        { value: "Ọ", valueReplace: "Ợ" },
        { value: "u", valueReplace: "ư" },
        { value: "ú", valueReplace: "ứ" },
        { value: "ù", valueReplace: "ừ" },
        { value: "ủ", valueReplace: "ử" },
        { value: "ũ", valueReplace: "ữ" },
        { value: "ụ", valueReplace: "ự" },
        { value: "U", valueReplace: "Ư" },
        { value: "Ú", valueReplace: "Ứ" },
        { value: "Ù", valueReplace: "Ừ" },
        { value: "Ủ", valueReplace: "Ử" },
        { value: "Ũ", valueReplace: "Ữ" },
        { value: "Ụ", valueReplace: "Ự" },
    ],
    "8": [
        { value: "a", valueReplace: "ă" },
        { value: "á", valueReplace: "ắ" },
        { value: "à", valueReplace: "ằ" },
        { value: "ả", valueReplace: "ẳ" },
        { value: "ã", valueReplace: "ẵ" },
        { value: "ạ", valueReplace: "ặ" },
        { value: "A", valueReplace: "Ă" },
        { value: "Á", valueReplace: "Ắ" },
        { value: "À", valueReplace: "Ằ" },
        { value: "Ả", valueReplace: "Ẳ" },
        { value: "Ã", valueReplace: "Ẵ" },
        { value: "Ạ", valueReplace: "Ặ" },
    ],
    "9": [
        { value: "d", valueReplace: "đ" },
        { value: "D", valueReplace: "Đ" }
    ]
}