
//css
import { WrapBackground } from "./index.styles";
//component
import ScanCCCDControl from "../../controls/ScanCCCDControl";
//hook
import { damping, variants } from "../../utils/transition_page";
import { motion } from "framer-motion";

const ScanCCCDPage = () => {  

  return (
    <WrapBackground>
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: damping },
          opacity: { duration: 0.2 },
        }}
      >
        <ScanCCCDControl />
      </motion.div>
    </WrapBackground>
  );
};

export default ScanCCCDPage;
