import styled from 'styled-components'

const WrapVirtualKeyboard = styled.div`
    position: absolute;
    left: 0;
    top: calc(100% + 7px);
    border-radius: 9px;
    padding: 10px;
    background: #171717;
    box-shadow: 0px 4.08511px 4.08511px rgba(0, 0, 0, 0.25);
    z-index: 9;
    button {
        outline: none;
        border: none;
        padding: 0;
        border-radius: 17px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 25px;
        cursor: pointer;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content:center;

        &:not(.input-type-encode) {
            margin: 0 3px;
            height: 60px;
            min-width: 60px;
            background-color: inherit;
        }
    }
    button:active {
        background: rgba(0, 120, 34, 0.3);
    }
    .bound{
        display: flex;
        flex-direction: column;
    }
    .line{
        margin: 3px 0;
        display: flex;
        flex-direction: row;
        .input-style-bound{
            width: 126px;
            position: relative;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #0E0E0E;
            border-radius: 48px;

            button {
                width: 100%;
                height: 100%;
                background: none;
            }
            
            .input-style-dropdown{
                position: absolute;
                left: 0;
                bottom: calc(100% + 8px);
                width: 150px;
                background: red;
                border-radius: 6px;
                background: #343434;
                div{
                    height: 50px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .active{
                    color: #007822
                }
            }
        }

        #keyA, #keyOpenBracket {
            margin-left: 41px;
        }

        #keyShift, #keyBackspace, #keyEnter, #keyWhiteSpace, #keySymbols {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #0E0E0E;
        }

        #keyBackspace, #keyEnter, #keyWhiteSpace {
            flex-grow: 1;
            border-radius: 48px;
        }

        #keySymbols {
            width: 126px;
            border-radius: 48px;
        }
    }
`;

export {
    WrapVirtualKeyboard
}