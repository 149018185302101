import React from 'react'

const IcDEPEXT = (props) => {
    return (
        <svg {...props} width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.69422 23.5967C9.48797 16.3729 15.2159 10.7876 22.4813 9.17765L23.6916 8.90946C29.1641 7.69685 34.8356 7.69685 40.308 8.90946L41.5183 9.17765C48.7838 10.7876 54.5117 16.3729 56.3055 23.5967C57.6757 29.1151 57.6757 34.8849 56.3055 40.4033C54.5117 47.6271 48.7838 53.2124 41.5183 54.8224L40.308 55.0905C34.8356 56.3031 29.1641 56.3031 23.6916 55.0905L22.4813 54.8223C15.2159 53.2124 9.48797 47.6271 7.69422 40.4033C6.32393 34.8849 6.32393 29.1151 7.69422 23.5967Z" stroke="#AB1F24" strokeWidth="4.5" />
            <path d="M23.6916 8.90946C29.1641 7.69685 34.8356 7.69685 40.308 8.90946L41.5183 9.17765C48.7838 10.7876 54.5117 16.3729 56.3055 23.5967C57.6757 29.1151 57.6757 34.8849 56.3055 40.4033C54.5117 47.6271 48.7838 53.2124 41.5183 54.8224L40.308 55.0905C34.8356 56.3032 29.1641 56.3032 23.6916 55.0905L22.4813 54.8223C15.2159 53.2124 9.48797 47.6271 7.69422 40.4033C7.00907 37.6441 6.6665 34.8221 6.6665 32" stroke="#AB1F24" strokeWidth="4.5" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M32.0007 18.6667C33.0203 18.6667 33.8468 19.4454 33.8468 20.4059V21.8287H34.0513C37.4846 21.8287 40 24.6405 40 27.7839C40 28.7444 39.1734 29.523 38.1538 29.523C37.1342 29.523 36.3077 28.7444 36.3077 27.7839C36.3077 26.2704 35.1495 25.307 34.0513 25.307H33.8468V30.7821L36.3394 31.6359C38.6045 32.4119 40 34.5221 40 36.7569C40 39.6016 37.7202 42.1713 34.5776 42.1713H33.8468V43.5943C33.8468 44.5548 33.0203 45.3334 32.0007 45.3334C30.9811 45.3334 30.1545 44.5548 30.1545 43.5943V42.1713H29.9487C26.5154 42.1713 24 39.3595 24 36.2161C24 35.2556 24.8266 34.4769 25.8462 34.4769C26.8658 34.4769 27.6923 35.2556 27.6923 36.2161C27.6923 37.7296 28.8505 38.693 29.9487 38.693H30.1545V33.2183L27.6606 32.364C25.3955 31.5881 24 29.4779 24 27.243C24 24.3983 26.2798 21.8287 29.4224 21.8287H30.1545V20.4059C30.1545 19.4454 30.9811 18.6667 32.0007 18.6667ZM30.1545 25.307H29.4224C28.6148 25.307 27.6923 26.0282 27.6923 27.243C27.6923 28.172 28.2668 28.8706 28.9224 29.0952L30.1545 29.5173V25.307ZM33.8468 34.4832V38.693H34.5776C35.3852 38.693 36.3077 37.9717 36.3077 36.7569C36.3077 35.828 35.7332 35.1293 35.0776 34.9048L33.8468 34.4832Z" fill="#00783A" />
        </svg>
    )
}

export default IcDEPEXT