
import React, { useEffect, useRef } from 'react'
import { WrapUseRenderTime } from './index.styles'
import {TimeUtils} from '../../utils/time'

export default function UseRenderTime({ color }) {
    const timeRef = useRef()
    const timer = useRef(null)

    useEffect(() => {
        const checkTime = (i) => {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }
        const startTime = () => {
            var today = new Date()
            //---
            var h = today.getHours();
            var m = today.getMinutes();

            m = checkTime(m);
            timeRef.current.innerHTML = h + ":" + m + TimeUtils.convertDateTime(today, " DD MMM YYYY");
            //---
            var second = today.getSeconds();
            var milisecond = today.getMilliseconds();
            var timeout = ((59 - second) * 1000) + (1000 - milisecond);
            //---
            console.log("today: ", today);
            console.log("timeout: ", timeout);
            //---
            timer.current = setTimeout(function () {
                startTime()
            }, timeout);
        }
        startTime()

        return () => {
            clearTimeout(timer.current)
        }
    }, [])

    return (
        <WrapUseRenderTime color={color}>
            <div ref={timeRef} className="time"></div>
        </WrapUseRenderTime>
    )
}
