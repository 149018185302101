export const telexSpecialKey = {
    "w": [
        { value: "uo", valueReplace: "ươ" },
        { value: "uó", valueReplace: "ướ" },
        { value: "uò", valueReplace: "ườ" },
        { value: "uỏ", valueReplace: "ưở" },
        { value: "uõ", valueReplace: "ưỡ" },
        { value: "uọ", valueReplace: "ượ" },

        { value: "UO", valueReplace: "ƯƠ" },
        { value: "UÓ", valueReplace: "ƯỚ" },
        { value: "UÒ", valueReplace: "ƯỜ" },
        { value: "UỎ", valueReplace: "ƯỞ" },
        { value: "UÕ", valueReplace: "ƯỠ" },
        { value: "UỌ", valueReplace: "ƯỢ" },

        { value: "Uo", valueReplace: "Ươ" },
        { value: "Uó", valueReplace: "Ướ" },
        { value: "Uò", valueReplace: "Ườ" },
        { value: "Uỏ", valueReplace: "Ưở" },
        { value: "Uõ", valueReplace: "Ưỡ" },
        { value: "Uọ", valueReplace: "Ượ" },

        { value: "uO", valueReplace: "ưƠ" },
        { value: "uÓ", valueReplace: "ưỚ" },
        { value: "uÒ", valueReplace: "ưỜ" },
        { value: "uỎ", valueReplace: "ưỞ" },
        { value: "uÕ", valueReplace: "ưỠ" },
        { value: "uỌ", valueReplace: "ưỢ" },

        //
        { value: "ưo", valueReplace: "ươ" },
        { value: "ưó", valueReplace: "ướ" },
        { value: "ưò", valueReplace: "ườ" },
        { value: "ưỏ", valueReplace: "ưở" },
        { value: "ưõ", valueReplace: "ưỡ" },
        { value: "ưọ", valueReplace: "ượ" },

        { value: "ƯO", valueReplace: "ƯƠ" },
        { value: "ƯÓ", valueReplace: "ƯỚ" },
        { value: "ƯÒ", valueReplace: "ƯỜ" },
        { value: "ƯỎ", valueReplace: "ƯỞ" },
        { value: "ƯÕ", valueReplace: "ƯỠ" },
        { value: "ƯỌ", valueReplace: "ƯỢ" },

        { value: "Ưo", valueReplace: "Ươ" },
        { value: "Ưó", valueReplace: "Ướ" },
        { value: "Ưò", valueReplace: "Ườ" },
        { value: "Ưỏ", valueReplace: "Ưở" },
        { value: "Ưõ", valueReplace: "Ưỡ" },
        { value: "Ưọ", valueReplace: "Ượ" },

        { value: "ưO", valueReplace: "ưƠ" },
        { value: "ưÓ", valueReplace: "ưỚ" },
        { value: "ưÒ", valueReplace: "ưỜ" },
        { value: "ưỎ", valueReplace: "ưỞ" },
        { value: "ưÕ", valueReplace: "ưỠ" },
        { value: "ưỌ", valueReplace: "ưỢ" },

        //
        { value: "uơ", valueReplace: "ươ" },
        { value: "uớ", valueReplace: "ướ" },
        { value: "uờ", valueReplace: "ườ" },
        { value: "uở", valueReplace: "ưở" },
        { value: "uỡ", valueReplace: "ưỡ" },
        { value: "uợ", valueReplace: "ượ" },

        { value: "UƠ", valueReplace: "ƯƠ" },
        { value: "UỚ", valueReplace: "ƯỚ" },
        { value: "UỜ", valueReplace: "ƯỜ" },
        { value: "UỞ", valueReplace: "ƯỞ" },
        { value: "UỠ", valueReplace: "ƯỠ" },
        { value: "UỢ", valueReplace: "ƯỢ" },

        { value: "Uơ", valueReplace: "Ươ" },
        { value: "Uớ", valueReplace: "Ướ" },
        { value: "Uờ", valueReplace: "Ườ" },
        { value: "Uở", valueReplace: "Ưở" },
        { value: "Uỡ", valueReplace: "Ưỡ" },
        { value: "Uợ", valueReplace: "Ượ" },

        { value: "uƠ", valueReplace: "ưƠ" },
        { value: "uỚ", valueReplace: "ưỚ" },
        { value: "uỜ", valueReplace: "ưỜ" },
        { value: "uỞ", valueReplace: "ưỞ" },
        { value: "uỠ", valueReplace: "ưỠ" },
        { value: "uỢ", valueReplace: "ưỢ" },
        
        //
        { value: "a", valueReplace: "ă" },
        { value: "á", valueReplace: "ắ" },
        { value: "à", valueReplace: "ằ" },
        { value: "ả", valueReplace: "ẳ" },
        { value: "ã", valueReplace: "ẵ" },
        { value: "ạ", valueReplace: "ặ" },
        { value: "A", valueReplace: "Ă" },
        { value: "Á", valueReplace: "Ắ" },
        { value: "À", valueReplace: "Ằ" },
        { value: "Ả", valueReplace: "Ẳ" },
        { value: "Ã", valueReplace: "Ẵ" },
        { value: "Ạ", valueReplace: "Ặ" },
        { value: "o", valueReplace: "ơ" },
        { value: "ó", valueReplace: "ớ" },
        { value: "ò", valueReplace: "ờ" },
        { value: "ỏ", valueReplace: "ở" },
        { value: "õ", valueReplace: "ỡ" },
        { value: "ọ", valueReplace: "ợ" },
        { value: "O", valueReplace: "Ơ" },
        { value: "Ó", valueReplace: "Ớ" },
        { value: "Ò", valueReplace: "Ờ" },
        { value: "Ỏ", valueReplace: "Ở" },
        { value: "Õ", valueReplace: "Ỡ" },
        { value: "Ọ", valueReplace: "Ợ" },
        { value: "u", valueReplace: "ư" },
        { value: "ú", valueReplace: "ứ" },
        { value: "ù", valueReplace: "ừ" },
        { value: "ủ", valueReplace: "ử" },
        { value: "ũ", valueReplace: "ữ" },
        { value: "ụ", valueReplace: "ự" },
        { value: "U", valueReplace: "Ư" },
        { value: "Ú", valueReplace: "Ứ" },
        { value: "Ù", valueReplace: "Ừ" },
        { value: "Ủ", valueReplace: "Ử" },
        { value: "Ũ", valueReplace: "Ữ" },
        { value: "Ụ", valueReplace: "Ự" },
    ], 
    "e": [
        { value: "e", valueReplace: "ê" },
        { value: "é", valueReplace: "ế" },
        { value: "è", valueReplace: "ề" },
        { value: "ẻ", valueReplace: "ể" },
        { value: "ẽ", valueReplace: "ễ" },
        { value: "ẹ", valueReplace: "ệ" },
        { value: "E", valueReplace: "Ê" },
        { value: "É", valueReplace: "Ế" },
        { value: "È", valueReplace: "Ề" },
        { value: "Ẻ", valueReplace: "Ể" },
        { value: "Ẽ", valueReplace: "Ễ" },
        { value: "Ẹ", valueReplace: "Ệ" },
    ], 
    "r": [
        { value: "a", valueReplace: "ả" },
        { value: "á", valueReplace: "ả" },
        { value: "à", valueReplace: "ả" },
        { value: "ạ", valueReplace: "ả" },
        { value: "ã", valueReplace: "ả" },

        { value: "A", valueReplace: "Ả" },
        { value: "Á", valueReplace: "Ả" },
        { value: "À", valueReplace: "Ả" },
        { value: "Ạ", valueReplace: "Ả" },
        { value: "Ã", valueReplace: "Ả" },

        { value: "â", valueReplace: "ẩ" },
        { value: "ấ", valueReplace: "ẩ" },
        { value: "ầ", valueReplace: "ẩ" },
        { value: "ậ", valueReplace: "ẩ" },
        { value: "ẫ", valueReplace: "ẩ" },

        { value: "Â", valueReplace: "Ẩ" },
        { value: "Ấ", valueReplace: "Ẩ" },
        { value: "Ầ", valueReplace: "Ẩ" },
        { value: "Ậ", valueReplace: "Ẩ" },
        { value: "Ẫ", valueReplace: "Ẩ" },

        { value: "ă", valueReplace: "ẳ" },
        { value: "ắ", valueReplace: "ẳ" },
        { value: "ằ", valueReplace: "ẳ" },
        { value: "ặ", valueReplace: "ẳ" },
        { value: "ẵ", valueReplace: "ẳ" },

        { value: "Ă", valueReplace: "Ẳ" },
        { value: "Ắ", valueReplace: "Ẳ" },
        { value: "Ằ", valueReplace: "Ẳ" },
        { value: "Ặ", valueReplace: "Ẳ" },
        { value: "Ẵ", valueReplace: "Ẳ" },

        { value: "e", valueReplace: "ẻ" },
        { value: "é", valueReplace: "ẻ" },
        { value: "è", valueReplace: "ẻ" },
        { value: "ẹ", valueReplace: "ẻ" },
        { value: "ẽ", valueReplace: "ẻ" },

        { value: "E", valueReplace: "Ẻ" },
        { value: "É", valueReplace: "Ẻ" },
        { value: "È", valueReplace: "Ẻ" },
        { value: "Ẹ", valueReplace: "Ẻ" },
        { value: "Ẽ", valueReplace: "Ẻ" },

        { value: "ê", valueReplace: "ể" },
        { value: "ế", valueReplace: "ể" },
        { value: "ề", valueReplace: "ể" },
        { value: "ệ", valueReplace: "ể" },
        { value: "ễ", valueReplace: "ể" },

        { value: "Ê", valueReplace: "Ể" },
        { value: "Ế", valueReplace: "Ể" },
        { value: "Ề", valueReplace: "Ể" },
        { value: "Ệ", valueReplace: "Ể" },
        { value: "Ễ", valueReplace: "Ể" },

        { value: "o", valueReplace: "ỏ" },
        { value: "ó", valueReplace: "ỏ" },
        { value: "ò", valueReplace: "ỏ" },
        { value: "ọ", valueReplace: "ỏ" },
        { value: "õ", valueReplace: "ỏ" },

        { value: "O", valueReplace: "Ỏ" },
        { value: "Ó", valueReplace: "Ỏ" },
        { value: "Ò", valueReplace: "Ỏ" },
        { value: "Ọ", valueReplace: "Ỏ" },
        { value: "Õ", valueReplace: "Ỏ" },

        { value: "ô", valueReplace: "ổ" },
        { value: "ố", valueReplace: "ổ" },
        { value: "ồ", valueReplace: "ổ" },
        { value: "ộ", valueReplace: "ổ" },
        { value: "ỗ", valueReplace: "ổ" },

        { value: "Ô", valueReplace: "Ổ" },
        { value: "Ố", valueReplace: "Ổ" },
        { value: "Ồ", valueReplace: "Ổ" },
        { value: "Ộ", valueReplace: "Ổ" },
        { value: "Ỗ", valueReplace: "Ổ" },

        { value: "ơ", valueReplace: "ở" },
        { value: "ớ", valueReplace: "ở" },
        { value: "ờ", valueReplace: "ở" },
        { value: "ợ", valueReplace: "ở" },
        { value: "ỡ", valueReplace: "ở" },

        { value: "Ơ", valueReplace: "Ở" },
        { value: "Ớ", valueReplace: "Ở" },
        { value: "Ờ", valueReplace: "Ở" },
        { value: "Ợ", valueReplace: "Ở" },
        { value: "Ỡ", valueReplace: "Ở" },

        { value: "u", valueReplace: "ủ" },
        { value: "ú", valueReplace: "ủ" },
        { value: "ù", valueReplace: "ủ" },
        { value: "ụ", valueReplace: "ủ" },
        { value: "ũ", valueReplace: "ủ" },

        { value: "U", valueReplace: "Ủ" },
        { value: "Ú", valueReplace: "Ủ" },
        { value: "Ù", valueReplace: "Ủ" },
        { value: "Ụ", valueReplace: "Ủ" },
        { value: "Ũ", valueReplace: "Ủ" },

        { value: "ư", valueReplace: "ử" },
        { value: "ứ", valueReplace: "ử" },
        { value: "ừ", valueReplace: "ử" },
        { value: "ự", valueReplace: "ử" },
        { value: "ữ", valueReplace: "ử" },

        { value: "Ư", valueReplace: "Ử" },
        { value: "Ứ", valueReplace: "Ử" },
        { value: "Ừ", valueReplace: "Ử" },
        { value: "Ự", valueReplace: "Ử" },
        { value: "Ữ", valueReplace: "Ử" },

        { value: "y", valueReplace: "ỷ" },
        { value: "ý", valueReplace: "ỷ" },
        { value: "ỳ", valueReplace: "ỷ" },
        { value: "ỵ", valueReplace: "ỷ" },
        { value: "ỹ", valueReplace: "ỷ" },

        { value: "Y", valueReplace: "Ỷ" },
        { value: "Ý", valueReplace: "Ỷ" },
        { value: "Ỳ", valueReplace: "Ỷ" },
        { value: "Ỵ", valueReplace: "Ỷ" },
        { value: "Ỹ", valueReplace: "Ỷ" },

        { value: "i", valueReplace: "ỉ" },
        { value: "í", valueReplace: "ỉ" },
        { value: "ì", valueReplace: "ỉ" },
        { value: "ị", valueReplace: "ỉ" },
        { value: "ĩ", valueReplace: "ỉ" },

        { value: "I", valueReplace: "Ỉ" },
        { value: "Í", valueReplace: "Ỉ" },
        { value: "Ì", valueReplace: "Ỉ" },
        { value: "Ị", valueReplace: "Ỉ" },
        { value: "Ĩ", valueReplace: "Ỉ" },

    ],
    "o": [
        { value: "o", valueReplace: "ô" },
        { value: "ó", valueReplace: "ố" },
        { value: "ò", valueReplace: "ồ" },
        { value: "ỏ", valueReplace: "ổ" },
        { value: "õ", valueReplace: "ỗ" },
        { value: "ọ", valueReplace: "ộ" },
        { value: "O", valueReplace: "Ô" },
        { value: "Ó", valueReplace: "Ố" },
        { value: "Ò", valueReplace: "Ồ" },
        { value: "Ỏ", valueReplace: "Ổ" },
        { value: "Õ", valueReplace: "Ỗ" },
        { value: "Ọ", valueReplace: "Ộ" },
    ],
    "a": [
        { value: "a", valueReplace: "â" },
        { value: "á", valueReplace: "ấ" },
        { value: "à", valueReplace: "ầ" },
        { value: "ả", valueReplace: "ẩ" },
        { value: "ã", valueReplace: "ẫ" },
        { value: "ạ", valueReplace: "ậ" },
        { value: "A", valueReplace: "Â" },
        { value: "Á", valueReplace: "Ấ" },
        { value: "À", valueReplace: "Ầ" },
        { value: "Ả", valueReplace: "Ẩ" },
        { value: "Ã", valueReplace: "Ẫ" },
        { value: "Ạ", valueReplace: "Ậ" },
    ],
    "s": [
        { value: "a", valueReplace: "á" },
        { value: "à", valueReplace: "á" },
        { value: "ã", valueReplace: "á" },
        { value: "ạ", valueReplace: "á" },
        { value: "ả", valueReplace: "á" },

        { value: "A", valueReplace: "Á" },
        { value: "À", valueReplace: "Á" },
        { value: "Ã", valueReplace: "Á" },
        { value: "Ạ", valueReplace: "Á" },
        { value: "Ả", valueReplace: "Á" },

        { value: "â", valueReplace: "ấ" },
        { value: "ầ", valueReplace: "ấ" },
        { value: "ẫ", valueReplace: "ấ" },
        { value: "ậ", valueReplace: "ấ" },
        { value: "ẩ", valueReplace: "ấ" },

        { value: "Â", valueReplace: "Ấ" },
        { value: "Ầ", valueReplace: "Ấ" },
        { value: "Ẫ", valueReplace: "Ấ" },
        { value: "Ậ", valueReplace: "Ấ" },
        { value: "Ẩ", valueReplace: "Ấ" },

        { value: "ă", valueReplace: "ắ" },
        { value: "ằ", valueReplace: "ắ" },
        { value: "ẵ", valueReplace: "ắ" },
        { value: "ặ", valueReplace: "ắ" },
        { value: "ẳ", valueReplace: "ắ" },

        { value: "Ă", valueReplace: "Ắ" },
        { value: "Ằ", valueReplace: "Ắ" },
        { value: "Ẵ", valueReplace: "Ắ" },
        { value: "Ặ", valueReplace: "Ắ" },
        { value: "Ẳ", valueReplace: "Ắ" },

        { value: "uê", valueReplace: "uế" },
        { value: "uề", valueReplace: "uế" },
        { value: "uễ", valueReplace: "uế" },
        { value: "uệ", valueReplace: "uế" },
        { value: "uể", valueReplace: "uế" },

        { value: "e", valueReplace: "é" },
        { value: "è", valueReplace: "é" },
        { value: "ẽ", valueReplace: "é" },
        { value: "ẹ", valueReplace: "é" },
        { value: "ẻ", valueReplace: "é" },

        { value: "E", valueReplace: "É" },
        { value: "È", valueReplace: "É" },
        { value: "Ẽ", valueReplace: "É" },
        { value: "Ẹ", valueReplace: "É" },
        { value: "Ẻ", valueReplace: "É" },

        { value: "ê", valueReplace: "ế" },
        { value: "ề", valueReplace: "ế" },
        { value: "ễ", valueReplace: "ế" },
        { value: "ệ", valueReplace: "ế" },
        { value: "ể", valueReplace: "ế" },

        { value: "Ê", valueReplace: "Ế" },
        { value: "Ề", valueReplace: "Ế" },
        { value: "Ễ", valueReplace: "Ế" },
        { value: "Ệ", valueReplace: "Ế" },
        { value: "Ể", valueReplace: "Ế" },

        { value: "o", valueReplace: "ó" },
        { value: "ò", valueReplace: "ó" },
        { value: "õ", valueReplace: "ó" },
        { value: "ọ", valueReplace: "ó" },
        { value: "ỏ", valueReplace: "ó" },

        { value: "O", valueReplace: "Ó" },
        { value: "Ò", valueReplace: "Ó" },
        { value: "Õ", valueReplace: "Ó" },
        { value: "Ọ", valueReplace: "Ó" },
        { value: "Ỏ", valueReplace: "Ó" },

        { value: "ô", valueReplace: "ố" },
        { value: "ồ", valueReplace: "ố" },
        { value: "ỗ", valueReplace: "ố" },
        { value: "ộ", valueReplace: "ố" },
        { value: "ổ", valueReplace: "ố" },

        { value: "Ô", valueReplace: "Ố" },
        { value: "Ồ", valueReplace: "Ố" },
        { value: "Ỗ", valueReplace: "Ố" },
        { value: "Ộ", valueReplace: "Ố" },
        { value: "Ổ", valueReplace: "Ố" },

        { value: "ơ", valueReplace: "ớ" },
        { value: "ờ", valueReplace: "ớ" },
        { value: "ỡ", valueReplace: "ớ" },
        { value: "ợ", valueReplace: "ớ" },
        { value: "ở", valueReplace: "ớ" },

        { value: "Ơ", valueReplace: "Ớ" },
        { value: "Ờ", valueReplace: "Ớ" },
        { value: "Ỡ", valueReplace: "Ớ" },
        { value: "Ợ", valueReplace: "Ớ" },
        { value: "Ở", valueReplace: "Ớ" },

        { value: "u", valueReplace: "ú" },
        { value: "ù", valueReplace: "ú" },
        { value: "ũ", valueReplace: "ú" },
        { value: "ụ", valueReplace: "ú" },
        { value: "ủ", valueReplace: "ú" },

        { value: "U", valueReplace: "Ú" },
        { value: "Ù", valueReplace: "Ú" },
        { value: "Ũ", valueReplace: "Ú" },
        { value: "Ụ", valueReplace: "Ú" },
        { value: "Ủ", valueReplace: "Ú" },

        { value: "ư", valueReplace: "ứ" },
        { value: "ừ", valueReplace: "ứ" },
        { value: "ữ", valueReplace: "ứ" },
        { value: "ự", valueReplace: "ứ" },
        { value: "ử", valueReplace: "ứ" },

        { value: "Ư", valueReplace: "Ứ" },
        { value: "Ừ", valueReplace: "Ứ" },
        { value: "Ữ", valueReplace: "Ứ" },
        { value: "Ự", valueReplace: "Ứ" },
        { value: "Ử", valueReplace: "Ứ" },

        { value: "y", valueReplace: "ý" },
        { value: "ỳ", valueReplace: "ý" },
        { value: "ỹ", valueReplace: "ý" },
        { value: "ỵ", valueReplace: "ý" },
        { value: "ỷ", valueReplace: "ý" },

        { value: "Y", valueReplace: "Ý" },
        { value: "Ỳ", valueReplace: "Ý" },
        { value: "Ỹ", valueReplace: "Ý" },
        { value: "Ỵ", valueReplace: "Ý" },
        { value: "Ỷ", valueReplace: "Ý" },

        { value: "i", valueReplace: "í" },
        { value: "ì", valueReplace: "í" },
        { value: "ĩ", valueReplace: "í" },
        { value: "ị", valueReplace: "í" },
        { value: "ỉ", valueReplace: "í" },

        { value: "I", valueReplace: "Í" },
        { value: "Ì", valueReplace: "Í" },
        { value: "Ĩ", valueReplace: "Í" },
        { value: "Ị", valueReplace: "Í" },
        { value: "Ỉ", valueReplace: "Í" },

    ],
    "d": [
        { value: "d", valueReplace: "đ" },
        { value: "D", valueReplace: "Đ" }
    ],
    "f": [
        { value: "a", valueReplace: "à" },
        { value: "á", valueReplace: "à" },
        { value: "ã", valueReplace: "à" },
        { value: "ạ", valueReplace: "à" },
        { value: "ả", valueReplace: "à" },

        { value: "A", valueReplace: "À" },
        { value: "Á", valueReplace: "À" },
        { value: "Ã", valueReplace: "À" },
        { value: "Ạ", valueReplace: "À" },
        { value: "Ả", valueReplace: "À" },

        { value: "â", valueReplace: "ầ" },
        { value: "ấ", valueReplace: "ầ" },
        { value: "ẫ", valueReplace: "ầ" },
        { value: "ậ", valueReplace: "ầ" },
        { value: "ẩ", valueReplace: "ầ" },

        { value: "Â", valueReplace: "Ầ" },
        { value: "Ấ", valueReplace: "Ầ" },
        { value: "Ẫ", valueReplace: "Ầ" },
        { value: "Ậ", valueReplace: "Ầ" },
        { value: "Ẩ", valueReplace: "Ầ" },

        { value: "ă", valueReplace: "ằ" },
        { value: "ắ", valueReplace: "ằ" },
        { value: "ẵ", valueReplace: "ằ" },
        { value: "ặ", valueReplace: "ằ" },
        { value: "ẳ", valueReplace: "ằ" },

        { value: "Ă", valueReplace: "Ằ" },
        { value: "Ắ", valueReplace: "Ằ" },
        { value: "Ẵ", valueReplace: "Ằ" },
        { value: "Ặ", valueReplace: "Ằ" },
        { value: "Ẳ", valueReplace: "Ằ" },

        { value: "e", valueReplace: "è" },
        { value: "é", valueReplace: "è" },
        { value: "ẽ", valueReplace: "è" },
        { value: "ẹ", valueReplace: "è" },
        { value: "ẻ", valueReplace: "è" },

        { value: "E", valueReplace: "È" },
        { value: "É", valueReplace: "È" },
        { value: "Ẽ", valueReplace: "È" },
        { value: "Ẹ", valueReplace: "È" },
        { value: "Ẻ", valueReplace: "È" },

        { value: "ê", valueReplace: "ề" },
        { value: "ế", valueReplace: "ề" },
        { value: "ễ", valueReplace: "ề" },
        { value: "ệ", valueReplace: "ề" },
        { value: "ể", valueReplace: "ề" },

        { value: "Ê", valueReplace: "Ề" },
        { value: "Ế", valueReplace: "Ề" },
        { value: "Ễ", valueReplace: "Ề" },
        { value: "Ệ", valueReplace: "Ề" },
        { value: "Ể", valueReplace: "Ề" },

        { value: "o", valueReplace: "ò" },
        { value: "ó", valueReplace: "ò" },
        { value: "õ", valueReplace: "ò" },
        { value: "ọ", valueReplace: "ò" },
        { value: "ỏ", valueReplace: "ò" },

        { value: "O", valueReplace: "Ò" },
        { value: "Ó", valueReplace: "Ò" },
        { value: "Õ", valueReplace: "Ò" },
        { value: "Ọ", valueReplace: "Ò" },
        { value: "Ỏ", valueReplace: "Ò" },

        { value: "ô", valueReplace: "ồ" },
        { value: "ố", valueReplace: "ồ" },
        { value: "ỗ", valueReplace: "ồ" },
        { value: "ộ", valueReplace: "ồ" },
        { value: "ổ", valueReplace: "ồ" },

        { value: "Ô", valueReplace: "Ồ" },
        { value: "Ố", valueReplace: "Ồ" },
        { value: "Ỗ", valueReplace: "Ồ" },
        { value: "Ộ", valueReplace: "Ồ" },
        { value: "Ổ", valueReplace: "Ồ" },

        { value: "ơ", valueReplace: "ờ" },
        { value: "ớ", valueReplace: "ờ" },
        { value: "ỡ", valueReplace: "ờ" },
        { value: "ợ", valueReplace: "ờ" },
        { value: "ở", valueReplace: "ờ" },

        { value: "Ơ", valueReplace: "Ờ" },
        { value: "Ớ", valueReplace: "Ờ" },
        { value: "Ỡ", valueReplace: "Ờ" },
        { value: "Ợ", valueReplace: "Ờ" },
        { value: "Ở", valueReplace: "Ờ" },

        { value: "u", valueReplace: "ù" },
        { value: "ú", valueReplace: "ù" },
        { value: "ũ", valueReplace: "ù" },
        { value: "ụ", valueReplace: "ù" },
        { value: "ủ", valueReplace: "ù" },

        { value: "U", valueReplace: "Ù" },
        { value: "Ú", valueReplace: "Ù" },
        { value: "Ũ", valueReplace: "Ù" },
        { value: "Ụ", valueReplace: "Ù" },
        { value: "Ủ", valueReplace: "Ù" },

        { value: "ư", valueReplace: "ừ" },
        { value: "ứ", valueReplace: "ừ" },
        { value: "ữ", valueReplace: "ừ" },
        { value: "ự", valueReplace: "ừ" },
        { value: "ử", valueReplace: "ừ" },

        { value: "Ư", valueReplace: "Ừ" },
        { value: "Ứ", valueReplace: "Ừ" },
        { value: "Ữ", valueReplace: "Ừ" },
        { value: "Ự", valueReplace: "Ừ" },
        { value: "Ử", valueReplace: "Ừ" },

        { value: "y", valueReplace: "ỳ" },
        { value: "ý", valueReplace: "ỳ" },
        { value: "ỹ", valueReplace: "ỳ" },
        { value: "ỵ", valueReplace: "ỳ" },
        { value: "ỷ", valueReplace: "ỳ" },

        { value: "Y", valueReplace: "Ỳ" },
        { value: "Ý", valueReplace: "Ỳ" },
        { value: "Ỹ", valueReplace: "Ỳ" },
        { value: "Ỵ", valueReplace: "Ỳ" },
        { value: "Ỷ", valueReplace: "Ỳ" },

        { value: "i", valueReplace: "ì" },
        { value: "í", valueReplace: "ì" },
        { value: "ĩ", valueReplace: "ì" },
        { value: "ị", valueReplace: "ì" },
        { value: "ỉ", valueReplace: "ì" },

        { value: "I", valueReplace: "Ì" },
        { value: "Í", valueReplace: "Ì" },
        { value: "Ĩ", valueReplace: "Ì" },
        { value: "Ị", valueReplace: "Ì" },
        { value: "Ỉ", valueReplace: "Ì" },

    ],
    "j": [
        { value: "a", valueReplace: "ạ" },
        { value: "á", valueReplace: "ạ" },
        { value: "à", valueReplace: "ạ" },
        { value: "ã", valueReplace: "ạ" },
        { value: "ả", valueReplace: "a" },

        { value: "A", valueReplace: "Ạ" },
        { value: "Á", valueReplace: "Ạ" },
        { value: "À", valueReplace: "Ạ" },
        { value: "Ã", valueReplace: "Ạ" },
        { value: "Ả", valueReplace: "A" },

        { value: "â", valueReplace: "ậ" },
        { value: "ấ", valueReplace: "ậ" },
        { value: "ầ", valueReplace: "ậ" },
        { value: "ẫ", valueReplace: "ậ" },
        { value: "ẩ", valueReplace: "â" },

        { value: "Â", valueReplace: "Ậ" },
        { value: "Ấ", valueReplace: "Ậ" },
        { value: "Ầ", valueReplace: "Ậ" },
        { value: "Ẫ", valueReplace: "Ậ" },
        { value: "Ẩ", valueReplace: "Â" },

        { value: "ă", valueReplace: "ặ" },
        { value: "ắ", valueReplace: "ặ" },
        { value: "ằ", valueReplace: "ặ" },
        { value: "ẵ", valueReplace: "ặ" },
        { value: "ẳ", valueReplace: "ă" },

        { value: "Ă", valueReplace: "Ặ" },
        { value: "Ắ", valueReplace: "Ặ" },
        { value: "Ằ", valueReplace: "Ặ" },
        { value: "Ẵ", valueReplace: "Ặ" },
        { value: "Ẳ", valueReplace: "Ă" },

        { value: "e", valueReplace: "ẹ" },
        { value: "é", valueReplace: "ẹ" },
        { value: "è", valueReplace: "ẹ" },
        { value: "ẽ", valueReplace: "ẹ" },
        { value: "ẻ", valueReplace: "e" },

        { value: "E", valueReplace: "Ẹ" },
        { value: "É", valueReplace: "Ẹ" },
        { value: "È", valueReplace: "Ẹ" },
        { value: "Ẽ", valueReplace: "Ẹ" },
        { value: "Ẻ", valueReplace: "E" },

        { value: "ê", valueReplace: "ệ" },
        { value: "ế", valueReplace: "ệ" },
        { value: "ề", valueReplace: "ệ" },
        { value: "ễ", valueReplace: "ệ" },
        { value: "ể", valueReplace: "ê" },

        { value: "Ê", valueReplace: "Ệ" },
        { value: "Ế", valueReplace: "Ệ" },
        { value: "Ề", valueReplace: "Ệ" },
        { value: "Ễ", valueReplace: "Ệ" },
        { value: "Ể", valueReplace: "Ê" },

        { value: "o", valueReplace: "ọ" },
        { value: "ó", valueReplace: "ọ" },
        { value: "ò", valueReplace: "ọ" },
        { value: "õ", valueReplace: "ọ" },
        { value: "ỏ", valueReplace: "o" },

        { value: "O", valueReplace: "Ọ" },
        { value: "Ó", valueReplace: "Ọ" },
        { value: "Ò", valueReplace: "Ọ" },
        { value: "Õ", valueReplace: "Ọ" },
        { value: "Ỏ", valueReplace: "O" },

        { value: "ô", valueReplace: "ộ" },
        { value: "ố", valueReplace: "ộ" },
        { value: "ồ", valueReplace: "ộ" },
        { value: "ỗ", valueReplace: "ộ" },
        { value: "ổ", valueReplace: "ô" },

        { value: "Ô", valueReplace: "Ộ" },
        { value: "Ố", valueReplace: "Ộ" },
        { value: "Ồ", valueReplace: "Ộ" },
        { value: "Ỗ", valueReplace: "Ộ" },
        { value: "Ổ", valueReplace: "Ô" },

        { value: "ơ", valueReplace: "ợ" },
        { value: "ớ", valueReplace: "ợ" },
        { value: "ờ", valueReplace: "ợ" },
        { value: "ỡ", valueReplace: "ợ" },
        { value: "ở", valueReplace: "ơ" },

        { value: "Ơ", valueReplace: "Ợ" },
        { value: "Ớ", valueReplace: "Ợ" },
        { value: "Ờ", valueReplace: "Ợ" },
        { value: "Ỡ", valueReplace: "Ợ" },
        { value: "Ở", valueReplace: "Ơ" },

        { value: "u", valueReplace: "ụ" },
        { value: "ú", valueReplace: "ụ" },
        { value: "ù", valueReplace: "ụ" },
        { value: "ũ", valueReplace: "ụ" },
        { value: "ủ", valueReplace: "u" },

        { value: "U", valueReplace: "Ụ" },
        { value: "Ú", valueReplace: "Ụ" },
        { value: "Ù", valueReplace: "Ụ" },
        { value: "Ũ", valueReplace: "Ụ" },
        { value: "Ủ", valueReplace: "U" },

        { value: "ư", valueReplace: "ự" },
        { value: "ứ", valueReplace: "ự" },
        { value: "ừ", valueReplace: "ự" },
        { value: "ữ", valueReplace: "ự" },
        { value: "ử", valueReplace: "ư" },

        { value: "Ư", valueReplace: "Ự" },
        { value: "Ứ", valueReplace: "Ự" },
        { value: "Ừ", valueReplace: "Ự" },
        { value: "Ữ", valueReplace: "Ự" },
        { value: "Ử", valueReplace: "Ư" },

        { value: "y", valueReplace: "ỵ" },
        { value: "ý", valueReplace: "ỵ" },
        { value: "ỳ", valueReplace: "ỵ" },
        { value: "ỹ", valueReplace: "ỵ" },
        { value: "ỷ", valueReplace: "y" },

        { value: "Y", valueReplace: "Ỵ" },
        { value: "Ý", valueReplace: "Ỵ" },
        { value: "Ỳ", valueReplace: "Ỵ" },
        { value: "Ỹ", valueReplace: "Ỵ" },
        { value: "Ỷ", valueReplace: "Y" },

        { value: "i", valueReplace: "ị" },
        { value: "í", valueReplace: "ị" },
        { value: "ì", valueReplace: "ị" },
        { value: "ĩ", valueReplace: "ị" },
        { value: "ỉ", valueReplace: "i" },

        { value: "I", valueReplace: "Ị" },
        { value: "Í", valueReplace: "Ị" },
        { value: "Ì", valueReplace: "Ị" },
        { value: "Ĩ", valueReplace: "Ị" },
        { value: "Ỉ", valueReplace: "I" },

    ],
    "x": [

        { value: "a", valueReplace: "ã" },
        { value: "á", valueReplace: "ã" },
        { value: "à", valueReplace: "ã" },
        { value: "ạ", valueReplace: "ã" },
        { value: "ả", valueReplace: "ã" },

        { value: "A", valueReplace: "Ã" },
        { value: "Á", valueReplace: "Ã" },
        { value: "À", valueReplace: "Ã" },
        { value: "Ạ", valueReplace: "Ã" },
        { value: "Ả", valueReplace: "Ã" },

        { value: "â", valueReplace: "ẫ" },
        { value: "ấ", valueReplace: "ẫ" },
        { value: "ầ", valueReplace: "ẫ" },
        { value: "ậ", valueReplace: "ẫ" },
        { value: "ẩ", valueReplace: "ẫ" },

        { value: "Â", valueReplace: "Ẫ" },
        { value: "Ấ", valueReplace: "Ẫ" },
        { value: "Ầ", valueReplace: "Ẫ" },
        { value: "Ậ", valueReplace: "Ẫ" },
        { value: "Ẩ", valueReplace: "Ẫ" },

        { value: "ă", valueReplace: "ẵ" },
        { value: "ắ", valueReplace: "ẵ" },
        { value: "ằ", valueReplace: "ẵ" },
        { value: "ặ", valueReplace: "ẵ" },
        { value: "ẳ", valueReplace: "ẵ" },

        { value: "Ă", valueReplace: "Ẵ" },
        { value: "Ắ", valueReplace: "Ẵ" },
        { value: "Ằ", valueReplace: "Ẵ" },
        { value: "Ặ", valueReplace: "Ẵ" },
        { value: "Ẳ", valueReplace: "Ẵ" },

        { value: "e", valueReplace: "ẽ" },
        { value: "é", valueReplace: "ẽ" },
        { value: "è", valueReplace: "ẽ" },
        { value: "ẹ", valueReplace: "ẽ" },
        { value: "ẻ", valueReplace: "ẽ" },

        { value: "E", valueReplace: "Ẽ" },
        { value: "É", valueReplace: "Ẽ" },
        { value: "È", valueReplace: "Ẽ" },
        { value: "Ẹ", valueReplace: "Ẽ" },
        { value: "Ẻ", valueReplace: "Ẽ" },

        { value: "ê", valueReplace: "ễ" },
        { value: "ế", valueReplace: "ễ" },
        { value: "ề", valueReplace: "ễ" },
        { value: "ệ", valueReplace: "ễ" },
        { value: "ể", valueReplace: "ễ" },

        { value: "Ê", valueReplace: "Ễ" },
        { value: "Ế", valueReplace: "Ễ" },
        { value: "Ề", valueReplace: "Ễ" },
        { value: "Ệ", valueReplace: "Ễ" },
        { value: "Ể", valueReplace: "Ễ" },

        { value: "oa", valueReplace: "õa" },
        { value: "óa", valueReplace: "õa" },
        { value: "òa", valueReplace: "õa" },
        { value: "ọa", valueReplace: "õa" },
        { value: "ỏa", valueReplace: "õa" },

        { value: "OA", valueReplace: "ÕA" },
        { value: "ÓA", valueReplace: "ÕA" },
        { value: "ÒA", valueReplace: "ÕA" },
        { value: "ỌA", valueReplace: "ÕA" },
        { value: "ỎA", valueReplace: "ÕA" },

        { value: "o", valueReplace: "õ" },
        { value: "ó", valueReplace: "õ" },
        { value: "ò", valueReplace: "õ" },
        { value: "ọ", valueReplace: "õ" },
        { value: "ỏ", valueReplace: "õ" },

        { value: "O", valueReplace: "Õ" },
        { value: "Ó", valueReplace: "Õ" },
        { value: "Ò", valueReplace: "Õ" },
        { value: "Ọ", valueReplace: "Õ" },
        { value: "Ỏ", valueReplace: "Õ" },

        { value: "ô", valueReplace: "ỗ" },
        { value: "ố", valueReplace: "ỗ" },
        { value: "ồ", valueReplace: "ỗ" },
        { value: "ộ", valueReplace: "ỗ" },
        { value: "ổ", valueReplace: "ỗ" },

        { value: "Ô", valueReplace: "Ỗ" },
        { value: "Ố", valueReplace: "Ỗ" },
        { value: "Ồ", valueReplace: "Ỗ" },
        { value: "Ộ", valueReplace: "Ỗ" },
        { value: "Ổ", valueReplace: "Ỗ" },

        { value: "ơ", valueReplace: "ỡ" },
        { value: "ớ", valueReplace: "ỡ" },
        { value: "ờ", valueReplace: "ỡ" },
        { value: "ợ", valueReplace: "ỡ" },
        { value: "ở", valueReplace: "ỡ" },

        { value: "Ơ", valueReplace: "Ỡ" },
        { value: "Ớ", valueReplace: "Ỡ" },
        { value: "Ờ", valueReplace: "Ỡ" },
        { value: "Ợ", valueReplace: "Ỡ" },
        { value: "Ở", valueReplace: "Ỡ" },

        { value: "ưu", valueReplace: "ữu" },
        { value: "ứu", valueReplace: "ữu" },
        { value: "ừu", valueReplace: "ữu" },
        { value: "ựu", valueReplace: "ữu" },
        { value: "ửu", valueReplace: "ữu" },

        { value: "ƯU", valueReplace: "ỮU" },
        { value: "ỨU", valueReplace: "ỮU" },
        { value: "ỪU", valueReplace: "ỮU" },
        { value: "ỰU", valueReplace: "ỮU" },
        { value: "ỬU", valueReplace: "ỮU" },

        { value: "u", valueReplace: "ũ" },
        { value: "ú", valueReplace: "ũ" },
        { value: "ù", valueReplace: "ũ" },
        { value: "ụ", valueReplace: "ũ" },
        { value: "ủ", valueReplace: "ũ" },

        { value: "U", valueReplace: "Ũ" },
        { value: "Ú", valueReplace: "Ũ" },
        { value: "Ù", valueReplace: "Ũ" },
        { value: "Ụ", valueReplace: "Ũ" },
        { value: "Ủ", valueReplace: "Ũ" },

        { value: "ư", valueReplace: "ữ" },
        { value: "ứ", valueReplace: "ữ" },
        { value: "ừ", valueReplace: "ữ" },
        { value: "ự", valueReplace: "ữ" },
        { value: "ử", valueReplace: "ữ" },

        { value: "Ư", valueReplace: "Ữ" },
        { value: "Ứ", valueReplace: "Ữ" },
        { value: "Ừ", valueReplace: "Ữ" },
        { value: "Ự", valueReplace: "Ữ" },
        { value: "Ử", valueReplace: "Ữ" },

        { value: "y", valueReplace: "ỹ" },
        { value: "ý", valueReplace: "ỹ" },
        { value: "ỳ", valueReplace: "ỹ" },
        { value: "ỵ", valueReplace: "ỹ" },
        { value: "ỷ", valueReplace: "ỹ" },

        { value: "Y", valueReplace: "Ỹ" },
        { value: "Ý", valueReplace: "Ỹ" },
        { value: "Ỳ", valueReplace: "Ỹ" },
        { value: "Ỵ", valueReplace: "Ỹ" },
        { value: "Ỷ", valueReplace: "Ỹ" },

        { value: "i", valueReplace: "ĩ" },
        { value: "í", valueReplace: "ĩ" },
        { value: "ì", valueReplace: "ĩ" },
        { value: "ị", valueReplace: "ĩ" },
        { value: "ỉ", valueReplace: "ĩ" },

        { value: "I", valueReplace: "Ĩ" },
        { value: "Í", valueReplace: "Ĩ" },
        { value: "Ì", valueReplace: "Ĩ" },
        { value: "Ị", valueReplace: "Ĩ" },
        { value: "Ỉ", valueReplace: "Ĩ" },

    ]
}