import { styled } from "styled-components";
import bg from '../../assets/img/adv_01.png'

export const WrapMainBackground = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
`