import React from 'react'

const IcWITHDRAW = (props) => {
    return (
        <svg {...props} width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7438 12.2306C28.0856 6.58981 35.9144 6.58981 38.2562 12.2306L39.8873 16.1595C40.8463 18.4696 42.9519 20.071 45.394 20.3478L49.9457 20.8636C55.6941 21.515 58.0683 28.7347 53.8701 32.7969L50.0054 36.5364C48.3062 38.1806 47.5554 40.6021 48.0183 42.9453L48.9514 47.6689C50.1325 53.648 43.7271 58.1623 38.6715 54.9139L35.6189 52.9525C33.407 51.5312 30.593 51.5312 28.3811 52.9525L25.3285 54.9139C20.2729 58.1623 13.8675 53.648 15.0486 47.6689L15.9817 42.9453C16.4446 40.6021 15.6938 38.1806 13.9946 36.5364L10.1299 32.7969C5.93168 28.7347 8.30591 21.515 14.0542 20.8636L18.606 20.3478C21.0481 20.071 23.1537 18.4696 24.1127 16.1595L25.7438 12.2306Z" stroke="#AB1F24" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.3811 52.9525L25.3285 54.9139C20.2729 58.1623 13.8675 53.648 15.0486 47.6689L15.9817 42.9453C16.4446 40.6021 15.6938 38.1806 13.9946 36.5364L10.1299 32.7969C5.93168 28.7347 8.30591 21.515 14.0542 20.8636L18.606 20.3478C21.0481 20.071 23.1537 18.4696 24.1127 16.1595L25.7438 12.2306C28.0856 6.58981 35.9144 6.58981 38.2562 12.2306L39.8873 16.1595C40.8463 18.4696 42.9519 20.071 45.394 20.3478L49.9458 20.8636C55.6941 21.515 58.0683 28.7347 53.8701 32.7969L50.0054 36.5364C48.3062 38.1806 47.5554 40.6021 48.0183 42.9453" stroke="#00783A" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcWITHDRAW