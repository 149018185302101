import { styled } from "styled-components";
import { ConstData } from "../../assets/constants/constants";

import bg from '../../assets/img/bg.png'

const PageBg = styled.div`
    width: ${ConstData.screen.Width};
    height: ${ConstData.screen.Height};
    display: flex;
    flex-direction: column;
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
`;

export default PageBg