import { styled } from "styled-components";
import PageBg from "../../components/PageBg/index.styled";

const Wrap = styled(PageBg)``;

const WrapScanCCCD = styled.div`  
  width: 1080px;
  height: calc(1920px - 160px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    width: 100%;
    height: calc(100% - 405px);
    &-top {
      height: 837px;
      background: #303030;
      display: flex;
      align-items: center;
      justify-content: center;
      .animation {       
        svg {
          width: 440px !important;
          height: 440px !important;
        }
      }
      .wrap-btn{               
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 64px;
          background: unset;
          .btn-button{
              width: 560px;
              height: 108px;
              border-radius: 24px;
              background: #007822
          }
      }
    }
    &-bottom{
      height: 510px;
      gap: 72px;
      margin-top: 64px;
      padding: 0 198px;
      display: flex;
      flex-direction: column;
      align-items: center;     
      text-align: center;
      font-family: SVN-Gilroy;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: #FFFFFF;
      .icon-arrow{
        height: 240px;
        width: 240px;
      }
    }
  }
  .footer{
    width: 100%;
    height: 405px;
  }
`;

export { WrapScanCCCD, Wrap };
