import React, { useEffect, useRef, useState } from "react";
import OverlayFullScreen from "../OverlayFullScreen";
import { WrapPopupComfirm } from "./index.styled";
import IcCheck from '../../assets/icon/IcCheck';
import IcEnd from '../../assets/icon/IcEnd';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import commonSlice from "../../redux/common/common.slice";
import { ConstData } from "../../assets/constants/constants";

const PopupEndTransaction = ({ title, desc, timeWaringDefault , isShowTimeWarning, onEnd, onSubmit}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const countDownTime = useRef(timeWaringDefault);
  const [warningTime, setWarningTime] = useState(timeWaringDefault);

  const handleSubmit = (e) => {
    if(onSubmit){
      onSubmit();
    }
    e.preventDefault();
  };  

  const handleEnd = () => {
    if(onEnd){
      onEnd();
    }
  };

  var countDownDelayFunc = null;

  const countDownAction = () => {
    if(countDownTime.current === 0){
      countDownTime.current = timeWaringDefault;
      setTimeout(() =>{
        dispatch(commonSlice.actions.setIsWarningEndTrans(false))
        navigate(ConstData.routePath.MainPage)
      }, 200)
    } else {
      countDownDelayFunc = setTimeout(() =>{
        countDownTime.current = countDownTime.current - 1;
        setWarningTime(countDownTime.current)
      }, 1000)
    }
  }

  useEffect(()=>{
      if(countDownDelayFunc == null){
        countDownAction()
      }
      //---
      return () => {
          if(countDownDelayFunc!==null){
              clearTimeout(countDownDelayFunc)
          }
      }
  })

  return (
    <OverlayFullScreen>
      <WrapPopupComfirm>
        <div className="title">{title}</div>
        <div className="desc">{desc}</div>
        <div className="btn" >
          <div className="btn-end" onClick={handleEnd}>
            <IcEnd />
            Kết thúc
          </div>
          <div className="btn-next" onClick={handleSubmit}>
            <IcCheck />
            Tiếp tục
          </div>
        </div>
        {
          isShowTimeWarning && <div className="warning">
            Phiên giao dịch sẽ kết thúc sau: {warningTime}s
          </div>
        }
      </WrapPopupComfirm>
    </OverlayFullScreen>
  );
};

export default PopupEndTransaction;
