import styled from 'styled-components'

const WrapNumberVirtualKeyboard = styled.div`
    position: absolute;
    left: 0;
    top: calc(100% + 7px);
    padding: 10px;
    background: #171717;
    box-shadow: 0px 4.08511px 4.08511px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    z-index: 9;
    button {
        border-radius: 17px;
        border: 1px solid transparent;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 25px;
        background-color: inherit;
        cursor: pointer;
        transition: border-color 0.25s;
        margin: 0 3px;
        height: 60px;
        min-width: 60px;
        padding: 0 1.2em;
        color: #fff;
    }
    button:active {
        background: rgba(0, 120, 34, 0.3);
    }
    .bound{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.number {
            width: 432px;
        }
    }
    .line{
        margin: 3px 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        #key0 {
            grid-column: 2/3;
        }
    }
`;

export {
    WrapNumberVirtualKeyboard
}