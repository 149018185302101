import { createSlice } from "@reduxjs/toolkit";
import { ConstKey } from "../../assets/constants/constants";
import { CommonAction } from "./common.action";

const connectDeviceAction = (state, action) => {
    // return initialState
    console.log("connectDeviceAction action")
}

const initialState = {
    isLoading: false,
    isNetworkError: false,
    isSytemError: false,
    isWarningEndTrans: false,
    issueTicketResult: {
        isErr: false,
        messageErr: ""
    },
    isEndOfSession: false,
    is403Error: false,
    deviceInfo: JSON.parse(localStorage.getItem(ConstKey.DEVICE_INFO)) || null,
    faceData: JSON.parse(localStorage.getItem(ConstKey.FACE_INFO)) || null,
    stepScanChip: 1,
    typeConnectMK: "",
    idCardNo: "",
    isOcrSucceeded: false
}

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        resetCommon: (state) => {
            state.isLoading = false
            state.isNetworkError = false
            state.isSytemError = false
            state.isWarningEndTrans = false
            state.issueTicketResult = {
                isErr: false,
                messageErr: ""
            }
            state.isEndOfSession = false
            state.is403Error = false
            state.faceData = JSON.parse(localStorage.getItem(ConstKey.FACE_INFO)) || null
            state.stepScanChip = 1
            state.typeConnectMK = ""
            state.idCardNo = ""
            state.isOcrSucceeded = false
        },
        setIsDisconnectNetworkError: (state, action) => {
            state.isNetworkError = action.payload
            state.isLoading = false
        },
        setIsSystemError: (state, action) => {
            state.isSytemError = action.payload
            state.isLoading = false
        },
        setIs403Error: (state, action) => {
            state.is403Error = action.payload
            state.isLoading = false
        },
        setIssueTicketResult: (state, action) => {
            state.issueTicketResult = action.payload
            state.isLoading = false
        },
        connectDeviceCompleted: (state, action) => {
            state.deviceInfo = action.payload
            state.isLoading = false
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setIsWarningEndTrans: (state, action) => {
            state.isWarningEndTrans = action.payload
            state.isLoading = false
        },
        setIsEndOfSession: (state, action) => {
            state.isEndOfSession = action.payload
            state.isLoading = false
        },
        setStepScanChip: (state, action) => {
            state.stepScanChip = action.payload;
        },
        setTypeConnectMK: (state, action) => {
            state.typeConnectMK = action.payload;
        },
        setIdCardNo: (state, action) => {
            state.idCardNo = action.payload;
        },
        setIsOcrSucceeded: (state, action) => {
            state.isOcrSucceeded = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(CommonAction.mockConnectDeviceAction.fulfilled, connectDeviceAction)
        // .addCase(CommonAction.getPrintReceiptAction.fulfilled, (state, action) => { });
    },
})

export default commonSlice