/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import scanFingerSlice from "../redux/scanFinger/scanFinger.slice";
import { ConstData } from "../assets/constants/constants";

const MESSAGE = "MESSAGE";
const DATA = "DATA";
const STATUS = "STATUS";
const START = "START";
const STOP = "STOP";

const useConnectScannerFinger = () => {
    const dispatch = useDispatch();
    const wsRef = useRef(null);
    const timerReconnect = useRef(0);
    const isUnmounted = useRef(false);
    const [wsConnected, setWsConnected] = useState(false);
    const [message, setMessage] = useState("");
    const [fingerData, setFingerData] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)

    const startScanner = () => {
        wsRef.current.send(START);
    };

    const stopScanner = () => {
        wsRef.current.send(STOP);
    };

    const getFingerDataSucceed = (imageFp, templateFp) => {
        const dataFp = {
            base64: imageFp,
            template: templateFp
        }
        setFingerData(dataFp)
        //---
        dispatch(scanFingerSlice.actions.setFingerData(dataFp))
    }

    const connectWs = useCallback(() => {
        setWsConnected(false);
        wsRef.current = new WebSocket(`ws://${ConstData.ipAddressOfDevice}:8887`);
        wsRef.current.onopen = () => {
            console.log("connected");
            setWsConnected(true);
            clearTimeout(timerReconnect.current);
            startScanner()
        };
        wsRef.current.onmessage = async (e) => {
            try {
                const evtData = JSON.parse(e.data);
                const { type } = evtData;
                switch (type) {
                    case MESSAGE: {
                        const { code } = evtData;
                        let message = ""
                        if (code === 11) {
                            message = "Không có vân tay";
                            dispatch(scanFingerSlice.actions.setScanFpStatus(ConstData.scanFingerStatus.noFinger))
                        } else if (code === 10) {
                            message = "Đã có vân tay";
                            dispatch(scanFingerSlice.actions.setScanFpStatus(ConstData.scanFingerStatus.hasFinger))
                        }
                        setMessage(message)
                        break;
                    }
                    case STATUS: {
                        const { code } = evtData;
                        if (code === 20) {
                            setMessage("Kết nối thiết bị thành công")
                        } else if (code === 22) {
                            setMessage("Kết nối thiết bị thất bại")
                        } else if (code === 15) {
                            setMessage("Đang capture vân tay")
                            setIsSuccess(true)
                        } else if (code === 16) {
                            setMessage("Hoàn thành capture vân tay")
                            dispatch(scanFingerSlice.actions.setScanFpStatus(ConstData.scanFingerStatus.done))
                        } else if (code === 21) {
                            setMessage("Ngắt kết nối thiết bị thành công")
                            if (fingerData == null) {
                                startScanner()
                            }
                        }
                        break;
                    }
                    case DATA: {
                        setMessage("Hoàn thành capture vân tay")
                        console.log("DATA => evtData: ", evtData)
                        const { imageFp, templateFp } = evtData
                        if (imageFp) {
                            getFingerDataSucceed(imageFp, templateFp)
                        }
                        break
                    }
                    default: {

                    }
                }
            } catch (err) {
                console.log("cannot parse data", e.data, err);
            }
        };
        wsRef.current.onclose = (e) => {
            // console.log("close", e)
            setWsConnected(false);
            if (isUnmounted.current) return;
            timerReconnect.current = setTimeout(connectWs, 1000);
        };
        wsRef.current.onerror = (e) => {
            console.log("error", e);
        };
    }, []);

    useEffect(() => {
        connectWs();
        //---
        return () => {
            isUnmounted.current = true;
            clearTimeout(timerReconnect.current);
            wsRef.current && wsConnected && stopScanner();
            wsRef.current && wsRef.current.close();
            setWsConnected(false);
            setIsSuccess(false)
        };
    }, []);

    return {
        wsRef,
        connectWs,
        startScanner,
        wsConnected,
        message,
        fingerData,
        setFingerData,
        isSuccess,
        setIsSuccess
    };
};
export default useConnectScannerFinger;
