/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
//css
import { Wrap, WrapInfoBill, WrapIssueTicket } from "./index.styled";
//img
import XuatVe from "../../assets/img/xuat_ve.png";
import lottieTicketLoading from "../../assets/lottie/ticket_loading.json";
//component
import Header from "../Header";
import LottieAnimation from "../../controls/LottieAnimation";
import FormTransactionReceipt from "../FormTransactionReceipt";
//hook
import { ConstData } from "../../assets/constants/constants";
import { removePrefix } from "../../utils/blob";
import { getFileFromBase64 } from "../../utils/base64";
import { CommonAction } from "../../redux/common/common.action";
import { useNavigate } from "react-router-dom";
import { damping, variants } from "../../utils/transition_page";
import { motion } from "framer-motion";
import { HomeAction } from "../../redux/home/home.action";

const IssueTicketInfo = ({ customerInfo, ticketInfo, deviceInfo }) => {
  const refForm = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fileReceipt, setFileReceipt] = useState("");
  const [isInProgress, setIsInProgress] = useState(false);
  //---
  useEffect(() => {
    let timer = setTimeout(() => {
      if (customerInfo && customerInfo.type === ConstData.customerType.VIP) {
        dispatch(HomeAction.onGetTicket({
          deviceInfo: deviceInfo,
          customerInfo: customerInfo,
          serviceList: (ConstData.transTypeList[ConstData.transTypeList.length - 1])
        }))
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [customerInfo, customerInfo?.type])
  //---
  useEffect(() => {
    let timer = setTimeout(() => {
      const input = refForm.current;
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        let base64 = removePrefix(imgData);
        // console.log(base64)
        let byteArray = getFileFromBase64(base64, "receipt");
        console.log("setFileReceipt => file: ", byteArray);
        setFileReceipt(byteArray);
      });
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      console.log("IssueTicketInfo => ticketInfo: ", ticketInfo);
      if ((ticketInfo && ticketInfo.ticketNumber !== "") && Object.values(ticketInfo).length !== 0) {
        printReceipt();
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [fileReceipt, ticketInfo]);

  const printReceipt = () => {
    if (isInProgress) return;
    let timer = setTimeout(() => {
      console.log("printReceipt => file: ", fileReceipt);
      if (fileReceipt) {
        const callBackSuccess = () => {
          // console.log("printReceipt success");
          setTimeout(() => {
            navigate(ConstData.routePath.RatingPage);
          }, 2000);
        };
        const callBackError = () => {
          // console.log("printReceipt error");
          setIsInProgress(false);
        };
        dispatch(
          CommonAction.getPrintReceiptAction({
            file: fileReceipt,
            onSuccess: callBackSuccess,
            onError: callBackError,
          })
        );
      } else {
        console.log("Không có data hóa đơn");
      }
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  };

  //---
  return (
    <Wrap>
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: damping },
          opacity: { duration: 0.2 },
        }}
      >
        <WrapIssueTicket>
          <Header nameOffice={deviceInfo.officeName} />
          <WrapInfoBill>
            <div className="content">
              {customerInfo &&
                customerInfo.type === ConstData.customerType.VIP && (
                  <div className="content-header">
                    <span>Xin chào quý khách</span>
                    <span className="name">{customerInfo.name}</span>
                  </div>
                )}
              <div className="content-title">Thông tin vé giao dịch</div>
              <div className="content-number">
                <div className="content-number-transaction">
                  số vé giao dịch
                </div>
                {Object.values(ticketInfo).length === 0 ||
                  ticketInfo.ticketNumber === "" ? (
                  <div className="content-loading">
                    <div className="content-loading-animation">
                      <LottieAnimation
                        data={lottieTicketLoading}
                        isLoop={true}
                        className="animation"
                      />
                    </div>
                    <div className="content-loading-desc">
                      Hệ thống đang xử lý
                    </div>
                    <div className="content-loading-desc">
                      Vui lòng đợi trong giây lát
                    </div>
                  </div>
                ) : (
                  <div className="content-number-bill">
                    {ticketInfo.ticketNumber}
                  </div>
                )}
              </div>
              <div className="content-desp">
                {customerInfo &&
                  customerInfo.type === ConstData.customerType.VIP ? (
                  <div className="content-desp-value">
                    XIN MỜI QUÝ KHÁCH ĐẾN QUẦY VIP ĐỂ ĐƯỢC PHỤC VỤ
                  </div>
                ) : (
                  ticketInfo && ticketInfo.listService &&
                  <>
                    <span className="content-desp-value">DỊCH VỤ YÊU CẦU</span>
                    <span className="content-desp-service">{ticketInfo.listService.value || "-"}</span>
                    {/* {ticketInfo &&
                      ticketInfo.listService?.map((service, index) => {
                        return (
                          <span key={index} className="content-desp-service">
                            {service}
                          </span>
                        );
                      })} */}
                  </>
                )}
              </div>
              <div className="content-bottom">
                <span className="content-bottom-value">
                  Vé sẽ được in ra từ thiết bị,
                </span>
                <span className="content-bottom-value">
                  bạn vui lòng đợi trong giây lát...
                </span>
                <img className="image" src={XuatVe} alt="xuat ve" />
              </div>
            </div>
          </WrapInfoBill>
        </WrapIssueTicket>
        <FormTransactionReceipt refForm={refForm} />
      </motion.div>
    </Wrap>
  );
};

export default IssueTicketInfo;
