import { createSlice } from "@reduxjs/toolkit";
import { ConstKey } from "../../assets/constants/constants";
import { HomeActionMock } from "./home.action.mock";
import { HomeAction } from "./home.action";

const mockGetTicketAction = (state, action) => {
    // return initialState
    console.log("mock: getTicket completed")
}

const initialState = {
    ticketInfo: {},
    customerInfo: {},
    isGetTicketSuccess: false
}

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        resetHome: () => initialState,
        setIsGetTicketSuccess: (state, action) => {
            state.isGetTicketSuccess = action.payload
        },
        setTicketInfo: (state, action) => {
            state.ticketInfo = action.payload
        },
        setCustomerInfo: (state, action) => {
            state.customerInfo = action.payload
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(HomeActionMock.onGetTicket.fulfilled, mockGetTicketAction)
        // .addCase(HomeAction.onGetTicket.fulfilled,  (state, action) => { })
    },
})

export default homeSlice