/* eslint-disable react-hooks/exhaustive-deps */
import { WrapPrivateRoute } from "./index.styles"
import { ConstData } from "../../assets/constants/constants"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import commonSlice from "../../redux/common/common.slice"
import { commonSelector } from "../../redux/common/common.selectors"

const PrivateRoute = ({ children }) => {
    const  dispatch = useDispatch()
    var noActionDelayFunc = null;
    const isWarningEndTrans = useSelector(commonSelector.isWarningEndTrans)

    const handleClickAction = () => {
        console.log("===> has action on UI")
        if(noActionDelayFunc!==null){
            clearTimeout(noActionDelayFunc)
        }
        //---
        noActionDelayFunc = setTimeout(() =>{
            dispatch(commonSlice.actions.setIsWarningEndTrans(true))
        }, ConstData.delayTimeWhenNoAction - 10)
    }

    useEffect(()=>{
        console.log("===> PrivateRoute -> useEffect")
        if(!isWarningEndTrans && noActionDelayFunc == null){
            //---
            handleClickAction()
        }
        //---
        return () => {
            if(noActionDelayFunc!==null){
                clearTimeout(noActionDelayFunc)
            }
        }
    },[isWarningEndTrans])
    //---
    return (
        <WrapPrivateRoute onClick={handleClickAction}>
            {children}
        </WrapPrivateRoute>
    )
}
export default PrivateRoute