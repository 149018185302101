/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

//css
import { Wrap, WrapRatingThanksPage } from './index.styles'

//img
import IcThanks from '../../assets/icon/IcThanks'
import { damping, variants } from "../../utils/transition_page";
import { motion } from "framer-motion";
import Header from '../../components/Header';
import { commonSelector } from '../../redux/common/common.selectors';
import { ConstData } from '../../assets/constants/constants';

export default function RatingThanksPage() {

    const navigate = useNavigate()
    //---
    const deviceInfo = useSelector(commonSelector.deviceInfo)

    useEffect(() => {
        let timer = setTimeout(() => {
            navigate(ConstData.routePath.MainPage);
        }, 2000)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <Wrap>
            <motion.div
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                    x: { type: "spring", stiffness: 300, damping: damping },
                    opacity: { duration: 0.2 },
                }}
            >
                <WrapRatingThanksPage>
                    <Header nameOffice={deviceInfo.officeName} />
                    <div className="content">
                        <IcThanks />
                        <div className='text-thanks'>
                            <p>Cảm ơn bạn đã đánh giá! 😘 </p>
                        </div>
                    </div>
                </WrapRatingThanksPage>
            </motion.div>
        </Wrap>
    )
}
