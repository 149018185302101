import styled from "styled-components";

const WrapLoading = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999999;
    background: rgb(1,1,1,0.75);
    .loading{
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .text {
        color: #FFF;
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        margin-top: -40px;
    }

`

export {WrapLoading}