
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//css
import { WrapBackground } from './index.styles'
//component
import FingerScanControl from '../../controls/FingerScanControl';
//hook
import { ConstData } from '../../assets/constants/constants';
import { commonSelector } from '../../redux/common/common.selectors';
import { damping, variants } from "../../utils/transition_page";
import { motion } from "framer-motion";

const ScanFingerPage = () => {
  const navigate = useNavigate()
  //===
  const deviceInfo = useSelector(commonSelector.deviceInfo)
  console.log("deviceInfo: ", deviceInfo)
  //---

  const onShowHomePage = () => {
    navigate(ConstData.routePath.HomePage)
  }

  const onShowIssueTicketPage = () => {
    navigate(ConstData.routePath.IssueTicketPage)
  }

  return (
    <WrapBackground>
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: damping },
          opacity: { duration: 0.2 },
        }}
      >
        <FingerScanControl
          onShowHomePage={onShowHomePage}
          onShowIssueTicketPage={onShowIssueTicketPage}
        />
      </motion.div>
    </WrapBackground>
  );
}

export default ScanFingerPage;
