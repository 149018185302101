/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import { WrapNumberVirtualKeyboard } from './index.styles'

// data
import { keyboardNumberArray } from './keyboardArray'


export default function NumberVirtualKeyboard({ inputRef, text, setText, isLoan }) {
    const keyboard = useRef(null)
    const selectionRef = useRef({
        start: 0,
        end: 0
    })

    useEffect(() => {
        const { start, end } = selectionRef.current
        inputRef.current.setSelectionRange(start, end)
        inputRef.current.focus()
    }, [text]);

    const _handleNumberTyping = (character) => {
        // console.log(text, character)
        //gõ tối đa 10 kí tự với input number
        if (text.length >= 10 && !isLoan) return
        const { start, end } = selectionRef.current
        
        if (text.length === 0) {
            setText(character)
            selectionRef.current = {
                start: start + 1,
                end: end + 1
            }
            return;
        }

        const isSelectRangeText = start !== end
        if(!isSelectRangeText) {
            const endOfSelectStr = start
            const newText = text.substring(0, endOfSelectStr) + character + text.substring(endOfSelectStr)
            selectionRef.current = {
                start: start + 1,
                end: start + 1
            }
            setText(newText)
            return;
        }

        const newText = text.substring(0, start) + character + text.substring(end)
        selectionRef.current = {
            start: start + 1,
            end: start + 1
        }
        setText(newText)
    }

    const _handleClickKeyBoard = (keyboard, value) => {

        switch (keyboard.code) {
            case 'keyBackspace': {
                const { start, end } = selectionRef.current
                if(start === end) {
                    setText(pre => pre.substring(0, start - 1) + pre.substring(start))
                    const newPos = start - 1 < 0 ? 0 : start - 1
                    selectionRef.current = {
                        start: newPos,
                        end: newPos
                    }
                    console.log("new caret position", selectionRef.current)
                    break;
                }
                setText(pre => pre.substring(0, start) + pre.substring(end))
                selectionRef.current = {
                    start: start,
                    end: start
                }
                console.log("new caret position", selectionRef.current)
                break;
            }
            default: {
                _handleNumberTyping(value)
                break;
            }
        }
    }

    const _handleClick = (keyboardItem) => {
        const input = inputRef.current
        // console.log(input)
        // input.focus()
        // get range of selection
        selectionRef.current = {
            start: input.selectionStart,
            end: input.selectionEnd
        }

        console.log("current caret position", selectionRef.current)


        _handleClickKeyBoard(keyboardItem, keyboardItem.key)
    }

    const _renderKeyboard = () => {
        return (
            <div className='bound number'>
                {
                    keyboardNumberArray.map((item, i) => {
                        return (
                            <div className='line' key={i}>
                                {
                                    item.map((keyItem, j) => {
                                        return (
                                            <button id={keyItem.code} onClick={(e) => { e.stopPropagation(); _handleClick(keyItem) }} key={j}>{keyItem.title}</button>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    useEffect(() => {
        const position = keyboard.current.getBoundingClientRect()
        // console.log(position)
        const { height, top } = position
        if (top + height > document.body.offsetHeight) {
            keyboard.current.style.bottom = "calc(100% + 7px)"
            keyboard.current.style.top = "initial"
        }
    }, []);

    return (
        <WrapNumberVirtualKeyboard ref={keyboard}>
            {_renderKeyboard()}
        </WrapNumberVirtualKeyboard>
    )
}
