import React from "react";
import OverlayFullScreen from "../OverlayFullScreen";
import { WrapPopupEror } from "./index.styled";

const PopupEror = ({ title, desc, iconBtn, textBtn, onSubmit }) => {
  const handleSubmit = () => {
    if(onSubmit){
      onSubmit();
    }
  };

  return (
    <OverlayFullScreen>
      <WrapPopupEror>
        <div className="title">{title}</div>
        <div className="desc">{desc}</div>
        <div className="btn-container" onClick={handleSubmit}>
          {iconBtn}
          {textBtn}
        </div>
      </WrapPopupEror>
    </OverlayFullScreen>
  );
};

export default PopupEror;
