import React from 'react'

export default function IcRate3(props) {
  return (
    <svg {...props} width={96} height={97} viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z" fill="#FFCE52" />
      <path d="M48.0025 79.3081C33.0432 79.3081 20.8721 69.009 20.8721 56.3516H25.046C25.046 66.7071 35.3451 75.1342 48.0025 75.1342C60.6599 75.1342 70.959 66.7071 70.959 56.3516H75.1329C75.1329 69.009 62.9618 79.3081 48.0025 79.3081Z" fill="#273941" />
      <path d="M39.6498 41.7429H35.4759C35.4759 37.1391 32.6669 33.3951 29.2151 33.3951C25.7632 33.3951 22.9542 37.1391 22.9542 41.7429H18.7803C18.7803 34.8372 23.4613 29.2212 29.2151 29.2212C34.9688 29.2212 39.6498 34.8372 39.6498 41.7429Z" fill="#273941" />
      <path d="M77.2153 41.7428H73.0414C73.0414 37.139 70.2323 33.395 66.7805 33.395C63.3287 33.395 60.5196 37.139 60.5196 41.7428H56.3457C56.3457 34.8371 61.0267 29.2211 66.7805 29.2211C72.5342 29.2211 77.2153 34.8371 77.2153 41.7428Z" fill="#273941" />
      <path d="M47.9947 6.26251C73.3512 6.26251 93.9077 24.0162 93.9077 45.9147H95.9425C95.3672 33.5851 90.0643 21.9509 81.1353 13.4289C72.2064 4.90692 60.3377 0.152085 47.9947 0.152085C35.6517 0.152085 23.783 4.90692 14.8541 13.4289C5.92511 21.9509 0.622216 33.5851 0.046875 45.9147H2.08166C2.08166 24.0162 22.6382 6.26251 47.9947 6.26251Z" fill="#FFE369" />
      <path d="M93.913 45.9141C93.913 67.8126 73.3565 85.5664 48 85.5664C22.6435 85.5664 2.08696 67.8126 2.08696 45.9141H0.0521739C0.0229565 46.607 0 47.302 0 48.0011C1.89698e-07 60.7315 5.05713 72.9405 14.0589 81.9423C23.0606 90.944 35.2696 96.0011 48 96.0011C60.7304 96.0011 72.9394 90.944 81.9411 81.9423C90.9429 72.9405 96 60.7315 96 48.0011C96 47.302 95.977 46.607 95.9478 45.9141H93.913Z" fill="#FFB32B" />
      <path d="M76.8247 18.4165C77.497 17.7442 77.2836 16.4408 76.3481 15.5053C75.4126 14.5699 74.1093 14.3565 73.437 15.0288C72.7647 15.701 72.9781 17.0044 73.9136 17.9399C74.8491 18.8754 76.1524 19.0887 76.8247 18.4165Z" fill="#F6FAFD" />
      <path d="M62.9282 8.17415C63.2648 7.8376 63.1584 7.18559 62.6907 6.71785C62.2229 6.25011 61.5709 6.14375 61.2344 6.4803C60.8978 6.81685 61.0042 7.46885 61.4719 7.9366C61.9397 8.40434 62.5917 8.51069 62.9282 8.17415Z" fill="#F6FAFD" />
      <path d="M72.2597 15.9387C73.6043 14.5942 73.1769 11.9868 71.3051 10.115C69.4333 8.24324 66.826 7.81584 65.4814 9.1604C64.1369 10.505 64.5643 13.1123 66.436 14.9841C68.3078 16.8559 70.9152 17.2833 72.2597 15.9387Z" fill="#F6FAFD" />
    </svg>
  )
}
