import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LottieAnimation from "../LottieAnimation";
//css
import { Wrap, WrapFingerScan } from "./index.styled";
//img
import VerifieldFinger from "../../assets/img/verifield_finger.png";
import BannerBottomFinger from "../../assets/img/banner_bottom_finger.png";
import IconArrow from "../../assets/icon/arrow";
import lottieFingerPressed from "../../assets/lottie/finger_pressed.json";
import lottieFingerCaptured from "../../assets/lottie/finger_captured.json";
import IcCheckScanFingerSuccess from "../../assets/icon/IcCheckScanFingerSuccess"
import IcRetryWhite from "../../assets/icon/IcRetryWhite";

//component
import Header from "../../components/Header";
import Portal from "../Portal";
import PopupEror from "../PopupErr";
//hook
import useConnectScannerFinger from "../../hooks/useConnectScannerFinger";
import { scanFingerSelector } from "../../redux/scanFinger/scanFinger.selectors";
import { ConstData } from "../../assets/constants/constants";
import { ScanFingerAction } from "../../redux/scanFinger/scanFinger.action";
import { commonSelector } from "../../redux/common/common.selectors";
import { detectFaceSelector } from "../../redux/detectFace/detectFace.selectors";
import commonSlice from "../../redux/common/common.slice";
import useConnectScannerMK from "../../hooks/useConnectScannerMK";
import { DetectFaceAction } from "../../redux/detectFace/detectFace.action";
import detectFaceSlice from "../../redux/detectFace/detectFace.slice";
import scanFingerSlice from "../../redux/scanFinger/scanFinger.slice";

const FingerScanControl = ({ onShowHomePage, onShowIssueTicketPage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const numberOfRetry = useRef(0)
  const timeOut = useRef(null)

  const { wsRef, wsConnected } = useConnectScannerFinger()
  //---
  const {
    wsRef: wsRefMK,
    connectWs: connectWsMK,
    wsConnected: wsConnectedMK,
    message: messageMK,
    setMessage: setMessageMK,
    isProcessing: isProcessingMK,
    setIsProcessing: setIsProcessingMK,
    isVerifyFailed: isVerifyFailedMK,
    setIsVerifyFailed: setIsVerifyFailedMK,
  } = useConnectScannerMK();
  //---  
  const fingerData = useSelector(scanFingerSelector.fingerData)
  const scanFpStatus = useSelector(scanFingerSelector.scanFpStatus)
  const deviceInfo = useSelector(commonSelector.deviceInfo)
  const faceData = useSelector(detectFaceSelector.faceData)
  //---
  const typeConnectMK = useSelector(state => state.common.typeConnectMK)
  const idCardNo = useSelector(state => state.common.idCardNo)
  const isOcrSucceeded = useSelector(state => state.common.isOcrSucceeded)
  //---
  const [isShowIconSuccess, setIsShowIconSuccess] = useState(false)
  const [isShowPopupErrMK, setIsShowPopupErrMK] = useState(false)
  const [isCallApi, setIsCallApi] = useState(false)
  const [isShowErrMorethan3, setIsShowErrMorethan3] = useState(false)
  const [isTimeOut, setIsTimeOut] = useState(false)

  useEffect(() => {
    if (messageMK) {
      setIsShowPopupErrMK(true)
    }
  }, [messageMK])

  // useEffect(() => {
  //   if (!isTimeOut) {
  //     if (timeOut.current) {
  //       clearTimeout(timeOut.current)
  //       timeOut.current = null
  //     }
  //     return
  //   }
  //   timeOut.current = setTimeout(() => {
  //     dispatch(commonSlice.actions.setIsLoading(false));
  //     dispatch(scanFingerSlice.actions.setScanFpStatus(ConstData.scanFingerStatus.noFinger))
  //     setIsProcessingMK(false)
  //     if (wsConnectedMK) {
  //       wsRefMK.current.send(typeConnectMK)
  //     }
  //   }, 10000);
  //   return () => {
  //     clearTimeout(timeOut.current)
  //   }
  // }, [isTimeOut])

  useEffect(() => {
    if (!isCallApi) return
    let timer = setTimeout(() => {
      setIsShowPopupErrMK(false)
      dispatch(commonSlice.actions.setIsLoading(true));
      setIsTimeOut(true)
    }, 300);
    return () => {
      clearTimeout(timer)
    }
  }, [isCallApi])

  useEffect(() => {
    if (!isOcrSucceeded && !isVerifyFailedMK) return
    let timer = setTimeout(() => {
      dispatch(commonSlice.actions.setIsLoading(false));
      setIsTimeOut(false)
    }, 300);
    return () => {
      clearTimeout(timer)
    }
  }, [isOcrSucceeded, isVerifyFailedMK])

  useEffect(() => {
    if (!typeConnectMK) return
    wsConnectedMK && wsRefMK.current.send(typeConnectMK)
  }, [typeConnectMK, wsConnectedMK, wsRefMK])

  const onCaptureCompleted = () => {
    setIsShowIconSuccess(true)
    //---
    setIsCallApi(true)
  }

  useEffect(() => {
    if (!isCallApi || !faceData) return
    onCallAPICheckFp()
  }, [isCallApi, faceData])

  const onCallAPICheckFp = () => {
    console.log("onCallAPICheckFp => fingerData: ", fingerData, ", faceData: ", faceData)
    const callBack = (flowCode, isVIPCustomer) => {
      dispatch(commonSlice.actions.setIsLoading(false))
      setIsTimeOut(false)
      if (flowCode === ConstData.flowCode.retry) {
        numberOfRetry.current = numberOfRetry.current + 1;
        if (numberOfRetry.current < flowCode.retry) {
          if (typeConnectMK) {
            if (!faceData || !idCardNo) return
            dispatch(DetectFaceAction.findUserByCifs({
              faceData: faceData,
              deviceInfo: deviceInfo,
              idCardNo: idCardNo,
              callBack: callBack,
            }))
          } else if (!typeConnectMK) {
            dispatch(ScanFingerAction.findUserWithFaceAndFinger({
              fingerData: fingerData,
              faceData: faceData,
              deviceInfo: deviceInfo,
              callBack: callBack,
            }))
          }
        } else {
          onShowHomePage()
        }
      } else {
        var flow = "Khách hàng đã đăng ký STH"
        if (isVIPCustomer) {
          flow = "Khách hàng VIP"
          onShowIssueTicketPage()
        } else {
          onShowHomePage()
        }
      }
    }
    //---
    if (typeConnectMK) {
      if (!faceData || !idCardNo) return
      dispatch(DetectFaceAction.findUserByCifs({
        faceData: faceData,
        deviceInfo: deviceInfo,
        idCardNo: idCardNo,
        callBack: callBack,
      }))
    } else if (!typeConnectMK) {
      if (!fingerData || !faceData) {
        dispatch(commonSlice.actions.setIsSystemError(true))
        return
      }
      //---
      dispatch(ScanFingerAction.findUserWithFaceAndFinger({
        fingerData: fingerData,
        faceData: faceData,
        deviceInfo: deviceInfo,
        callBack: callBack,
      }))
    }
  }

  const handleRetry = () => {
    setIsShowPopupErrMK(false)
    setIsVerifyFailedMK(false)
    setIsProcessingMK(false)
    setIsCallApi(false)
    dispatch(scanFingerSlice.actions.setScanFpStatus(ConstData.scanFingerStatus.noFinger))
    dispatch(detectFaceSlice.actions.setFace(null))
    dispatch(scanFingerSlice.actions.setFingerData(null))
    numberOfRetry.current = numberOfRetry.current + 1
    // if (wsConnected && wsConnectedMK) {
    //   wsRef.current.send("START")
    //   wsRefMK.current.send(typeConnectMK)
    // }
    if (wsConnectedMK) {
      wsRefMK.current.send(typeConnectMK)
    }
  }

  const handleUnderStand = () => {
    setIsShowErrMorethan3(false)
    setIsShowPopupErrMK(false)
    setIsVerifyFailedMK(false)
    setIsProcessingMK(false)
    dispatch(detectFaceSlice.actions.setFace(null))
    navigate(ConstData.routePath.MainPage);
  }

  useEffect(() => {
    if (numberOfRetry.current === 2 && isVerifyFailedMK) {
      setIsShowErrMorethan3(true)
    }
  }, [isVerifyFailedMK])

  return (
    <Wrap>
      <Header nameOffice={deviceInfo.officeName} />
      <WrapFingerScan>
        <div className="content">
          <div className="content-header">
            <img src={VerifieldFinger} alt="VerifieldFinger" />
          </div>
          <div className="content-finger">
            {scanFpStatus === ConstData.scanFingerStatus.noFinger && !isProcessingMK ? (
              <div className="content-finger-default">
                <span className="content-finger-default-value">
                  Vui lòng đưa ngón tay bất kỳ vào vùng nhận diện{" "}
                </span>
                <IconArrow />
              </div>
            ) : (
              <div className="content-finger-check">
                <div className="content-finger-check-success">
                  <div className="image-success">
                    <LottieAnimation
                      data={
                        scanFpStatus === ConstData.scanFingerStatus.done || (isProcessingMK && isCallApi)
                          ? lottieFingerCaptured
                          : lottieFingerPressed
                      }
                      handleComplete={onCaptureCompleted}
                      className="animation"
                    />
                    {
                      isShowIconSuccess
                        ? <div className="icon">
                          <IcCheckScanFingerSuccess />
                        </div>
                        : <></>
                    }
                  </div>
                  <span className="content-finger-check-default-value">
                    {
                      scanFpStatus === ConstData.scanFingerStatus.done || (isProcessingMK && isCallApi)
                        ? "Xác nhận vân tay"
                        : "Đang ghi nhận"
                    }
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="content-bottom">
            <img src={BannerBottomFinger} alt="BannerBottomFinger" />
          </div>
        </div>
        {isVerifyFailedMK && typeConnectMK && (numberOfRetry.current !== 2) && (
          <Portal>
            <PopupEror
              title={"Xác thực thất bại."}
              desc={"Quý khách vui lòng thử lại."}
              iconBtn={<IcRetryWhite />}
              textBtn={"Thử lại"}
              onSubmit={handleRetry}
            />
          </Portal>
        )}
        {isShowPopupErrMK && typeConnectMK && (
          <Portal>
            <PopupEror
              title={messageMK}
              iconBtn={<IcRetryWhite />}
              textBtn={"Thử lại"}
              onSubmit={handleRetry}
            />
          </Portal>
        )}
        {isShowErrMorethan3 &&
          <Portal>
            <PopupEror
              title={"Xác thực thất bại."}
              desc={"Quý khách vui lòng đến chi nhánh gần nhất để được hỗ trợ."}
              iconBtn={<IcRetryWhite />}
              textBtn={"Đã hiểu"}
              onSubmit={handleUnderStand}
            />
          </Portal>
        }
      </WrapFingerScan>
    </Wrap>
  );
};

export default FingerScanControl;
