import React from 'react'

const IcTRANSEXTACC = (props) => {
    return (
        <svg {...props} width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.3332 50.3032V21.5355C49.3332 15.986 45.434 11.2094 40.0205 10.1273C34.7252 9.06875 29.2745 9.06875 23.9792 10.1273C18.5657 11.2094 14.6665 15.986 14.6665 21.5355V50.3032C14.6665 53.6689 18.3004 55.7636 21.1892 54.0631L29.0807 49.4179C30.8837 48.3566 33.116 48.3566 34.919 49.4179L42.8105 54.0631C45.6993 55.7636 49.3332 53.6689 49.3332 50.3032Z" stroke="#AB1F24" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6665 50.3032C14.6665 53.6689 18.3004 55.7636 21.1892 54.0631L29.0807 49.4179C30.8837 48.3566 33.116 48.3566 34.919 49.4179L42.8105 54.0631C45.6993 55.7636 49.3332 53.6689 49.3332 50.3032V21.5355C49.3332 15.986 45.434 11.2094 40.0205 10.1273C34.7252 9.06875 29.2745 9.06875 23.9792 10.1273" stroke="#00783A" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcTRANSEXTACC