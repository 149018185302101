import { configureStore } from '@reduxjs/toolkit'
import detectFaceSlice from './detectFace/detectFace.slice';
import commonSlice from './common/common.slice';
import scanFingerSlice from './scanFinger/scanFinger.slice';
import homeSlice from './home/home.slice';

const store = configureStore({
    reducer: {
        detectFace: detectFaceSlice.reducer,
        common: commonSlice.reducer,
        scanFinger: scanFingerSlice.reducer,
        home: homeSlice.reducer
    },
    devTools: true
})
export default store;